import { Query } from "../../../shared/Models/Query";
import { VehicleForStocking } from "../Models/VehicleForStocking";
import { AuthFetch } from "../../../shared/Models/AuthFetch";

export class VehiclesForStocking implements Query<VehicleForStocking[]> {
  Result(): Promise<VehicleForStocking[]> {
    const myFetch = AuthFetch();
    const url = "/api/Vehicle/VehiclesForStocking";
    return myFetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((locationsJSON: VehicleForStocking[]) => {
        return locationsJSON;
      });
  }
}
