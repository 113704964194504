import * as React from "react";
import { Route, Switch } from "react-router";
import { Offline, Online } from "react-detect-offline";
import "./custom.css";
import { Location } from "./modules/vehicle-stocking-page/Models/Location";
import LoginPage from "./modules/authorization/LoginPage/LoginPage";
import PageNotFound from "./modules/authorization/PageNotFound";
import { PrivateRoute } from "./modules/helpers/PrivateRoute";
import { history } from "./modules/helpers/history";
import Home from "./modules/home/Home";
import Header from "./shared/components/header/Header";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Spinner } from "./shared/components/header/Spiner";
import "react-notifications/lib/notifications.css";

// Vehicle Stocking Page
import VehicleStockingPage from "./modules/vehicle-stocking-page/VehicleStockingPage";
import { VehicleStock } from "./modules/vehicle-stocking-page/Commands/VehicleStock";
import { VehiclePhoto } from "./modules/vehicle-stocking-page/Commands/VehiclePhoto";
import { VehiclePhotoDelete } from "./modules/vehicle-stocking-page/Commands/VehiclePhotoDelete";
import { VehicleVINPhoto } from "./modules/vehicle-stocking-page/Commands/VehicleVINPhoto";
import { VehicleVINPhotoDelete } from "./modules/vehicle-stocking-page/Commands/VehicleVINPhotoDelete";
import { RetailerForStocking } from "./modules/vehicle-stocking-page/Models/RetailerForStocking";
import { VehicleForStocking } from "./modules/vehicle-stocking-page/Models/VehicleForStocking";
import { RetailersForStocking } from "./modules/vehicle-stocking-page/Queries/RetailersForStocking";
import { VehicleLocations } from "./modules/vehicle-stocking-page/Queries/VehicleLocations";
import { VehiclesForStocking } from "./modules/vehicle-stocking-page/Queries/VehiclesForStocking";

// Prepare for Dispatch Page
import PrepareForDispatchPage from "./modules/prepare-for-dispatch-page/PrepareForDispatchPage";
import { VehicleDispatch } from "./modules/prepare-for-dispatch-page/Commands/VehicleDispatch";
import { RetailerForDispatch } from "./modules/prepare-for-dispatch-page/Models/RetailerForDispatch";
import { VehicleForDispatch } from "./modules/prepare-for-dispatch-page/Models/VehicleForDispatch";
import { RetailersForDispatch } from "./modules/prepare-for-dispatch-page/Queries/RetailersForDispatch";
import { VehiclesForDispatch } from "./modules/prepare-for-dispatch-page/Queries/VehiclesForDispatch";

// Put on Truck Page
import PutOnTruckPage from "./modules/put-on-truck-page/PutOnTruckPage";
import { VehiclePutOnTruck } from "./modules/put-on-truck-page/Commands/VehiclePutOnTruck";
import { RetailerForPutOnTruck } from "./modules/put-on-truck-page/Models/RetailerForPutOnTruck";
import { VehicleForPutOnTruck } from "./modules/put-on-truck-page/Models/VehicleForPutOnTruck";
import { RetailersForPutOnTruck } from "./modules/put-on-truck-page/Queries/RetailersForPutOnTruck";
import { VehiclesForPutOnTruck } from "./modules/put-on-truck-page/Queries/VehiclesForPutOnTruck";

// CMR
import CMRPage from "./modules/CMR/CMRPage";
import { VehicleForCMR } from "./modules/CMR/Models/VehicleForCMR";
import { VehiclesForCMR } from "./modules/CMR/Queries/VehiclesForCMR";
import { VehicleCMRPhoto } from "./modules/CMR/Commands/VehicleCMRPhoto";
import { VehicleCMRPhotoDelete } from "./modules/CMR/Commands/VehicleCMRPhotoDelete";

// Auth
import { IUser } from "./modules/authorization/Models/User";
import { AuthService } from "./modules/authorization/Services/AuthService";

// Global
import { SpinnerSize, Sticky } from "office-ui-fabric-react";
import { Resource } from "./assets/Localization";

// PDI
import PDIPage from "./modules/PDI/PDIPage";
import PDICheckPage from "./modules/PDI/PDICheckPage";
import { VehicleForPDI } from "./modules/PDI/Models/VehicleForPDI";
import { AddPDIResult } from "./modules/PDI/Models/AddPDIResult";
import { PDIDetails } from "./modules/PDI/Queries/PDIDetails";
import { VehicleDamaged } from "./modules/PDI/Queries/VehicleDamaged";
import { AddPDI } from "./modules/PDI/Commands/AddPDI";
import { FinishPDI } from "./modules/PDI/Commands/FinishPDI";
import { Comment } from "./modules/PDI/Commands/Comment";
import { DeleteCheck } from "./modules/PDI/Commands/DeleteCheck";
import { PDIPrint } from "./modules/PDI/Commands/PDIPrint";

// Shared
import { PhotoUpload } from "./shared/Commands/PhotoUpload";
import { DamageType } from "./shared/Modules/AddPdiDialog/Models/DamageType";
import { AddPDICheck } from "./modules/PDI/Commands/AddPDICheck";
import { EDamageType } from "./shared/Modules/AddPdiDialog/Models/EDamageType";
import { PDI } from "./modules/PDI/Models/PDI";
import { VehiclesForPDI } from "./modules/PDI/Queries/VehiclesForPdi";
import { Configuration } from "./shared/Queries/Configuration";
import { PdiPhotoUploadResponse, PhotoUploadResponse } from "./shared/Models/PhotoUploadResponse";
import {
  ArrivalVehicleDTO,
  FetchArrivalVehiclesInput,
  fetchArrivalVehicles,
} from "./modules/PDI/Queries/ArrivalVehicles";

interface Props {}

interface State {
  // Global
  pageTitle: string;
  prevPath: string;
  pageLoading: boolean;
  globalLoader: number;
  currentUser: IUser | null;
  currentLanguage: string;

  // Vehicle Stocking Page
  vehicleLocations: Location[];
  vehiclesForStocking: VehicleForStocking[];
  retailersForStocking: RetailerForStocking[];
  vehicleStockLoading: boolean;
  hideLocationSelector: boolean;
  selectedLocationId: string;
  selectedLocationName: string;

  // Prepare for Dispatch Page
  vehiclesForDispatch: VehicleForDispatch[];
  retailersForDispatch: RetailerForDispatch[];
  vehicleDispatchLoading: boolean;

  // Put on Truck Page
  vehiclesForPutOnTruck: VehicleForPutOnTruck[];
  retailersForPutOnTruck: RetailerForPutOnTruck[];
  vehiclePutOnTruckLoading: boolean;

  // CMR
  vehiclesForCMR: VehicleForCMR[];
  photoLoading: boolean;
  VINPhotoLoading: boolean;

  // PDI
  vehiclesForPDI: VehicleForPDI[];
  addPDILoading: boolean;
  PDIphotoLoading: boolean;
  VehicleDamaged: boolean;
  PDICheckPhoto: PdiPhotoUploadResponse | undefined;
  DemageType: DamageType | undefined;
  selectedPDI: PDI | undefined;
  ahah: string;

  //Arrival
  arrivalVehicles: ArrivalVehicleDTO[];
  arrivalVehiclesLoading: boolean;
  arrivalVehiclesError: boolean;

  //shared
  configuration: string;
}
export default class App extends React.Component<Props, State> {
  _ = this;
  constructor(props: Props) {
    super(props);
    Resource.setLanguage(localStorage.getItem("Language") || "en");
    this.state = {
      // Global
      pageTitle: "",
      ahah: "",
      prevPath: "",
      pageLoading: false,
      globalLoader: 0,
      currentUser: AuthService.getCurrentUser(),
      currentLanguage: localStorage.getItem("Language") || "en",

      // Vehicle Stocking Page
      vehicleLocations: [],
      vehiclesForStocking: [],
      retailersForStocking: [],
      vehicleStockLoading: false,
      hideLocationSelector: false,
      selectedLocationId: localStorage.getItem("SelectedLocationId") || "",
      selectedLocationName: localStorage.getItem("SelectedLocationName") || "",

      // Prepare for Dispatch Page
      vehiclesForDispatch: [],
      retailersForDispatch: [],
      vehicleDispatchLoading: false,

      // Put on Truck Page
      vehiclesForPutOnTruck: [],
      retailersForPutOnTruck: [],
      vehiclePutOnTruckLoading: false,

      // CMR
      vehiclesForCMR: [],
      photoLoading: false,
      VINPhotoLoading: false,

      // PDI
      vehiclesForPDI: [],
      addPDILoading: false,
      PDIphotoLoading: false,
      VehicleDamaged: false,
      PDICheckPhoto: undefined,
      DemageType: undefined,
      selectedPDI: undefined,

      //Arrival
      arrivalVehicles: [],
      arrivalVehiclesLoading: false,
      arrivalVehiclesError: false,

      // shared
      configuration: "",
    };
  }

  getVehicleLocations = async () => {
    const locations = await new VehicleLocations().Result();
    this.setState({ vehicleLocations: locations });
    return locations;
  };

  setCurrentLocation = (id: string) => {
    const currentLocation = this.state.vehicleLocations.find((e) => e.Id == id);
    const { Id, Name } = currentLocation!;
    localStorage.setItem("SelectedLocationId", Id);
    localStorage.setItem("SelectedLocationName", Name);
    this.setState({ selectedLocationId: Id, selectedLocationName: Name });
  };

  // Vehicle Stocking Page
  getVehiclesForStocking = () => {
    //this.incrementLoaderCount();
    new VehiclesForStocking()
      .Result()
      .then((vehiclesForStocking: VehicleForStocking[]) => {
        this.setState({ vehiclesForStocking: vehiclesForStocking });
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  getRetailersForStocking = () => {
    this.incrementLoaderCount();
    new RetailersForStocking()
      .Result()
      .then((retailersForStocking: RetailerForStocking[]) => {
        this.setState({ retailersForStocking: retailersForStocking });
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  vehicleStock = (vin: string, location: string) => {
    this.incrementLoaderCount();
    return new VehicleStock(vin, location)
      .Result()
      .then((response: Response) => {
        if (response.ok) {
          NotificationManager.success(
            Resource.shared.Messages.vehicleStock.success,
            Resource.shared.Messages.vehicleStock.title
          );
          this.getVehiclesForStocking();
          this.getRetailersForStocking();
        }
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  // Prepare for Dispatch Page
  getVehiclesForDispatch = (selectedRetailerCode: string) => {
    this.incrementLoaderCount();
    new VehiclesForDispatch(selectedRetailerCode)
      .Result()
      .then((vehiclesForDispatch: VehicleForDispatch[]) => {
        this.setState({ vehiclesForDispatch: vehiclesForDispatch });
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  getRetailersForDispatch = () => {
    this.incrementLoaderCount();
    new RetailersForDispatch()
      .Result()
      .then((retailersForDispatch: RetailerForDispatch[]) => {
        this.setState({ retailersForDispatch: retailersForDispatch });
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  vehicleDispatch = (vin: string, selectedRetailerCode: string) => {
    this.incrementLoaderCount();
    new VehicleDispatch(vin)
      .Result()
      .then((response: Response) => {
        if (response.ok) {
          NotificationManager.success(
            Resource.shared.Messages.vehicleDispatch.success,
            Resource.shared.Messages.vehicleDispatch.title
          );
          this.getVehiclesForDispatch(selectedRetailerCode);
          this.getRetailersForDispatch();
        }
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  // Put on Truck Page
  getVehiclesForPutOnTruck = (selectedRetailerCode: string) => {
    this.incrementLoaderCount();
    new VehiclesForPutOnTruck(selectedRetailerCode)
      .Result()
      .then((vehiclesForPutOnTruck: VehicleForPutOnTruck[]) => {
        this.setState({ vehiclesForPutOnTruck: vehiclesForPutOnTruck });
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  getRetailersForPutOnTruck = () => {
    this.incrementLoaderCount();
    new RetailersForPutOnTruck()
      .Result()
      .then((retailersForPutOnTruck: RetailerForPutOnTruck[]) => {
        this.setState({ retailersForPutOnTruck: retailersForPutOnTruck });
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  vehiclePutOnTruck = (vin: string, selectedRetailerCode: string) => {
    this.incrementLoaderCount();
    new VehiclePutOnTruck(vin)
      .Result()
      .then((response: Response) => {
        if (response.ok) {
          NotificationManager.success(
            Resource.shared.Messages.vehiclePutOnTruck.success,
            Resource.shared.Messages.vehiclePutOnTruck.title
          );
          this.getVehiclesForPutOnTruck(selectedRetailerCode);
          this.getRetailersForPutOnTruck();
        }
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  // CMR
  getVehiclesForCMR = () => {
    this.incrementLoaderCount();
    new VehiclesForCMR()
      .Result()
      .then((vehiclesForCMR: VehicleForCMR[]) => {
        this.setState({ vehiclesForCMR: vehiclesForCMR });
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  uploadPhoto = (VIN: string, Photo: File) => {
    this.setState({ photoLoading: true });
    new PhotoUpload(Photo)
      .Result()
      .then((CMRPhoto: PhotoUploadResponse) => {
        this.vehicleCMRPhoto(VIN, CMRPhoto.Name);
      })
      .catch(() => {
        this.setState({ photoLoading: false });
      });
  };

  uploadVehiclePhoto = (VIN: string, Photo: File) => {
    this.setState({ photoLoading: true });
    new PhotoUpload(Photo)
      .Result()
      .then((Photo: PhotoUploadResponse) => {
        this.vehiclePhoto(VIN, Photo.Name);
      })
      .catch(() => {
        this.setState({ photoLoading: false });
      });
  };

  uploadVINPhoto = (VIN: string, Photo: File) => {
    this.setState({ VINPhotoLoading: true });
    new PhotoUpload(Photo)
      .Result()
      .then((CMRPhoto: PhotoUploadResponse) => {
        this.vehicleVINPhoto(VIN, CMRPhoto.Name);
      })
      .catch(() => {
        this.setState({ VINPhotoLoading: false });
      });
  };

  vehiclePhoto = (VIN: string, PhotoName: string) => {
    this.setState({ photoLoading: true });
    new VehiclePhoto(VIN, PhotoName)
      .Result()
      .then((res) => {
        NotificationManager.success(Resource.shared.Messages.addVehiclePhoto.success, "");
        this.getVehiclesForStocking();
        this.getVehiclesForPDI();
      })
      .finally(() => {
        this.setState({ photoLoading: false });
      });
  };

  vehiclePhotoDelete = (VIN: string) => {
    this.setState({ photoLoading: true });
    new VehiclePhotoDelete(VIN)
      .Result()
      .then(() => {
        NotificationManager.success(Resource.shared.Messages.deleteVehiclePhoto.success, "");
        this.getVehiclesForStocking();
        this.getVehiclesForPDI();
      })
      .finally(() => {
        this.setState({ photoLoading: false });
      });
  };

  vehicleVINPhoto = (VIN: string, PhotoName: string) => {
    this.setState({ VINPhotoLoading: true });
    new VehicleVINPhoto(VIN, PhotoName)
      .Result()
      .then(() => {
        NotificationManager.success(Resource.shared.Messages.addVINPhoto.success, "");
        this.getVehiclesForStocking();
        this.getVehiclesForPDI();
      })
      .finally(() => {
        this.setState({ VINPhotoLoading: false });
      });
  };

  vehicleVINPhotoDelete = (VIN: string) => {
    this.setState({ VINPhotoLoading: true });
    new VehicleVINPhotoDelete(VIN)
      .Result()
      .then(() => {
        NotificationManager.success(Resource.shared.Messages.deleteVINPhoto.success, "");
        this.getVehiclesForStocking();
        this.getVehiclesForPDI();
      })
      .finally(() => {
        this.setState({ VINPhotoLoading: false });
      });
  };

  vehicleCMRPhoto = (VIN: string, PhotoName: string) => {
    new VehicleCMRPhoto(VIN, PhotoName)
      .Result()
      .then(() => {
        NotificationManager.success(Resource.shared.Messages.addCMRPhoto.success, "");
        this.getVehiclesForCMR();
      })
      .finally(() => {
        this.setState({ photoLoading: false });
      });
  };

  CMRPhotoDelete = (VIN: string) => {
    this.setState({ photoLoading: true });
    new VehicleCMRPhotoDelete(VIN)
      .Result()
      .then(() => {
        NotificationManager.success(Resource.shared.Messages.deleteCMRPhoto.success, "");
        this.getVehiclesForCMR();
      })
      .finally(() => {
        this.setState({ photoLoading: false });
      });
  };

  // PDI
  getVehiclesForPDI = (): Promise<void> => {
    //this.incrementLoaderCount();
    return new VehiclesForPDI()
      .Result()
      .then((vehiclesForPDI: VehicleForPDI[]) => {
        this.setState({ vehiclesForPDI: vehiclesForPDI });
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  getConfiguration = (): Promise<string> => {
    return new Configuration()
      .Result()
      .then((configuration: string) => {
        this.setState({ configuration });
        return configuration;
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  addPDI = (vin: string, PDIState: string) => {
    this.setState({ globalLoader: 0 });
    this.incrementLoaderCount();
    new AddPDI(vin, PDIState)
      .Result()
      .then((AddPDIResult: AddPDIResult) => {
        history.push(`/Add-PDI-Check?VIN=${AddPDIResult.VIN}&PDIId=${AddPDIResult.Id}`);
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  finishPDI = (vin: string, PDIid: string) => {
    this.incrementLoaderCount();
    return new FinishPDI(vin, PDIid)
      .Result()
      .then((response: Response) => {
        NotificationManager.success(Resource.shared.Messages.PDIFinish.success, "");
        this.getVehiclesForPDI();
        this.getVehiclesForStocking();
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  PDIPrint = (pdiId: string) => {
    this.incrementLoaderCount();
    new PDIPrint(pdiId)
      .Result()
      .then((PDIPrintResult: Response) => {
        if (PDIPrintResult.status == 200) {
          NotificationManager.success(Resource.shared.Messages.PDIPrint.success, "");
        } else {
          NotificationManager.error(Resource.shared.Messages.generalError, "");
        }
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  getPDIDetails = (pdiid: string): Promise<PDI> => {
    this.incrementLoaderCount();
    return new PDIDetails(pdiid)
      .Result()
      .then((PDI: PDI) => {
        this.setState({
          selectedPDI: PDI,
        });
        return PDI;
      })
      .finally(() => {
        this.decrementLoaderCount();
      });
  };

  comment = (VIN: string, pdiId: string, comment: string) => {
    return new Comment(VIN, comment, pdiId).Result().then((response: Response) => {
      if (response.ok) {
        // NotificationManager.success(
        // 	Resource.shared.Messages.comment.success,
        // 	Resource.shared.Messages.comment.title,
        // );
        this.getVehiclesForPDI();
      }
    });
  };

  deleteCheck = (VIN: string, checkID: string, pdiId: string, damaged: boolean) => {
    new DeleteCheck(VIN, checkID, pdiId, damaged).Result().then((response: Response) => {
      if (response.ok) {
        this.getPDIDetails(pdiId);
        this.vehicleDamaged(VIN);
      }
    });
  };

  addCheck = (
    VIN: string,
    photoName: string,
    originPhotoName: string,
    pdiId: string,
    partId: number,
    damageType: EDamageType | undefined,
    damaged: boolean,
    partName: string,
    photo?: File
  ) => {
    return new AddPDICheck(VIN, photoName, originPhotoName, partId, pdiId, damageType, damaged, partName, photo)
      .Result()
      .then((response: Response) => {
        if (response.ok) {
          this.getPDIDetails(pdiId);
          this.vehicleDamaged(VIN);
        }
        return response;
      });
  };

  uploadPDIPhoto = (Photo: File) => {
    this.setState({ PDIphotoLoading: true });
    new PhotoUpload(Photo)
      .PdiResult()
      .then((photoUploadResponse: PhotoUploadResponse) => {
        this.setState({ PDICheckPhoto: photoUploadResponse });
      })
      .finally(() => {
        this.setState({ PDIphotoLoading: false });
      });
  };

  vehicleDamaged = (vin: string) => {
    new VehicleDamaged(vin).Result().then((damaged: boolean) => {
      this.setState({ VehicleDamaged: damaged });
    });
  };

  getClassNames = (globalLoader: number): string => {
    let returnedClass = "main-container ";
    if (globalLoader > 0) {
      returnedClass += "pageLoading ";
    }
    return returnedClass;
  };

  changeLanguage = (lang: string): void => {
    localStorage.setItem("Language", lang);
    Resource.setLanguage(lang);
    this.setState({
      currentLanguage: lang,
    });
    window.location.reload();
  };

  logOut = () => {
    localStorage.removeItem("CurrentUser");
    localStorage.removeItem("SelectedLocationId");
    localStorage.removeItem("SelectedLocationName");
    this.setState({ currentUser: null });
    history.push({ pathname: "/" });
  };

  logIn = (Username: string, Password: string) => {
    this.incrementLoaderCount();
    AuthService.login(Username, Password)
      .then((user: IUser) => {
        if (user) {
          this.setState({ currentUser: user });
          AuthService.setCurrentUser(user);
          const { from } = history.location.state || {
            from: { pathname: "/" },
          };
          history.push(from);
          this.decrementLoaderCount();
        } else {
          this.decrementLoaderCount();
          NotificationManager.error(Resource.shared.Messages.loginError.text, "");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  incrementLoaderCount = () => {
    setTimeout(() => {
      this.setState({ globalLoader: this.state.globalLoader + 1 });
    }, 0);
  };

  decrementLoaderCount = () => {
    setTimeout(() => {
      this.setState({ globalLoader: this.state.globalLoader - 1 });
    }, 0);
  };

  // Arrival
  getArrivalVehicles = ({ arrivalDate }: FetchArrivalVehiclesInput) => {
    this.setState({ arrivalVehiclesLoading: true, arrivalVehiclesError: false });
    fetchArrivalVehicles({ arrivalDate })
      .then((res) => {
        if (res.status !== 200) {
          this.setState({ arrivalVehiclesError: true });
          NotificationManager.error(Resource.shared.Messages.internetError, "");
        } else {
          return res.json();
        }
      })
      .then((arrivalVehicles: ArrivalVehicleDTO[]) => {
        this.setState({ arrivalVehicles: arrivalVehicles ?? [] });
      })
      .finally(() => {
        this.setState({ arrivalVehiclesLoading: false });
      });
  };

  render() {
    const { globalLoader, currentUser } = this.state;
    const className = this.getClassNames(globalLoader);
    return (
      <>
        <div className={className}>
          {/* <Offline>
						<div className='offline-notification'>{Resource.shared.Messages.offline}</div>
					</Offline> */}

          {currentUser ? (
            <header className="top">
              <Header context={this._} logOut={this.logOut} />
            </header>
          ) : null}
          <div className="center">
            <Switch>
              <PrivateRoute exact path="/" component={Home} context={this._} />
              <PrivateRoute exact path="/vehicle-stocking" component={VehicleStockingPage} context={this._} />
              <PrivateRoute path="/prepare-for-dispatch" component={PrepareForDispatchPage} context={this._} />
              <PrivateRoute path="/put-on-truck" component={PutOnTruckPage} context={this._} />
              <PrivateRoute path="/PDI-Check" component={PDIPage} context={this._} />
              <PrivateRoute path="/Add-PDI-Check" component={PDICheckPage} context={this._} />
              <PrivateRoute path="/CMR-Upload" component={CMRPage} context={this._} />
              <Route path="/login" render={(props) => <LoginPage {...props} context={this._} />} />
              <Route component={PageNotFound} />
            </Switch>
          </div>
        </div>
        {globalLoader > 0 ? (
          <div className="page-loader">
            <Spinner scale={2.5} />
            <p>{Resource.shared.Messages.loaderMessage.loading}</p>
          </div>
        ) : null}
        <NotificationContainer />
      </>
    );
  }
}
