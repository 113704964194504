import { Command } from "../../../shared/Models/Command";
import { AuthFetch } from "../../../shared/Models/AuthFetch";
export class Comment implements Command<Response> {
  readonly vin: string;
  readonly comment: string;
  readonly pdiId: string;
  constructor(vin: string, comment: string, pdiId: string) {
    this.vin = vin;
    this.comment = comment;
    this.pdiId = pdiId;
  }

  Result(): Promise<Response> {
    const myFetch = AuthFetch();
    const url = `/api/Vehicle/Comment`;
    return myFetch(url, {
      method: "POST",
      body: JSON.stringify({ vin: this.vin, comment: this.comment, pdiId: this.pdiId }),
    });
  }
}
