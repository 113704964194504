import { AuthenticationHeader } from "../../modules/helpers/Authentication-Header";
export function AuthFetch() {
	return (input: RequestInfo, init: RequestInit) => {
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': AuthenticationHeader()
		};
		init.headers = { ...headers, ...init.headers };
		return fetch(input, init);
	};
}
