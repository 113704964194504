import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  PrimaryButton,
  SpinnerSize,
  ChoiceGroup,
} from "office-ui-fabric-react";
import * as React from "react";
import { Resource } from "../../../assets/Localization";
import { PhotoUploadResponse } from "../../Models/PhotoUploadResponse";
import "./AddPdiDialog.scss";
import { DamageType } from "./Models/DamageType";
import { EDamageType } from "./Models/EDamageType";
import ReactFancyBox from "react-fancybox";
import Resizer from "react-image-file-resizer";
import "react-fancybox/lib/fancybox.css";
import { Spinner } from "../../components/header/Spiner";
interface Props {
  title: string;
  hideAddPdiDialog: boolean;
  checkPreview: boolean;
  PDIphotoLoading: boolean;
  PDICheckPhoto: PhotoUploadResponse | undefined;
  deletePhoto: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  uploadPDIPhoto: (file: File) => void;
  handleDemageType: (demageType: DamageType | undefined) => void;
  DemageType: DamageType | undefined;
  closeAddPdiDialog: (success: boolean) => void;
  addPDICheckLoading: boolean;
}

interface State {}

export default class AddPdiDialog extends React.Component<Props, State> {
  demageTypes: DamageType[] = [
    { Id: EDamageType.Grazemark, Name: "Grazemark" },
    { Id: EDamageType.Scratch, Name: "Scratch" },
    { Id: EDamageType.Chip, Name: "Chip" },
    { Id: EDamageType.Dent, Name: "Dent" },
    { Id: EDamageType.Broken, Name: "Broken" },
    { Id: EDamageType.Severe_Damage, Name: "Severe Damage" },
    { Id: EDamageType.Missing, Name: "Missing" },
  ];

  closeDialog = (success: boolean): void => {
    this.props.closeAddPdiDialog(success);
  };

  handleImageChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { uploadPDIPhoto } = this.props;
    changeEvent.preventDefault();
    const photo = changeEvent.target.files ? changeEvent.target.files[0] : null;
    console.log(photo);
    if (photo) {
      uploadPDIPhoto(photo);
      changeEvent.target.value = "";
    }
  };

  deleteDemageType = () => {
    const { handleDemageType } = this.props;
    handleDemageType(undefined);
  };

  validateCheck = (): boolean => {
    const { PDICheckPhoto, DemageType, addPDICheckLoading } = this.props;
    if (addPDICheckLoading || !DemageType || (DemageType.Id !== EDamageType.Missing && !PDICheckPhoto)) {
      return true;
    } else {
      return false;
    }
  };

  onItemClick = (item: DamageType) => {
    const { handleDemageType } = this.props;
    handleDemageType(item);
  };

  render(): JSX.Element {
    const { AddPdiDialog } = Resource.shared.Modules;
    const {
      PDICheckPhoto,
      title,
      DemageType,
      PDIphotoLoading,
      deletePhoto,
      handleDemageType,
      checkPreview,
      addPDICheckLoading,
    } = this.props;

    return (
      <>
        {!this.props.hideAddPdiDialog && (
          <Dialog
            hidden={false}
            onDismiss={!addPDICheckLoading ? () => this.closeDialog(false) : () => {}}
            dialogContentProps={{
              type: DialogType.largeHeader,
              title: title,
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 650 } },
              className: "addPdiModalClass",
            }}
          >
            <div className="dialog-content">
              <section className="damage-types-chooser">
                <div className="chooser">
                  <p className="title">{Resource.modules.PDICheck.dialogTitle}</p>
                  <ChoiceGroup
                    options={this.demageTypes.map((item, index) => {
                      return {
                        key: index.toString(),
                        text: item.Name,
                        value: item.Id,
                        onChange: () => handleDemageType(item),
                        checked: DemageType && DemageType.Id === item.Id,
                        disabled: checkPreview,
                      };
                    })}
                  />
                </div>
              </section>
              <section className="damage-photo-chooser">
                <div className="content">
                  {checkPreview ? (
                    PDICheckPhoto && PDICheckPhoto.URL ? (
                      <div
                        className="img"
                        style={{
                          backgroundImage: "url(" + PDICheckPhoto.URL + ")",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      >
                        <ReactFancyBox thumbnail={PDICheckPhoto.URL} image={PDICheckPhoto.URL} />

                        {!checkPreview ? (
                          <div className="remove" onClick={(event) => deletePhoto(event)}>
                            <img src={require("../../../assets/images/remove.svg")} alt="bin" />
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="no-image">
                        <img src={require("../../../assets/images/no-photo.svg")} alt="no-photo" />
                        <span>{Resource.modules.PDICheck.noImage}</span>
                      </div>
                    )
                  ) : !PDICheckPhoto ? (
                    <label
                      htmlFor="getImage"
                      className={!!PDICheckPhoto || PDIphotoLoading ? "label disableUpload" : "label"}
                    >
                      <img src={require("../../../assets/images/camera.svg")} alt="camera" />
                      <span>
                        <img src={require("../../../assets/images/add.svg")} alt="add" />
                        {Resource.modules.PDICheck.uploadPhoto}
                      </span>
                    </label>
                  ) : PDICheckPhoto.URL ? (
                    <div
                      className="img"
                      style={{
                        backgroundImage: "url(" + PDICheckPhoto.URL + ")",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    >
                      <ReactFancyBox thumbnail={PDICheckPhoto.URL} image={PDICheckPhoto.URL} />

                      {
                        // !checkPreview ?
                        <div className="remove" onClick={(event) => deletePhoto(event)}>
                          <img src={require("../../../assets/images/remove.svg")} alt="bin" />
                        </div>
                        //  : null
                      }
                    </div>
                  ) : null}

                  <input
                    type="file"
                    id="getImage"
                    accept="image/*"
                    capture="capture"
                    onChange={this.handleImageChange}
                    hidden
                    disabled={!!PDICheckPhoto || PDIphotoLoading}
                  />

                  {PDIphotoLoading ? (
                    <div className="loadingMode">
                      <Spinner scale={1} />
                    </div>
                  ) : null}
                </div>
              </section>
            </div>
            {!checkPreview ? (
              <DialogFooter>
                <DefaultButton
                  onClick={() => this.closeDialog(false)}
                  text={AddPdiDialog.cancel}
                  disabled={addPDICheckLoading}
                />
                <PrimaryButton disabled={this.validateCheck()} onClick={() => this.closeDialog(true)}>
                  {addPDICheckLoading ? <Spinner scale={SpinnerSize.small} color="white" /> : AddPdiDialog.save}
                </PrimaryButton>
              </DialogFooter>
            ) : null}
          </Dialog>
        )}
      </>
    );
  }
}
