import { Query } from "../../../shared/Models/Query";
import { Location } from "../Models/Location";
import { AuthFetch } from "../../../shared/Models/AuthFetch";

export class VehicleLocations implements Query<Location[]> {
  Result(): Promise<Location[]> {
    const myFetch = AuthFetch();
    const url = "/api/Vehicle/Locations";
    return myFetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((locationsJSON: Location[]) => {
        return locationsJSON;
      });
  }
}
