import * as React from 'react';
import { Resource } from '../../../assets/Localization';
import { history } from '../../../modules/helpers/history'
import { Link } from 'react-router-dom';
import logo from "../../../assets/images/toyota-logo.png";
import "./Header.scss";
import App from '../../../App';
import { UnregisterCallback } from 'history';
import { IUser } from '../../../modules/authorization/Models/User';
import { AuthService } from '../../../modules/authorization/Services/AuthService';
import { TruckIcon, ShieldIcon, CarIcon, HomeIcon, CheckIcon, CloudIcon } from './Icons';


interface Props {
	context: App,
	logOut: () => void
}

interface State {
	selectedKey: string;
	menuIsOpen: boolean;
	environment: string;
};
export default class Header extends React.Component<Props, State> {
	unlisten: UnregisterCallback;
	state: State = {
		selectedKey: this.getSelectedKey(),
		menuIsOpen: false,
		environment: '',
	}


	getSelectedKey(): string {
		return history.location.pathname.substr(1);
	}

	getConfiguration =()=>{
		const {getConfiguration} = this.props.context;
		getConfiguration().then((Configuration:string)=>{
			this.setState({environment : Configuration})
			console.log(this.state.environment)
		})
	}

	componentDidMount() {
		this.unlisten = history.listen((location, action) => {
			this.setState({ selectedKey: this.getSelectedKey() });
		});
		this.getConfiguration();
	}
	componentWillUnmount() {
		this.unlisten();
	}

	_onLinkClick = (ev: any, url: any) => {
		ev.preventDefault();
		this._toggleMenu(false)
		history.replace(url);
	}

	_toggleMenu = (value: boolean) => {
		this.setState({ menuIsOpen: value });
	}

	_changeLanguage = () => {
		const { changeLanguage } = this.props.context;
		if (localStorage.getItem('Language') == 'ka') {
			changeLanguage('en')
		} else {
			changeLanguage('ka')
		}
		this._toggleMenu(false)
	}

	_logOut = () => {
		this._toggleMenu(false);
		const { logOut } = this.props;
		logOut();
	}

	render(): JSX.Element {
		const currentUser: IUser | null = AuthService.getCurrentUser();
		const { selectedLocationName } =this.props.context.state;
		const env = this.state.environment.replace(/"/g, '');

		return <div className={this.state.menuIsOpen ? 'header active' : 'header'}>
			<div className="header__top d-flex align-items-center">
				<div className="icon icn-home pointer">
					{/* <Icon iconName="GlobalNavButton" className="deletePhoto" onClick={() => this._toggleMenu(true)} /> */}
					<img src={require('../../../assets/images/bars.png')}  onClick={() => this._toggleMenu(true)}/>
				</div>
				<div className="txt">
					{this.props.context.state.pageTitle}
				</div>
				{
				env === 'Development'  ?
				<div className='txt_env'>
					Test Environment
				</div>
				:
				null
			    }
				
			</div>
			<div className="header__left">
				<div className="header__left__inner d-flex flex-column justify-content-between">
					<div>
						<div className="header__left__head d-flex flex-column ">
							<div className='d-flex justify-content-center align-items-center logo-container'>
								<div className="header__left__head__image">
									<img src={logo} alt="logo" />
									<p className="header__left__head__title">{Resource.shared.Header.title}</p>
								</div>
							</div>

							<div className='d-flex justify-content-between align-items-center'>
								<div className="header__left__head__name">
									{
										currentUser ?
											<b>{currentUser.FullName}</b> :
											null
									}
								  {selectedLocationName && <div className='location-name'>{selectedLocationName}</div>}
								</div>
								<button className="signOut-button ripple" onClick={this._logOut} ><img src={require('../../../assets/images/signout.svg')} alt='signout' />{Resource.shared.Header.logout}</button>
							</div>

						

							{/* <div className="close-menu">
								<IconButton iconProps={{ iconName: 'Cancel' }} title="Close Menu" onClick={() => { this._toggleMenu(false) }} />
							</div> */}
						</div>

						<nav className='nav' role='navigation'>
							<ul className='navmenu'>
								<li><Link
									className={this.getSelectedKey() == '' ? 'is-selected' : ''}
									onClick={(ev) => this._onLinkClick(ev, '/')}
									to='/'>
									<div className='svg-container'>
										<HomeIcon focused={this.getSelectedKey() == '' ? true : false} />
									</div>
									<span>{Resource.shared.Header.home}</span>
								</Link></li>
								<li><Link
									className={this.getSelectedKey() == 'Vehicle-Stocking' ? 'is-selected' : ''}
									onClick={(ev) => this._onLinkClick(ev, '/Vehicle-Stocking')}
									to='/'>
									<div className='svg-container'>
										<CarIcon focused={this.getSelectedKey() == 'Vehicle-Stocking' ? true : false} />
									</div>
									<span>{Resource.shared.Header.vehicleStocking}</span>
								</Link></li>
								<li><Link
									className={this.getSelectedKey() == 'Prepare-for-Dispatch' ? 'is-selected' : ''}
									onClick={(ev) => this._onLinkClick(ev, '/Prepare-for-Dispatch')}
									to='/'>
									<div className='svg-container'>
										<ShieldIcon focused={this.getSelectedKey() == 'Prepare-for-Dispatch' ? true : false} />
									</div>
									<span>{Resource.shared.Header.prepareForDispatch}</span>
								</Link></li>
								<li><Link
									className={this.getSelectedKey() == 'Put-on-Truck' ? 'is-selected' : ''}
									onClick={(ev) => this._onLinkClick(ev, '/Put-on-Truck')}
									to='/'>
									<div className='svg-container'>
										<TruckIcon focused={this.getSelectedKey() == 'Put-on-Truck' ? true : false} />
									</div>
									<span>{Resource.shared.Header.removal}</span></Link></li>
								<li><Link
									className={this.getSelectedKey() == 'PDI-Check' ? 'is-selected' : ''}
									onClick={(ev) => this._onLinkClick(ev, '/PDI-Check')}
									to='/'>
									<div className='svg-container'>
										<CheckIcon focused={this.getSelectedKey() == 'PDI-Check' ? true : false} />
									</div>
									<span>{Resource.shared.Header.pdiCheck}</span>
								</Link></li>
								<li><Link
									className={this.getSelectedKey() == 'CMR-Upload' ? 'is-selected' : ''}
									onClick={(ev) => this._onLinkClick(ev, '/CMR-Upload')}
									to='/'>
									<div className='svg-container'>
										<CloudIcon focused={this.getSelectedKey() == 'CMR-Upload' ? true : false} />
									</div>
									<span>{Resource.shared.Header.cmrUpload}</span>
								</Link></li>
							</ul>
						</nav>


					</div>
					<button
						className='language-switcher'
						onClick={this._changeLanguage}>
						<img src={require('../../../assets/images/globe.svg')} alt='globe' />
						{Resource.shared.Header.language}
					</button>
				</div>
			</div>
			
			<div className="header__right" onClick={() => { this._toggleMenu(false) }}></div>
		</div>
	}
}