import React from "react";
import { DatePicker, Toggle } from "office-ui-fabric-react";
import "./arrival.scss";
import { format } from "date-fns";
import { ArrivalVehicleDTO } from "../../../PDI/Queries/ArrivalVehicles";
import VehicleStockingPage from "../../VehicleStockingPage";
import { Spinner } from "../../../../shared/components/header/Spiner";
import { Resource } from "../../../../assets/Localization";

type Props = {
  date: Date;
  onSelectDate: (date: Date | null | undefined) => void;
  vehicles: ArrivalVehicleDTO[];
  areAllVehiclesVisible: boolean;
  handleShowAllArrivalVehicles: VehicleStockingPage["handleShowAllArrivalVehicles"];
  arrivalVehiclesLoading: boolean;
};

const LoadingStatistics = ({ label, value, isLoading }: { label: string; value: number; isLoading: boolean }) => (
  <>
    {!isLoading ? (
      <>
        <span>{label}</span>
        <span>{value}</span>
      </>
    ) : (
      <Spinner scale={0.8} color="white" />
    )}
  </>
);

const ArrivalFilter = ({
  date,
  onSelectDate,
  vehicles,
  areAllVehiclesVisible,
  handleShowAllArrivalVehicles,
  arrivalVehiclesLoading,
}: Props) => {
  const { arrivals, stocked, all } = Resource.modules.VehicleStocking;
  const { arrivalsCount, stockedCount } = vehicles.reduce(
    (accumulator, currentValue) => {
      return {
        arrivalsCount: currentValue.Stocked ? accumulator.arrivalsCount : accumulator.arrivalsCount + 1,
        stockedCount: !currentValue.Stocked ? accumulator.stockedCount : accumulator.stockedCount + 1,
      };
    },
    { arrivalsCount: 0, stockedCount: 0 }
  );
  return (
    <div>
      <div className="filter-container">
        <div className="date-picker-container">
          <DatePicker
            className="date-picker"
            showMonthPickerAsOverlay={true}
            formatDate={(date) => (date ? format(date, "dd/MM/yyyy") : "")}
            onSelectDate={onSelectDate}
            value={date}
            showGoToToday={false}
            disabled={arrivalVehiclesLoading}
          />
        </div>
        <div className="statistics">
          <div className="statistics-item arrivals">
            <LoadingStatistics label={arrivals ?? ""} value={arrivalsCount} isLoading={arrivalVehiclesLoading} />
          </div>
          <div className="statistics-item stocked">
            <LoadingStatistics label={stocked ?? ""} value={stockedCount} isLoading={arrivalVehiclesLoading} />
          </div>
        </div>
      </div>
      <div className="toggle-container">
        <Toggle
          checked={areAllVehiclesVisible}
          onChange={() => handleShowAllArrivalVehicles(!areAllVehiclesVisible)}
          onText={all}
          offText={all}
        />
      </div>
    </div>
  );
};

export default ArrivalFilter;
