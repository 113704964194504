import { Query } from "../../../shared/Models/Query";
import { AuthFetch } from "../../../shared/Models/AuthFetch";
import { PDI } from "../Models/PDI";
export class PDIDetails implements Query<PDI> {
  readonly pdiId: string;
  constructor(pdiId: string) {
    this.pdiId = pdiId;
  }
  Result(): Promise<PDI> {
    const myFetch = AuthFetch();
    const url = `/api/Vehicle/PDIDetails?pdiId=${this.pdiId}`;
    return myFetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((PDI: PDI) => {
        return PDI;
      });
  }
}
