import * as React from 'react';


interface UserIconProps {
    focused: boolean
};

export const UserIcon = (props: UserIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19.198" viewBox="0 0 16 19.198">
            <g id="Group_4890" data-name="Group 4890" transform="translate(0)">
                <path id="Path_2866" data-name="Path 2866" d="M44.545,12.826a.766.766,0,0,1,.139-.045l2.75-.62v-1.4a4.743,4.743,0,0,1-1.6-3.559V4.417A4.422,4.422,0,0,1,50.253,0h.766a4.422,4.422,0,0,1,4.417,4.417V7.2a4.74,4.74,0,0,1-1.6,3.559v1.4l2.751.62a.783.783,0,0,1,.139.045,3.149,3.149,0,0,1,1.91,2.9v1.65a.8.8,0,0,1-.6.775l-1.334.338a25.932,25.932,0,0,1-6.05.712,26.214,26.214,0,0,1-6.086-.714l-1.324-.336a.8.8,0,0,1-.6-.775v-1.65A3.149,3.149,0,0,1,44.545,12.826Z" transform="translate(-42.635 0)" fill={props.focused ? "#047df3" : '#a6adb5'} />
            </g>
        </svg>
    )
}


export const PasswordIcon = (props: UserIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24">
            <g id="Group_4892" data-name="Group 4892" transform="translate(0)">
                <g id="Group_4891" data-name="Group 4891">
                    <path id="Path_2867" data-name="Path 2867" d="M88.333,9.765V5a5,5,0,1,1,10,0V9.765a8,8,0,1,1-10,0Zm4,8.051V19a1,1,0,0,0,2,0V17.816a3,3,0,1,0-2,0Zm-2-9.231c.1-.04.2-.069.3-.1.126-.045.25-.094.379-.133s.272-.072.409-.105c.114-.029.226-.061.342-.085.162-.032.327-.053.491-.075.1-.013.189-.032.286-.041a7.725,7.725,0,0,1,1.59,0c.1.009.191.028.286.041.165.023.329.043.491.075.116.024.228.056.343.085.136.033.274.064.408.105s.254.088.38.133c.1.036.2.065.3.1V5a3,3,0,0,0-6,0V8.585Z" transform="translate(-85.333 0)" fill={props.focused ? "#047df3" : '#a6adb5'} />
                    <path id="Path_2868" data-name="Path 2868" d="M236,298.673a1.333,1.333,0,1,1-1.333,1.333A1.335,1.335,0,0,1,236,298.673Z" transform="translate(-228 -285.097)" fill={props.focused ? "#047df3" : '#a6adb5'} />
                </g>
            </g>
        </svg>

    )
}
