import { Query } from "../../shared/Models/Query";

export class Configuration implements Query<string> {
  Result(): Promise<string> {
    const url = "/api/Auth/GetEnvironment";
    return fetch(url, {
      method: "GET",
    })
      .then((response) => response.text())
      .then((Configuration: string) => {
        return Configuration;
      });
  }
}
