import { Query } from "../../../shared/Models/Query";
import { RetailerForDispatch } from "../Models/RetailerForDispatch";
import { AuthFetch } from "../../../shared/Models/AuthFetch";

export class RetailersForDispatch implements Query<RetailerForDispatch[]> {
  Result(): Promise<RetailerForDispatch[]> {
    const myFetch = AuthFetch();
    const url = "/api/Vehicle/RetailersForDispatch";
    return myFetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((locationsJSON: RetailerForDispatch[]) => {
        return locationsJSON;
      });
  }
}
