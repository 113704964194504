import { UnregisterCallback } from 'history';
import {
	Icon, ITextField, TextField,

	SpinnerSize
} from 'office-ui-fabric-react';
import * as React from 'react';
import App from '../../App';
import barcode_scanner from "../../assets/images/Barcode_Scanner.svg";
import { Resource } from '../../assets/Localization';
import { GetQueryParamsInUrl } from '../../shared/Models/GetQueryParamsInUrl';
import { SetQueryParamsInUrl } from '../../shared/Models/SetQueryParamsInUrl';
import { DamageType } from '../../shared/Modules/AddPdiDialog/Models/DamageType';
import { EDamageType } from '../../shared/Modules/AddPdiDialog/Models/EDamageType';
import Barcode from '../Barcode/Barcode';
import { history } from '../helpers/history';
import { PDI } from '../PDI/Models/PDI';
import { VehicleForPDI } from './Models/VehicleForPDI';
import "./PDIPage.scss";
import ReactFancyBox from 'react-fancybox';
import Confirmation from '../../shared/Modules/Confirmation/Confirmation';
import { Spinner } from '../../shared/components/header/Spiner'
import exifr from 'exifr';

interface Props {
	context: App
}

interface State {
	VIN: string;
	scannerModeOn: boolean;
	searchedVehicles: VehicleForPDI[];
	showVehicleSearchBox: boolean;
	isVIN: boolean;
	hideConfirmationDialog: boolean;
};


export default class PDIPage extends React.Component<Props, State> {
	unlisten: UnregisterCallback;
	node: any;
	vinTextfield: ITextField | null;
	demageTypes: DamageType[] = [
		{ Id: EDamageType.Grazemark, Name: 'Grazemark' },
		{ Id: EDamageType.Scratch, Name: 'Scratch' },
		{ Id: EDamageType.Chip, Name: 'Chip' },
		{ Id: EDamageType.Dent, Name: 'Dent' },
		{ Id: EDamageType.Broken, Name: 'Broken' },
		{ Id: EDamageType.Severe_Damage, Name: 'Severe Damage' },
		{ Id: EDamageType.Missing, Name: 'Missing' }
	]
	constructor(props: Props) {
		super(props);
		this.state = {
			VIN: new GetQueryParamsInUrl('VIN').Result() || '',
			scannerModeOn: false,
			searchedVehicles: [],
			showVehicleSearchBox: false,
			isVIN: false,
			hideConfirmationDialog: true
		}
		props.context.setState({ pageTitle: Resource.modules.PDICheck.pageTitle });


	}
	getPageData() {
		this.getVehiclesForPDI();
	}


	getVehiclesForPDI = () => {
		const { getVehiclesForPDI } = this.props.context;
		getVehiclesForPDI();
	}

	componentDidMount() {
		this.unlisten = history.listen((location, action) => {
			this.setState({
				VIN: new GetQueryParamsInUrl('VIN').Result() || ''
			});
		});
		this.getPageData();
		document.addEventListener('mousedown', this.handleDocumentClick, false);
	}


	componentWillUnmount() {
		this.unlisten();
		document.removeEventListener('mousedown', this.handleDocumentClick, false);
	}

	handleDocumentClick = (e: MouseEvent) => {
		if (this.node && !this.node.contains(e.target)) {
			this.setState({
				searchedVehicles: [],
				showVehicleSearchBox: false
			})
		}
	}

	getKey = (item: PDI, index?: number): string => {
		return index ? (index + 1).toString() : '1';
	};

	toggleScannerMode = () => {
		this.setState({ scannerModeOn: !this.state.scannerModeOn });
	}


	addPDI = (VIN: string, PDIState: string): void => {
		const { addPDI } = this.props.context;
		this.props.context.setState({ prevPath: `${history.location.pathname}${history.location.search}` });
		addPDI(VIN, PDIState);
	}

	handleScan = (vinCode: string) => {
		const VIN = vinCode.slice(0, 17);
		new SetQueryParamsInUrl('VIN', VIN).Result();
		this.toggleScannerMode();
	}


	getSelectedVehicleForPDI(): VehicleForPDI | undefined {
		const { vehiclesForPDI } = this.props.context.state;
		const { VIN } = this.state;
		let selectedVehicleForPDI = vehiclesForPDI.find(vehicle => vehicle.VIN === VIN);

		// if (selectedVehicleForPDI) {
		// 	exifr.parse(selectedVehicleForPDI.VINPhotoName)
		// 		.then(output => { 
		// 			if (output && output.Orientation && output.Orientation == 'Rotate 90 CW') {
		// 				let photo = document.getElementById('VINPhoto');
		// 				photo!.classList.add('rotateImage');
		// 			}
		// 		})


		// 	exifr.parse(selectedVehicleForPDI.VehiclePhotoName)
		// 		.then(output => {
		// 			if (output && output.Orientation && output.Orientation == 'Rotate 90 CW') {
		// 				let photo = document.getElementById('VehiclePhoto');
		// 				photo!.classList.add('rotateImage');
		// 			}	
		// 		})
		// 	}

		return selectedVehicleForPDI;
	}


	handleError = (err: any) => {
		console.error(err)
	}

	handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		new SetQueryParamsInUrl('VIN', (newValue || '')).Result();
		this.handleVinSearch((newValue || ''));
	};


	clearVin = () => {
		new SetQueryParamsInUrl('VIN', '').Result();
		this.setState({
			searchedVehicles: [],
			showVehicleSearchBox: false
		});
		setTimeout(() => {
			if (this.vinTextfield) {
				this.vinTextfield.focus();
			}
		}, 100);
	}


	handleVinSearch = (VIN: string): void => {
		if (VIN.length > 2) {
			const { vehiclesForPDI } = this.props.context.state;
			this.setState({
				searchedVehicles: vehiclesForPDI.filter(vehicle => vehicle.VIN.toLowerCase().includes(VIN.toLowerCase())),
				showVehicleSearchBox: true
			});
		} else {
			this.setState({
				searchedVehicles: [],
				showVehicleSearchBox: false
			});
		}
	}


	selectVinFromSearchBox = (VIN: string): void => {
		new SetQueryParamsInUrl('VIN', VIN).Result();
		this.setState({
			showVehicleSearchBox: false
		});
	}


	selectPDI = (PDI: PDI) => {
		const { VIN } = this.state;
		history.push(`/Add-PDI-Check?VIN=${VIN}&PDIId=${PDI.Id}`);
	}


	PDIPreview = (PDI: PDI, VIN: string) => {
		this.props.context.setState({ prevPath: `${history.location.pathname}${history.location.search}` });
		history.push(`/Add-PDI-Check?VIN=${VIN}&PDIId=${PDI.Id}`);
	}

	transformDate(inputDate: string, whithTime: boolean) {
		if (inputDate) {
			const options = whithTime ? {
				year: 'numeric', month: 'numeric', day: 'numeric',
				hour: 'numeric', minute: 'numeric', second: 'numeric',
				hour12: false,
				timeZone: 'Asia/Tbilisi'
			} : {
					year: 'numeric', month: 'numeric', day: 'numeric'
				}
			const date: Date = new Date(inputDate);
			let newDate = new Intl.DateTimeFormat('uk-UK', options).format(date);
			newDate = newDate.substr(0, 2) + '/' + newDate.substr(3);
			newDate = newDate.substr(0, 5) + '/' + newDate.substr(6);
			return newDate;
		} else {
			return '';
		}
	}


	handleImageChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
		const { uploadVehiclePhoto } = this.props.context;
		const { VIN } = this.state;
		changeEvent.preventDefault();
		const photo = changeEvent.target.files ? changeEvent.target.files[0] : null;
		if (photo) {
			uploadVehiclePhoto(VIN, photo);
			changeEvent.target.value = '';
		}
	};

	handleVINImageChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
		const { uploadVINPhoto } = this.props.context;
		const { VIN } = this.state;
		changeEvent.preventDefault();
		const photo = changeEvent.target.files ? changeEvent.target.files[0] : null;
		if (photo) {
			uploadVINPhoto(VIN, photo);
			changeEvent.target.value = '';
		}
	};


	confirmation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.stopPropagation();
		this.setState({ hideConfirmationDialog: false, isVIN: false });
	}

	VINconfirmation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.stopPropagation();
		this.setState({ hideConfirmationDialog: false, isVIN: true });
	}

	deletePhoto = (success: boolean) => {
		if (success) {
			const selectedVehicleForStocking: VehicleForPDI | undefined = this.getSelectedVehicleForPDI();
			if (selectedVehicleForStocking) {
				const { vehiclePhotoDelete } = this.props.context;
				const { photoLoading } = this.props.context.state;
				if (!photoLoading) {
					vehiclePhotoDelete(selectedVehicleForStocking.VIN);
				}
			}
		}
		this.setState({ hideConfirmationDialog: true });
	}



	deleteVINPhoto = (success: boolean) => {
		if (success) {
			const selectedVehicleForStocking: VehicleForPDI | undefined = this.getSelectedVehicleForPDI();
			if (selectedVehicleForStocking) {
				const { vehicleVINPhotoDelete } = this.props.context;
				const { photoLoading } = this.props.context.state;
				if (!photoLoading) {
					vehicleVINPhotoDelete(selectedVehicleForStocking.VIN);
				}
			}
		}
		this.setState({ hideConfirmationDialog: true });
	}




	render(): JSX.Element {
		const { PDICheck } = Resource.modules;
		const { vehicleColumns } = Resource.shared;
		const { photoLoading, VINPhotoLoading } = this.props.context.state;
		const { scannerModeOn, showVehicleSearchBox, searchedVehicles, hideConfirmationDialog } = this.state;
		const selectedVehicleForPDI = this.getSelectedVehicleForPDI();


		return <>
			<div className="PDIPage page-container">
				{
					scannerModeOn ?
						<div className="BarcodeReader">
							<Barcode onDetected={this.handleScan} handleScannerMode={this.toggleScannerMode} />
						</div>
						:
						<>
							<div className="top">

								<section className="VehicleSearch-section d-flex align-items-center justify-content-between">
									<div className="qr-wrapper"
										onClick={this.toggleScannerMode} >
										<img src={barcode_scanner} alt="" />
									</div>
									<div className="textField-wrapper" ref={node => this.node = node}>
										<img src={require('../../assets/images/search-icon.svg')} alt='search icon' className='searchIcon' />
										<TextField maxLength={17} autoComplete='off' value={this.state.VIN} onChange={this.handleChange} placeholder={PDICheck.enterVin}
											onFocus={() => this.handleVinSearch(this.state.VIN)} componentRef={((ref: ITextField | null) => {
												this.vinTextfield = ref;
											})} />
										{
											showVehicleSearchBox ?
												<div className='VehicleSearchBox'>
													{
														searchedVehicles.map((item, index) => {
															return <div className="VehicleSearchBox__item" key={index} onClick={() => this.selectVinFromSearchBox(item.VIN)}>
																{item.VIN}
															</div>
														})
													}
													{
														searchedVehicles.length === 0 ?
															<span className="VehicleSearchBox__item">
																{Resource.shared.Messages.noResults}
															</span> :
															null
													}
												</div> :
												null
										}
										{
											this.state.VIN ?
												<Icon iconName="Cancel" className="ClearVin" onClick={this.clearVin} /> :
												null
										}
									</div>
								</section>
								{
									selectedVehicleForPDI ?
										<>
											<section className="VehicleDetails-section">
												<h3 className="VehicleDetails-section__title">{PDICheck.vehicleDetails}</h3>
												<div className="vehicle-card">
													<div className="items">
														<div className="item">
															<div className="labels">
																<div className="labelsTxt">{vehicleColumns.Model}</div>
																<div className="labelsTxt">{vehicleColumns.Color}</div>
																<div className="labelsTxt">{vehicleColumns.ProductionDate}</div>
															</div>
															<div className="values">
																<div className="valuesTxt">{selectedVehicleForPDI.Model}</div>
																<div className="valuesTxt">{selectedVehicleForPDI.Color}</div>
																<div className="valuesTxt">{this.transformDate(selectedVehicleForPDI.ProductionDate, false)}</div>
															</div>
														</div>
														<div className="item">
															<div className="labels">
																<div className="labelsTxt">{vehicleColumns.TTCInvoiceNumber}</div>
																<div className="labelsTxt">{vehicleColumns.Location}</div>
																<div className="labelsTxt">{vehicleColumns.State}</div>
															</div>
															<div className="values">
																<div className="valuesTxt">{selectedVehicleForPDI.TTCInvoice}</div>
																<div className="valuesTxt">{selectedVehicleForPDI.Location}</div>
																<div className="valuesTxt">{selectedVehicleForPDI.State}</div>
															</div>
														</div>
													</div>
												</div>
											</section>

											{selectedVehicleForPDI ?
												<section className="PDIDetails-section">
													<h3 className="VehicleDetails-section__title">{Resource.modules.PDICheck.PDICheck}</h3>
													<div className="PDIChecksList">
														<div className="addPDIButton">
															<div className="addPDIButtonWrapper" onClick={() => this.addPDI(selectedVehicleForPDI.VIN, selectedVehicleForPDI.PDIState)}>
																<Icon iconName="CircleAdditionSolid" className="CircleAdditionSolidIcon" />
																<div className="txt">{Resource.modules.PDICheck.addPDI}</div>
															</div>
														</div>
														<div className="PDIChecksLisWrapper">
															{
																selectedVehicleForPDI.PDIs.map((item, index) => {
																	return <div className="PDIChecksListItem" key={index} onClick={() => this.PDIPreview(item, selectedVehicleForPDI.VIN)}>
																		<div className="PDIChecksListItemPDICheksCount">
																			{item.PDIChecksCount !== 0 ?
																				`${item.PDIChecksCount} ${Resource.modules.PDICheck.damage}` : Resource.modules.PDICheck.noDamageShort}</div>
																		<div className="PDIChecksListItemName">{item.Name}</div>
																		<div className="PDIChecksListItemAuthor">{item.Author}</div>
																		<div className="PDIChecksListItemDate">{this.transformDate(item.CreateDate, true)}</div>
																	</div>

																})
															}
														</div>
													</div>
												</section>
												: null
											}

											<section className="VehicleDetails-section">
												<div className="d-flex align-items-center justify-content-between">
													<h3 className="VehiclePDI-section__title">{Resource.modules.VehicleStocking.photos}</h3>
												</div>
												<div className="CarPhotos">
													{!selectedVehicleForPDI.VehiclePhotoName &&
														<label htmlFor="getImage" className="addPhotoButton">
															<div className="addPDIButtonWrapper" >
																<img src={require('../../assets/images/camera-blue.svg')} alt='camera' />
																<div className="txt">{Resource.modules.VehicleStocking.addCarPhoto}</div>
															</div>

														</label>
													}
													<input type="file" id="getImage" accept="image/*" capture="capture" onChange={this.handleImageChange} hidden />

													{selectedVehicleForPDI ?
														<>
															{selectedVehicleForPDI.VehiclePhotoName &&
																<div id='VehiclePhoto' className={`uploadedImg `} key={1} style={{
																	backgroundImage: 'url(' + selectedVehicleForPDI.VehiclePhotoName + ')',
																	backgroundPosition: 'center',
																	backgroundRepeat: 'no-repeat',
																	backgroundSize: 'cover'
																}}
																>
																	<ReactFancyBox
																		thumbnail={selectedVehicleForPDI.VehiclePhotoName}
																		image={selectedVehicleForPDI.VehiclePhotoName} />
																	<div className="remove" onClick={this.confirmation}>
																		<img src={require('../../assets/images/remove.svg')} alt='bin' />
																	</div>
																</div>
															}
														</>
														: null}


													{photoLoading ?
														<div className="loadingMode ">
															<Spinner scale={1} />
														</div> :
														null
													}
													<Confirmation
														text={this.state.isVIN ? Resource.shared.Modules.Confirmation.deleteVINPhoto : Resource.shared.Modules.Confirmation.deleteVehiclePhoto}
														hideConfirmationDialog={hideConfirmationDialog}
														title={Resource.shared.Modules.Confirmation.deletePhoto}
														closeConfirmationDialog={this.state.isVIN ? this.deleteVINPhoto : this.deletePhoto}
													>

													</Confirmation>
													{!selectedVehicleForPDI.VINPhotoName &&
														<label htmlFor="getVINImage" className="addPhotoButton">
															<div className="addPDIButtonWrapper" >
																<img src={require('../../assets/images/camera-blue.svg')} alt='camera' />
																<div className="txt">{Resource.modules.VehicleStocking.addCarVINPhoto}</div>
															</div>

														</label>
													}
													<input type="file" id="getVINImage" accept="image/*" capture="capture" onChange={this.handleVINImageChange} hidden />


													{VINPhotoLoading ?
														<div className="loadingMode loadingModeVIN">
															<Spinner scale={1} />
														</div> :
														null
													}

													{selectedVehicleForPDI ?
														<>
															{selectedVehicleForPDI.VINPhotoName &&
																<div id='VINPhoto' className={`uploadedImg`} key={1} style={{
																	backgroundImage: 'url(' + selectedVehicleForPDI.VINPhotoName + ')',
																	backgroundPosition: 'center',
																	backgroundRepeat: 'no-repeat',
																	backgroundSize: 'cover'
																}}
																>

																	<ReactFancyBox
																		thumbnail={selectedVehicleForPDI.VINPhotoName}
																		image={selectedVehicleForPDI.VINPhotoName} />
																	<div className="remove" onClick={this.VINconfirmation}>
																		<img src={require('../../assets/images/remove.svg')} alt='bin' />
																	</div>
																</div>
															}
														</>
														: null
													}
												</div>
											</section>

										</> :
										<div className='search-car-icon'>
											<img src={require('../../assets/images/car-search.png')} alt='search car' />
											<div className='search-icon'>
												<img src={require('../../assets/images/search-white.svg')} alt='search white' />
											</div>
											<p>{Resource.modules.PDICheck.searchPlaceholder}</p>
										</div>
								}


							</div>
							{selectedVehicleForPDI && <div className="bottom">
								<div className="text-center finish">
									<button onClick={this.clearVin} disabled={!selectedVehicleForPDI}>{Resource.shared.Modules.Confirmation.finish}</button>
								</div>
							</div>
							}
						</>
				}
			</div>
		</>
	}
}