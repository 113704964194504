import React, { FC } from "react";
import { ArrivalVehicleDTO } from "../../../PDI/Queries/ArrivalVehicles";

type Props = {
  vehicles: ArrivalVehicleDTO[];
  areAllVehiclesVisible: boolean;
};

const ArrivalList = ({ vehicles, areAllVehiclesVisible }: Props) => {
  const visibleVehicles = !areAllVehiclesVisible ? vehicles.filter((item) => item.Stocked) : vehicles;
  return (
    <div className="arrival-list-container">
      {visibleVehicles.map((item) => (
        <div className="vehicle" key={item.VIN}>
          <div className="status">
            {item.Stocked ? (
              <img src={require("../../../../assets/images/green-mark.svg")} alt="checkmark" />
            ) : (
              <img src={require("../../../../assets/images/clock.svg")} alt="checkmark" />
            )}
          </div>
          <div className="vehicleDetailsItem">
            <div className="value VIN">{item.VIN}</div>
            <div className="value">{item.Color}</div>
          </div>
          <div className="vehicleDetailsItem">
            <div className="value">{item.Model} </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ArrivalList;
