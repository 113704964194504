import { Query } from "../../../shared/Models/Query";
import { AuthFetch } from "../../../shared/Models/AuthFetch";
import { VehicleForPDI } from "../Models/VehicleForPDI";

export class VehiclesForPDI implements Query<VehicleForPDI[]> {
  Result(): Promise<VehicleForPDI[]> {
    const myFetch = AuthFetch();
    const url = "/api/Vehicle/Details";
    return myFetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((locationsJSON: VehicleForPDI[]) => {
        return locationsJSON;
      });
  }
}
