import { Query } from "../../../shared/Models/Query";
import { AuthFetch } from "../../../shared/Models/AuthFetch";

export class VehicleVINPhotoDelete implements Query<Response>{
	readonly VIN: string;
	constructor(VIN: string) {
		this.VIN = VIN;
	}
	Result(): Promise<Response> {
		const myFetch = AuthFetch();
		const url = `api/Vehicle/DeleteVINPhoto?vin=${this.VIN}`;
		return myFetch(url, {
			method: 'POST'
		})
	}
}
