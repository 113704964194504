import { Query } from "../../../shared/Models/Query";
import { AuthFetch } from "../../../shared/Models/AuthFetch";

export class VehicleVINPhoto implements Query<Response> {
  readonly VIN: String;
  readonly PhotoName: String;
  constructor(VIN: String, PhotoName: String) {
    this.VIN = VIN;
    this.PhotoName = PhotoName;
  }
  Result(): Promise<Response> {
    const myFetch = AuthFetch();
    const url = `/api/Vehicle/VINPhoto`;
    return myFetch(url, {
      method: "POST",
      body: JSON.stringify({ vin: this.VIN, photoName: this.PhotoName }),
    });
  }
}
