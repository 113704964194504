import React from "react";
import { Spinner } from "../../../shared/components/header/Spiner";
import Confirmation from "../../../shared/Modules/Confirmation/Confirmation";
import ReactFancyBox from "react-fancybox";
import { VehicleForStocking } from "../Models/VehicleForStocking";
import VehicleStockingPage from "../VehicleStockingPage";
import { Resource } from "../../../assets/Localization";
import "../VehicleStockingPage.scss";

type Props = {
  selectedVehicleForStocking: VehicleForStocking;
  hideConfirmationDialog: boolean;
  photoLoading: boolean;
  VINPhotoLoading: boolean;
  handleImageChange: VehicleStockingPage["handleImageChange"];
  handleVINImageChange: VehicleStockingPage["handleVINImageChange"];
  confirmation: VehicleStockingPage["confirmation"];
  VINconfirmation: VehicleStockingPage["VINconfirmation"];
  deletePhoto: VehicleStockingPage["deletePhoto"];
  deleteVINPhoto: VehicleStockingPage["deleteVINPhoto"];
  addPDI: VehicleStockingPage["addPDI"];
  PDIPreview: VehicleStockingPage["PDIPreview"];
  transformDate: VehicleStockingPage["transformDate"];
  isVIN: boolean;
};

const StockingVehicle = ({
  selectedVehicleForStocking,
  hideConfirmationDialog,
  photoLoading,
  VINPhotoLoading,
  handleImageChange,
  handleVINImageChange,
  confirmation,
  VINconfirmation,
  deletePhoto,
  deleteVINPhoto,
  addPDI,
  PDIPreview,
  transformDate,
  isVIN,
}: Props) => {
  const { VehicleStocking, PDICheck } = Resource.modules;
  const { vehicleColumns } = Resource.shared;
  console.log("selectedVehicleForStocking", selectedVehicleForStocking);
  return (
    <>
      <section className="VehicleDetails-section">
        <h3 className="VehicleDetails-section__title">{vehicleColumns.Title}</h3>
        <div className="vehicle-card">
          <div className="items">
            <div className="item">
              <div className="labels">
                <div className="labelsTxt">{vehicleColumns.Model}</div>
                <div className="labelsTxt">{vehicleColumns.Color}</div>
                <div className="labelsTxt">{vehicleColumns.ProductionDate}</div>
              </div>
              <div className="values">
                <div className="valuesTxt">{selectedVehicleForStocking.Model}</div>
                <div className="valuesTxt">{selectedVehicleForStocking.Color}</div>
                <div className="valuesTxt">{transformDate(selectedVehicleForStocking.ProductionDate, false)}</div>
              </div>
            </div>
            <div className="item">
              <div className="labels">
                <div className="labelsTxt">{vehicleColumns.TTCInvoiceNumber}</div>
                <div className="labelsTxt">{vehicleColumns.Location}</div>
                <div className="labelsTxt">{vehicleColumns.State}</div>
              </div>
              <div className="values">
                <div className="valuesTxt">{selectedVehicleForStocking.TTCInvoiceNumber}</div>
                <div className="valuesTxt">{selectedVehicleForStocking.Location}</div>
                <div className={selectedVehicleForStocking.Stocked ? "valuesTxt active" : "valuesTxt"}>
                  {selectedVehicleForStocking.State}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="VehiclePDI-section">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="VehiclePDI-section__title">{VehicleStocking.pdiCheck}</h3>
        </div>
        <div className="PDIChecksList">
          <div className="addPDIButton">
            <div
              className="addPDIButtonWrapper"
              onClick={() => addPDI(selectedVehicleForStocking.VIN, selectedVehicleForStocking.PDIState)}
            >
              {/* <Icon iconName="CircleAdditionSolid" className="CircleAdditionSolidIcon" /> */}
              <div className="icon icn-add pointer">
                <img src={require("../../../assets/images/plus.png")} />
              </div>
              <div className="txt">{VehicleStocking.addPDI}</div>
            </div>
          </div>
          <div className="PDIChecksLisWrapper">
            {selectedVehicleForStocking.PDIs.map((item, index) => {
              return (
                <div
                  className="PDIChecksListItem"
                  key={index}
                  onClick={() => PDIPreview(item, selectedVehicleForStocking.VIN)}
                >
                  <div className="PDIChecksListItemPDICheksCount">
                    {item.PDIChecksCount !== 0 ? `${item.PDIChecksCount} ${PDICheck.damage}` : PDICheck.noDamageShort}
                  </div>
                  <div className="PDIChecksListItemName">{item.Name}</div>
                  <div className="PDIChecksListItemAuthor">{item.Author}</div>
                  <div className="PDIChecksListItemDate">{transformDate(item.CreateDate, true)}</div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="VehiclePDI-section">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="VehiclePDI-section__title">{VehicleStocking.photos}</h3>
        </div>
        <div className="CarPhotos">
          {!selectedVehicleForStocking.VehiclePhotoName && (
            <label htmlFor="getImage" className="addPhotoButton">
              <div className="addPDIButtonWrapper">
                <img src={require("../../../assets/images/camera-blue.svg")} alt="camera" />
                <div className="txt">{VehicleStocking.addCarPhoto}</div>
              </div>
            </label>
          )}
          <input type="file" id="getImage" accept="image/*" capture="capture" onChange={handleImageChange} hidden />

          {selectedVehicleForStocking ? (
            <>
              {selectedVehicleForStocking.VehiclePhotoName && (
                <div
                  id="VehiclePhoto"
                  className="uploadedImg"
                  key={1}
                  style={{
                    backgroundImage: "url(" + selectedVehicleForStocking.VehiclePhotoName + ")",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <ReactFancyBox
                    thumbnail={selectedVehicleForStocking.VehiclePhotoName}
                    image={selectedVehicleForStocking.VehiclePhotoName}
                  />
                  <div className="remove" onClick={confirmation}>
                    <img src={require("../../../assets/images/remove.svg")} alt="bin" />
                  </div>
                </div>
              )}
            </>
          ) : null}

          {photoLoading ? (
            <div className="loadingMode ">
              <Spinner scale={1} />
            </div>
          ) : null}
          <Confirmation
            text={
              isVIN
                ? Resource.shared.Modules.Confirmation.deleteVINPhoto
                : Resource.shared.Modules.Confirmation.deleteVehiclePhoto
            }
            hideConfirmationDialog={hideConfirmationDialog}
            title={Resource.shared.Modules.Confirmation.deletePhoto}
            closeConfirmationDialog={isVIN ? deleteVINPhoto : deletePhoto}
          ></Confirmation>

          {!selectedVehicleForStocking.VINPhotoName && (
            <label htmlFor="getVINImage" className="addPhotoButton">
              <div className="addPDIButtonWrapper">
                <img src={require("../../../assets/images/camera-blue.svg")} alt="camera" />
                <div className="txt">{VehicleStocking.addCarVINPhoto}</div>
              </div>
            </label>
          )}
          <input
            type="file"
            id="getVINImage"
            accept="image/*"
            capture="capture"
            onChange={handleVINImageChange}
            hidden
          />

          {VINPhotoLoading ? (
            <div className="loadingMode loadingModeVIN">
              <Spinner scale={1} />
            </div>
          ) : null}
          {selectedVehicleForStocking ? (
            <>
              {selectedVehicleForStocking.VINPhotoName && (
                <div
                  id="VINPhoto"
                  className="uploadedImg"
                  key={1}
                  style={{
                    backgroundImage: "url(" + selectedVehicleForStocking.VINPhotoName + ")",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <ReactFancyBox
                    thumbnail={selectedVehicleForStocking.VINPhotoName}
                    image={selectedVehicleForStocking.VINPhotoName}
                  />
                  <div className="remove" onClick={VINconfirmation}>
                    <img src={require("../../../assets/images/remove.svg")} alt="bin" />
                  </div>
                </div>
              )}
            </>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default StockingVehicle;
