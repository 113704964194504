import * as React from 'react';
import { TextField, PrimaryButton } from "office-ui-fabric-react";
import logo from "../../../assets/images/toyota-logo.png";
import "./LoginPage.scss";
import { AuthService } from '../Services/AuthService';
import { history } from '../../helpers/history';
import App from '../../../App';
import { UserIcon, PasswordIcon } from './Icons';
import { Resource } from '../../../assets/Localization';
import { Location } from '../../../modules/vehicle-stocking-page/Models/Location';

interface Props {
	context: App
}

interface State {
	Username: string;
	Password: string;
	userNameFocused: boolean;
	passwordFocused: boolean;
	shouldBeFixed: boolean;
	SelectedLocationId: string;
	locations: Location[];
};
export default class LoginPage extends React.Component<Props, State>  {
	constructor(props: Props) {
		super(props);

		// redirect to home if already logged in
		if (AuthService.getCurrentUser()) {
			history.push("/");
		}
		this.state = {
			Username: '',
			Password: '',
			userNameFocused: false,
			passwordFocused: false,
			shouldBeFixed: false,
			SelectedLocationId: '',
			locations: []
		}
	}

	componentDidMount = async () => {
		const { getVehicleLocations } = this.props.context;
		const vehicleLocations = await getVehicleLocations();
		this.setState({ locations: vehicleLocations.slice(0,2) });
	}

	handleUsernameChange = (event: any, newValue?: string) => {
		this.setState({ Username: event.target.value });
	};


	handlePasswordChange = (event: any, newValue?: string) => {
		this.setState({ Password: event.target.value });
	};

	login = (event: React.FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		const { Username, Password } = this.state;
		const { logIn, setCurrentLocation } = this.props.context;
		setCurrentLocation(this.state.SelectedLocationId);
		logIn(Username, Password);
	}

	changeLanguage = () => {
		const { changeLanguage } = this.props.context;
		if (localStorage.getItem('Language') == 'ka') {
			changeLanguage('en')
		} else {
			changeLanguage('ka')
		}
	}

	setLocation = (locationId: string) => {
		this.setState({ SelectedLocationId: locationId });
	}


	render() {
		const isEmpty = (str: string | null) => !str || str.length === 0;
		const isLoginDisabled = isEmpty(this.state.Username) || isEmpty(this.state.Password) || isEmpty(this.state.SelectedLocationId);

		return (
			<div className={`login-page d-flex justify-content-center ${this.state.shouldBeFixed ? 'fixed-login' : ''}`} >
				<div className="container">
					<div className="logo text-center">
						<img src={logo} alt="Toyota logo" />
					</div>
					<br />
					<h1 className="login-page__title text-center">{Resource.shared.Login.avmsLogistics}</h1>
					<form className="login-page__form" onSubmit={this.login}>
						<p className='text-center form__title'>{Resource.shared.Login.title}</p>

						<div className={`${this.state.userNameFocused ? 'bordered' : ''} new-field form__username login-page__row`}>
							<label>
								<input
									autoFocus
									autoCapitalize='off'
									type='text'
									placeholder=" "
									onChange={(e) => this.handleUsernameChange(e)}
									value={this.state.Username}
									onFocus={() => this.setState({ userNameFocused: true, shouldBeFixed: true })}
									onBlur={() => this.setState({ userNameFocused: false, shouldBeFixed: false })}
								/>
								<span>{Resource.shared.Login.usernamePH}</span>
							</label>
							<div className='icon'><UserIcon focused={this.state.userNameFocused} /></div>
						</div>

						<div className={`${this.state.passwordFocused ? 'bordered' : ''} new-field form__password login-page__row`}>
							<label>
								<input
									type='password'
									autoCapitalize='off'
									placeholder=" "
									onChange={(e) => this.handlePasswordChange(e)}
									value={this.state.Password}
									onFocus={() => this.setState({ passwordFocused: true, shouldBeFixed: true })}
									onBlur={() => this.setState({ passwordFocused: false, shouldBeFixed: false })}
								/>
								<span>{Resource.shared.Login.passWordPH}</span>
							</label>
							<div className='icon'><PasswordIcon focused={this.state.passwordFocused} /></div>
						</div>
						<div className='row justify-content-center'>
							<div className="btn-group btn-group-toggle mb-2" style={{width: '360px'}}>
								{/* {this.state.locations.map(location =>
								<label className={`${this.state.SelectedLocationId === location.Id ? 'active' : ''} btn btn-light`} key={location.Id}>
									<input type='radio' onClick={() => this.setLocation(location.Id)} checked={this.state.SelectedLocationId === location.Id} /> {location.Name}
									</label>)} */}
								{this.state.locations.map(location =>
									<div className= {`${this.state.SelectedLocationId === location.Id ? 'active' : ''} custom-control custom-radio mr-5`} key={location.Id}>
										<input className="custom-control-input" type="radio" onClick={() => this.setLocation(location.Id)} checked={this.state.SelectedLocationId === location.Id}/>
										<label className="custom-control-label" onClick={() => this.setLocation(location.Id)}> {location.Name}</label>
									</div>)}
							</div>
							
						</div>
						<div className="form__submit mt-3 login-page__row">
							<button disabled={isLoginDisabled}  className='ripple' type="submit">{Resource.shared.Login.signin}</button>
						</div>
					</form>
				</div>
				
				<button
						className={`language-switcher`}
						onClick={this.changeLanguage}>
						<img src={require('../../../assets/images/globe.svg')} alt='globe' />
						{Resource.shared.Header.language}
					</button>
			</div>
		);
	}
}
