import { UnregisterCallback } from "history";
import {
  CompoundButton,
  IconButton,
  Pivot,
  PivotItem,
  PivotLinkFormat,
  PivotLinkSize,
  TextField,
  Toggle,
} from "office-ui-fabric-react";
import * as React from "react";
import App from "../../App";
import imageBlobs from "../../assets/imageBlobs.json";
import NoImage from "../../assets/images/NoImage.png";
import { Resource } from "../../assets/Localization";
import { GetQueryParamsInUrl } from "../../shared/Models/GetQueryParamsInUrl";
import AddPdiDialog from "../../shared/Modules/AddPdiDialog/AddPdiDialog";
import { DamageType } from "../../shared/Modules/AddPdiDialog/Models/DamageType";
import { EDamageType } from "../../shared/Modules/AddPdiDialog/Models/EDamageType";
import Confirmation from "../../shared/Modules/Confirmation/Confirmation";
import { history } from "../helpers/history";
import { PDICheck } from "./Models/PDICheck";
import { PDI } from "./Models/PDI";
import { VehicleForPDI } from "./Models/VehicleForPDI";
import { NotificationManager } from "react-notifications";
import "./PDICheckPage.scss";
import "./Circles.scss";
import { Spinner } from "../../shared/components/header/Spiner";
interface Props {
  context: App;
}

interface State {
  VIN: string;
  PDIId: string;
  hideAddPdiDialog: boolean;
  showNumbers: boolean;
  selectedPartName: string;
  checkIdForDelete: string;
  selectedPartNumber: string;
  scaleVehicle: number;
  clientX: number;
  clientY: number;
  checkPreview: boolean;
  hideConfirmationDialog: boolean;
  comment: string | undefined;
  status: string;
  hidePrintDialog: boolean;
  addPDICheckLoading: boolean;
  isFinishPDILoading: boolean;
}

export default class PDICheckPage extends React.Component<Props, State> {
  unlisten: UnregisterCallback;
  vehicleParts: any = {
    1: "COVER, ENGINE UNDER",
    2: "COVER, FRONT BUMPER",
    3: "GRILLE, RADIATOR",
    4: "HOOD SUB-ASSY",
    5: "HEADLAMP ASSY, RH",
    6: "HEADLAMP ASSY, LH",
    7: "FOG LAMP FR RH",
    8: "FOG LAMP FR LH",
    9: "COVER, REAR BUMPER",
    10: "LAMP ASSY, REAR  RH",
    11: "LAMP ASSY, REAR  LH",
    12: "REFLECTOR ASSY, REFLEX, LH",
    13: "REFLECTOR ASSY, REFLEX, RH",
    14: "LUGGAGE COMPARTMENT DOOR",
    15: "GARNISH SUB-ASSY, LUGGAGE COMPARTMENT DOOR",
    16: "FENDER SUB-ASSY, FRONT RH",
    17: "MIRROR ASSY, OUTER REAR VIEW, RH",
    18: "PANEL SUB-ASSY, FRONT DOOR, RH",
    19: "GLASS SUB-ASSY, FRONT DOOR, RH",
    20: "PANEL SUB-ASSY, REAR DOOR, RH",
    21: "GLASS SUB-ASSY, REAR DOOR, RH",
    22: "MUDGUARD, FRONT FENDER, RH",
    23: "MUDGUARD, QUARTER PANEL, REAR RH",
    24: "PANEL, QUARTER, RR, RH",
    25: "MOULDING SUB-ASSY, FRONT DOOR, OUTSIDE RH",
    26: "MOULDING SUB-ASSY, REAR DOOR, OUTSIDE RH",
    27: "FRONT WHEEL DISC RH",
    28: "FRONT TIRE RH",
    29: "REAR WHEEL DISC RH",
    30: "REAR TIRE RH",
    31: "FENDER SUB-ASSY, FRONT LH",
    32: "MIRROR ASSY, OUTER REAR VIEW, LH",
    33: "PANEL SUB-ASSY, FRONT DOOR, LH",
    34: "GLASS SUB-ASSY, FRONT DOOR, LH",
    35: "PANEL SUB-ASSY, REAR DOOR, LH",
    36: "GLASS SUB-ASSY, REAR DOOR, LH",
    37: "MUDGUARD, FRONT FENDER, LH",
    38: "MUDGUARD, QUARTER PANEL, REAR LH",
    39: "PANEL, QUARTER, RR, LH",
    40: "MOULDING SUB-ASSY, FRONT DOOR, OUTSIDE LH",
    41: "MOULDING SUB-ASSY, REAR DOOR, OUTSIDE LH",
    42: "FRONT WHEEL DISC LH",
    43: "FRONT TIRE LH",
    44: "REAR WHEEL DISC LH",
    45: "REAR TIRE LH",
    46: "WIPER BLADES",
    47: "WINDSHIELD FRONT",
    48: "PANEL, ROOF",
    49: "GLASS SUNROOF/SLIDING ROOF",
    50: "WINDSHIELD REAR",
    51: "PAD ASSY, INSTRUMENT PANEL",
    52: "WHEEL ASSY, STEERING",
    53: "BOX SUB-ASSY, CONSOLE",
    54: "FRONT SEAT, RH",
    55: "FRONT SEAT, LH",
    56: "BOARD SUB-ASSY, FRONT DOOR TRIM, RH",
    57: "BOARD SUB-ASSY, FRONT DOOR TRIM, LH",
    58: "FLOOR  MAT FR RH",
    59: "FLOOR  MAT FR LH",
    60: "REAR SEAT, RH",
    61: "REAR SEAT, LH",
    62: "BOARD SUB-ASSY, REAR DOOR TRIM, RH",
    63: "BOARD SUB-ASSY, REAR DOOR TRIM, LH",
    64: "FLOOR  MAT RR RH",
    65: "FLOOR  MAT RR LH",
    66: "SEAT 3RD ROW",
    67: "FLOOR  MAT 3RD ROW/TRUNK LINER",
    68: "REAR WIEW MIRROR",
    69: "ROOF PANEL",
    70: "Roof rail, LH",
    71: "Roof rail, RH",
    72: "Sidestep, LH",
    73: "Sidestep, RH",
  };
  demageTypes: DamageType[] = [
    { Id: EDamageType.Grazemark, Name: "Grazemark" },
    { Id: EDamageType.Scratch, Name: "Scratch" },
    { Id: EDamageType.Chip, Name: "Chip" },
    { Id: EDamageType.Dent, Name: "Dent" },
    { Id: EDamageType.Broken, Name: "Broken" },
    { Id: EDamageType.Severe_Damage, Name: "Severe Damage" },
    { Id: EDamageType.Missing, Name: "Missing" },
  ];
  constructor(props: Props) {
    super(props);
    this.state = {
      VIN: new GetQueryParamsInUrl("VIN").Result() || "",
      PDIId: new GetQueryParamsInUrl("PDIId").Result() || "",
      hideAddPdiDialog: true,
      showNumbers: true,
      selectedPartName: "",
      selectedPartNumber: "",
      checkIdForDelete: "",
      scaleVehicle: 1,
      clientX: 0,
      clientY: 0,
      checkPreview: false,
      hideConfirmationDialog: true,
      comment: undefined,
      status: "",
      hidePrintDialog: true,
      addPDICheckLoading: false,
      isFinishPDILoading: false,
    };
  }

  componentDidMount() {
    this.props.context.setState({
      hideLocationSelector: true,
    });
    this.unlisten = history.listen((location, action) => {
      this.setState({
        VIN: new GetQueryParamsInUrl("VIN").Result() || "",
      });
    });
    this.getPageData();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  getPageData() {
    this.getVehicleDamaged();
    this.getPDIDetails();
  }

  getPDIDetails = () => {
    const { getPDIDetails } = this.props.context;
    const { PDIId } = this.state;
    getPDIDetails(PDIId).then((PDI: PDI) => {
      this.setState({ status: PDI.Status });
      this.props.context.setState({ pageTitle: this.transformDate(PDI.CreateDate, true) + " - " + PDI.VIN });
    });
  };

  getVehicleDamaged = () => {
    const { vehicleDamaged } = this.props.context;
    const { VIN } = this.state;
    vehicleDamaged(VIN);
  };

  getSelectedVehicleForPDI(): VehicleForPDI | undefined {
    const { vehiclesForPDI } = this.props.context.state;
    const { VIN } = this.state;
    let selectedVehicleForPDI = vehiclesForPDI.find((vehicle) => vehicle.VIN === VIN);

    return selectedVehicleForPDI;
  }

  selectPart = (event: React.MouseEvent<SVGGElement, MouseEvent>, partId: number) => {
    event.stopPropagation();
    const name = this.vehicleParts[partId];
    if (this.state.status === "Active") {
      return;
    }
    this.setState({
      selectedPartName: name.toString(),
      selectedPartNumber: partId.toString(),
      hideAddPdiDialog: false,
    });
  };

  handleCommentChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ): void => {
    if (this.state.status == "Active") {
      return;
    }
    this.setState({
      comment: newValue,
    });
  };

  comment = () => {
    const { comment } = this.props.context;
    const { selectedPDI } = this.props.context.state;
    return comment(this.state.VIN, selectedPDI ? selectedPDI.Id : "", this.state.comment ? this.state.comment : "");
  };

  goBack = (): void => {
    // const { comment } = this.state;
    const { selectedPDI, prevPath } = this.props.context.state;
    // if (comment !== undefined && selectedPDI && selectedPDI.Comment !== comment) {
    // 	this.comment();
    // }
    this.zoomOut();
    this.props.context.setState({
      selectedPDI: undefined,
    });
    history.push(prevPath);
  };

  finishPDI = (VIN: string, PDIid: string): void => {
    const { finishPDI } = this.props.context;
    const { comment } = this.state;
    const { selectedPDI } = this.props.context.state;

    const uploadPDI = () => {
      return finishPDI(VIN, PDIid).finally(this.goBack);
    };

    const _finishPDI = () => {
      return new Promise((resolve) => {
        if (comment !== undefined && selectedPDI && selectedPDI.Comment !== comment) {
          resolve(this.comment().then(uploadPDI));
        } else {
          resolve(uploadPDI());
        }
      });
    };
    this.setState({ isFinishPDILoading: true });
    _finishPDI().finally(() => {
      this.setState({ isFinishPDILoading: false });
    });
  };

  deleteCheck = (success: boolean) => {
    if (success) {
      const { checkIdForDelete } = this.state;
      const { deleteCheck } = this.props.context;
      const { VehicleDamaged, selectedPDI } = this.props.context.state;
      deleteCheck(this.state.VIN, checkIdForDelete, selectedPDI ? selectedPDI.Id : "", VehicleDamaged);
    }
    this.setState({ hideConfirmationDialog: true, checkIdForDelete: "" });
  };

  confirmation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, checkId: string) => {
    event.stopPropagation();
    this.setState({ hideConfirmationDialog: false, checkIdForDelete: checkId });
  };

  PrintConfirmation = () => {
    this.setState({ hidePrintDialog: false });
  };

  previewCheck = (check: PDICheck) => {
    this.props.context.setState({
      PDICheckPhoto: {
        URL: check.PhotoURL,
        Name: check.CheckId,
        OriginName: check.CheckId,
        OriginURL: check.OriginphotoURL,
      },
      DemageType: this.demageTypes.find((demageType) => demageType.Name === check.DamageType),
    });

    this.setState({
      checkPreview: true,
      selectedPartNumber: check.PartId.toString(),
      selectedPartName: this.vehicleParts[check.PartId],
      hideAddPdiDialog: false,
    });
  };

  handleDemageType = (demageType: DamageType | undefined) => {
    const { checkPreview } = this.state;
    if (!checkPreview) {
      const { context } = this.props;
      context.setState({ DemageType: demageType });
    }
  };

  clearDataAfterSave = () => {
    this.props.context.setState({
      PDICheckPhoto: undefined,
      DemageType: undefined,
    });
    this.setState({
      selectedPartNumber: "",
      selectedPartName: "",
      hideAddPdiDialog: true,
      checkPreview: false,
    });
  };

  closeAddPdiDialog = (success: boolean): void => {
    const { addCheck } = this.props.context;
    const { VehicleDamaged, selectedPDI } = this.props.context.state;
    if (success) {
      this.zoomOut();
      this.setState({ addPDICheckLoading: true });
      addCheck(
        this.state.VIN,
        this.props.context.state.PDICheckPhoto ? this.props.context.state.PDICheckPhoto.Name : "",
        this.props.context.state.PDICheckPhoto ? this.props.context.state.PDICheckPhoto.OriginName : "",
        selectedPDI ? selectedPDI.Id : "",
        Number(this.state.selectedPartNumber),
        this.props.context.state.DemageType ? this.props.context.state.DemageType.Id : undefined,
        VehicleDamaged,
        this.vehicleParts[this.state.selectedPartNumber],
        this.props.context.state.PDICheckPhoto?.Photo
      )
        .then(
          (res: Response) => {
            if (res.status === 200) {
              this.clearDataAfterSave();
            } else {
              NotificationManager.error(Resource.shared.Modules.AddPdiDialog.error);
            }
          },
          () => {
            NotificationManager.error(Resource.shared.Modules.AddPdiDialog.error);
          }
        )
        .finally(() => {
          this.setState({ addPDICheckLoading: false });
        });
    } else {
      this.clearDataAfterSave();
    }
  };

  deletePhoto = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    const { checkPreview } = this.state;
    if (!checkPreview) {
      this.props.context.setState({
        PDICheckPhoto: undefined,
      });
    }
  };

  zoom = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { scaleVehicle } = this.state;
    if (scaleVehicle === 1) {
      const width = 800;
      const height = 556;
      const scale = 2;
      const x = width - event.clientX * scale;
      const y = height - (event.clientY - 80) * scale;
      this.setState({
        clientX: x,
        clientY: y,
        scaleVehicle: 2,
      });
    }
  };

  transformDate(inputDate: string, whithTime: boolean) {
    if (inputDate) {
      const options = whithTime
        ? {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
            timeZone: "Asia/Tbilisi",
          }
        : {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          };
      const date: Date = new Date(inputDate);
      let newDate = new Intl.DateTimeFormat("uk-UK", options).format(date);
      newDate = newDate.substr(0, 2) + "/" + newDate.substr(3);
      newDate = newDate.substr(0, 5) + "/" + newDate.substr(6);
      return newDate;
    } else {
      return "";
    }
  }

  PDIPrint = (success: boolean) => {
    if (success) {
      const { selectedPDI } = this.props.context.state;
      const { PDIPrint } = this.props.context;

      if (selectedPDI) {
        PDIPrint(selectedPDI.Id);
      }
    }
    this.setState({ hidePrintDialog: true });
  };

  zoomOut = () => {
    const { scaleVehicle } = this.state;
    if (scaleVehicle === 2) {
      this.setState({
        clientX: 0,
        clientY: 0,
        scaleVehicle: 1,
      });
    }
  };

  render(): JSX.Element {
    const {
      hideAddPdiDialog,
      showNumbers,
      scaleVehicle,
      checkPreview,
      clientX,
      clientY,
      comment,
      hideConfirmationDialog,
      hidePrintDialog,
    } = this.state;
    const { PDICheckPhoto, DemageType, PDIphotoLoading, selectedPDI } = this.props.context.state;
    const { uploadPDIPhoto } = this.props.context;
    (window as any).state = this.state.checkPreview;
    return (
      <>
        <div className="PDICheckPage">
          <main className="PDIChecks__content">
            <section className="top-container">
              <div className="toggle-numbers">
                <Toggle
                  checked={showNumbers}
                  onChange={() => this.setState({ showNumbers: !this.state.showNumbers })}
                  onText={Resource.modules.PDICheck.hideCircles}
                  offText={Resource.modules.PDICheck.showCircles}
                />
              </div>
              {this.state.status == "Active" && (
                <div className="zoomIcons" onClick={() => this.PrintConfirmation()}>
                  <img src={require("../../assets/images/print.svg")} alt="print" /> {Resource.modules.PDICheck.print}
                </div>
              )}
            </section>
            <section className="PDIChecks__image">
              <Pivot linkFormat={PivotLinkFormat.tabs} linkSize={PivotLinkSize.large} onLinkClick={this.zoomOut}>
                <PivotItem headerText={Resource.modules.PDICheck.exterior}>
                  <div className="img">
                    {scaleVehicle === 1 ? <div className="overlay" onMouseDown={this.zoom}></div> : null}
                    <svg
                      id="Main"
                      viewBox="0 0 600 417"
                      style={{
                        position: "absolute",
                        transform: "translate(" + clientX + "px, " + clientY + "px) scale(" + scaleVehicle + ")",
                        left: 0 + "%",
                        top: 0 + "%",
                        transition: "all 0.4s ease 0s",
                      }}
                    >
                      <g id="Image">
                        <image
                          width="556"
                          height="600"
                          transform="translate(58 -62) scale(0.84)"
                          href={require("../../assets/images/EXTERIOR.svg")}
                          onMouseDown={this.zoomOut}
                        />
                      </g>
                      <g
                        id="Numbers_with_Circles"
                        className={!showNumbers ? "circleNumbers hideNumbers" : "circleNumbers"}
                      >
                        <g className="vehicleParts c73" onClick={(event) => this.selectPart(event, 73)}>
                          <circle className="st0" cx="300" cy="91" r="10" />
                          <path
                            className="st1"
                            d="M294 87V87h5.6v.778a18.3 18.3 0 00-1.6 2.236 17.956 17.956 0 00-1.2 2.917 18.971 18.971 0 
												00-.4 2.333h-1.2a6.372 6.372 0 01.4-2.333 12.994 12.994 0 011.1-2.722 10.4 10.4 0 011.3-2.009l-4-0Z"
                          />
                          <path
                            className="st1"
                            d="m300 93 1 0c.1.5.3.9.6 1.3.3.3.7.4 1.1.4.5 0 .9-.2 1.3-.5s.5-.8.5-1.3c0-.9-.8-1.7-1.7-1.7-.2 0-.5
												 0-.7 0l0-.9h.2c.4 0 .9-.1 1.2-.4.3-.3.5-.7.5-1.1 0-.8-.6-1.4-1.3-1.4 0 0 0 0-.1 0-.5 0-.8.1-1 .4-.3.4-.4.8-.5 1.1l-1-.1c0-.6.4-1.2.9-1.6.5-.4
												  1.1-.6 1.7-.6.5 0 .9.1 1.3.3s.7.5.9.8c.2.3.3.7.3 1.1 0 .4-.1.7-.3 1-.2.3-.5.6-1 .9.5.1.9.4 1.1.8.3.4.4.9.4 1.4 0 .7-.3 
												  1.4-.8 1.9-.6.5-1.3.8-2.1.8-.7-.1-1.4-.2-1.8-.8-.4-.5-.7-1.1-.7-1.8z"
                          />
                        </g>
                        <g className="vehicleParts c72" onClick={(event) => this.selectPart(event, 72)}>
                          <circle className="st0" cx="400" cy="421" r="10" />
                          <path
                            className="st1"
                            d="M394 436V417h5.6v.778a18.3 18.3 0 00-1.6 2.236 17.956 17.956 0 00-1.2 2.917 18.971 18.971 0 00-.4 2.333h-1.2a6.372 6.372 0 01.4-2.333 12.994 12.994 0 011.1-2.722 10.4 10.4 0 011.3-2.009l-4-0Z"
                          />
                          <path
                            className="st1"
                            d="M406 424v1h-5.7c0-.2 0-.5.1-.7.1-.4.4-.8.7-1.1s.8-.8 1.4-1.3c.7-.6 1.3-1.2 1.9-1.8.3-.4.5-.8.5-1.3 
												0-.4-.1-.8-.4-1.1-.3-.3-.8-.4-1.2-.4-.5 0-.9.2-1.2.5s-.5.8-.5 1.3l-1.082-.008c.1-.7.3-1.3.8-1.8.6-.4 1.3-.7 2-.6.7 0 1.5.2 
												2 .7.5.4.7 1.1.7 1.7 0 .3-.1.7-.2 1-.2.4-.4.7-.7 1s-.9.9-1.6 1.5-1 .9-1.5 1.5z"
                          />
                        </g>
                        <g className="vehicleParts c71" onClick={(event) => this.selectPart(event, 71)}>
                          <circle className="st0" cx="385" cy="225" r="10" />
                          <path
                            className="st1"
                            d="M379 221V221h5.6v.778a18.3 18.3 0 00-1.6 2.236 17.956 17.956 0 00-1.2 2.917 18.971 18.971 0 00-.4 2.333h-1.2a6.372 6.372 0 01.4-2.333 12.994 12.994 0 011.1-2.722 10.4 10.4 0 011.3-2.009l-4-0Z"
                          />
                          <path
                            className="st1"
                            d="M389,229.5h-1.1v-6.7a3.591,3.591,0,0,1-1,.7,3.7,3.7,0,0,1-1.1.5v-1a3.956,3.956,0,0,0,1.5-1,3.126,3.126,0,0,0,.9-1.1h.7Z"
                          />
                        </g>
                        <g className="vehicleParts c70" onClick={(event) => this.selectPart(event, 70)}>
                          <circle className="st0" cx="385" cy="261" r="10" />
                          <path
                            className="st1"
                            d="M379 257V257h5.6v.778a18.3 18.3 0 00-1.6 2.236 17.956 17.956 0 00-1.2 2.917 18.971 18.971 0 00-.4 
												2.333h-1.2a6.372 6.372 0 01.4-2.333 12.994 12.994 0 011.1-2.722 10.4 10.4 0 011.3-2.209l-4-0Z"
                          />
                          <path
                            className="st1"
                            d="M386 261c0-.8.1-1.7.3-2.5.1-.6.4-1.1.9-1.4.5-.3 1-.5 1.6-.5.4 0 .8.1 
												1.2.3.3.2.6.5.8.8.2.5.4.9.5 1.3.1.7.2 1.3.2 2 0 .8 0 1.6-.3 2.4-.2.5-.5 1.1-.9 1.4-.5.3-1 
												.5-1.6.5-.8 0-1.6-.2-2-.9-.7-1.2-.8-2.2-.7-3.4zm1.1 0c0 1 .1 1.9.5 2.8.4.7 1.2.9 
												1.9.5.2-.1.4-.3.5-.5.4-.9.5-1.8.5-2.8 0-1 0-1.9-.5-2.8-.3-.4-.7-.7-1.2-.7s-1 .2-1.2.6c-.5.9-.5 1.9-.5 2.9z"
                          />
                        </g>
                        <g className="vehicleParts c68" onClick={(event) => this.selectPart(event, 68)}>
                          <ellipse
                            transform="matrix(0.3162 -0.9487 0.9487 0.3162 -13.3495 394.0788)"
                            className="st0"
                            cx="266.7"
                            cy="206.3"
                            rx="10"
                            ry="10"
                          />
                          <path
                            className="st1"
                            d="M266,204.3l-1,0.1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.3-0.3-0.7-0.5-1.1-0.5c-0.3,0-0.6,0.1-0.9,0.3
			c-0.4,0.3-0.6,0.7-0.8,1.1c-0.2,0.6-0.3,1.3-0.3,2c0.2-0.3,0.6-0.7,0.9-0.9c0.4-0.2,0.8-0.3,1.2-0.3c0.7,0,1.3,0.3,1.8,0.8
			s0.7,1.3,0.7,2c0,0.5-0.1,1-0.3,1.5c-0.2,0.5-0.6,0.8-1,1.1s-0.9,0.4-1.4,0.4c-0.8,0-1.7-0.3-2.2-1c-0.6-0.9-0.9-2.1-0.8-3.2
			c-0.1-1.3,0.2-2.5,0.9-3.6c0.5-0.7,1.3-1.1,2.2-1c0.6,0,1.2,0.2,1.7,0.6C265.7,203.2,266,203.7,266,204.3z M261.7,208
			c0,0.3,0.1,0.7,0.2,1s0.3,0.6,0.6,0.8c0.3,0.2,0.6,0.3,0.9,0.3c0.4,0,0.8-0.2,1.1-0.5c0.4-0.4,0.5-1,0.5-1.5s-0.2-1-0.5-1.4
			c-0.7-0.7-1.7-0.7-2.4,0l0,0C261.8,207.1,261.7,207.5,261.7,208z"
                          />
                          <path
                            className="st1"
                            d="M268.8,206.1c-0.4-0.1-0.7-0.4-1-0.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.6,0.2-1.2,0.7-1.6
			c0.5-0.4,1.1-0.6,1.8-0.6c0.7-0.1,1.3,0.2,1.8,0.7c0.4,0.4,0.7,1,0.7,1.6c0,0.4-0.1,0.7-0.3,1c-0.3,0.3-0.6,0.6-1,0.7
			c0.5,0.1,0.9,0.4,1.2,0.8c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.8,0-1.5-0.3-2.1-0.8
			s-0.9-1.2-0.8-1.9c0-0.5,0.1-1,0.4-1.4C267.8,206.5,268.3,206.2,268.8,206.1z M268.3,208.3c0,0.3,0.1,0.6,0.2,0.9
			c0.1,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2c0.9,0,1.7-0.8,1.7-1.7c0-0.5-0.2-0.9-0.5-1.3c-0.3-0.4-0.8-0.5-1.3-0.5
			s-0.9,0.2-1.2,0.5S268.3,207.9,268.3,208.3z M268.6,204.3c0,0.8,0.6,1.4,1.4,1.4c0.4,0,0.7-0.1,1-0.4c0.3-0.2,0.4-0.6,0.4-0.9
			c0-0.8-0.6-1.4-1.4-1.4c-0.4,0-0.7,0.1-1,0.4C268.7,203.6,268.6,204,268.6,204.3z"
                          />
                        </g>
                        <g className="vehicleParts c50" onClick={(event) => this.selectPart(event, 50)}>
                          <circle className="st0" cx="406.4" cy="206.8" r="10" />
                          <path
                            className="st1"
                            d="M400.2,209l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.2c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,1-0.2,1.3-0.6
			c0.7-0.9,0.7-2,0-2.9c-0.4-0.3-0.8-0.5-1.3-0.5c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.6,0.6l-1-0.1l0.8-4.4h4.3v1h-3.4
			l-0.5,2.3c0.5-0.3,1-0.5,1.6-0.5c0.7,0,1.4,0.3,1.9,0.8s0.8,1.3,0.8,2s-0.2,1.4-0.7,2c-0.6,0.7-1.4,1.1-2.3,1
			c-0.7,0-1.4-0.2-1.9-0.7C400.6,210.3,400.3,209.7,400.2,209z"
                          />
                          <path
                            className="st1"
                            d="M406.9,207c0-0.8,0.1-1.7,0.3-2.5c0.1-0.6,0.4-1.1,0.9-1.4c0.5-0.3,1-0.5,1.6-0.5c0.4,0,0.8,0.1,1.2,0.3
			c0.3,0.2,0.6,0.5,0.9,0.8c0.3,0.4,0.5,0.8,0.5,1.3c0.1,0.7,0.2,1.3,0.2,2c0,0.8-0.1,1.6-0.3,2.4c-0.2,0.5-0.5,1-0.9,1.4
			c-0.5,0.3-1,0.5-1.6,0.5c-0.8,0-1.5-0.3-2-0.9C407.1,209.4,406.8,208.2,406.9,207z M408,207c-0.1,1,0.1,1.9,0.5,2.8
			c0.4,0.7,1.2,0.9,1.9,0.5c0.2-0.1,0.4-0.3,0.5-0.5c0.4-0.9,0.6-1.8,0.5-2.8c0.1-1-0.1-1.9-0.5-2.8c-0.3-0.4-0.7-0.7-1.2-0.7
			s-0.9,0.2-1.2,0.6C408.1,205,407.9,206,408,207z"
                          />
                        </g>
                        <g className="vehicleParts c49" onClick={(event) => this.selectPart(event, 49)}>
                          <circle className="st0" cx="297.7" cy="207.8" r="10" />
                          <path
                            className="st1"
                            d="M294.9,212.3v-2.1h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1H296v2.1H294.9z M294.9,209.2v-3.9l-2.7,3.9H294.9z"
                          />
                          <path
                            className="st1"
                            d="M298.4,210.3l1-0.1c0.1,0.5,0.2,0.8,0.5,1s0.6,0.3,0.9,0.3s0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.6-0.6
			s0.3-0.6,0.4-1s0.2-0.9,0.2-1.3v-0.2c-0.2,0.3-0.5,0.6-0.9,0.8s-0.8,0.3-1.2,0.3c-0.7,0-1.3-0.3-1.8-0.8s-0.7-1.3-0.7-2
			c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.2-0.8,1.9-0.8c0.5,0,1.1,0.2,1.5,0.5c0.5,0.3,0.8,0.8,1.1,1.3c0.3,0.8,0.4,1.6,0.4,2.4
			c0.1,0.9-0.1,1.8-0.4,2.6c-0.2,0.6-0.6,1.1-1.1,1.5s-1.1,0.6-1.7,0.5c-0.6,0-1.2-0.2-1.6-0.6C298.7,211.5,298.4,210.9,298.4,210.3
			z M302.7,206.5c0-0.5-0.2-1-0.5-1.4c-0.3-0.3-0.7-0.5-1.2-0.5s-0.9,0.2-1.2,0.6c-0.3,0.4-0.5,1-0.5,1.5s0.2,0.9,0.5,1.3
			c0.7,0.7,1.7,0.7,2.4,0l0,0C302.5,207.6,302.7,207,302.7,206.5z"
                          />
                        </g>
                        <g className="vehicleParts c48" onClick={(event) => this.selectPart(event, 48)}>
                          <circle className="st0" cx="335.8" cy="203.3" r="10" />
                          <path
                            className="st1"
                            d="M333,207.8v-2.1h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1H333z M333,204.8v-3.9l-2.7,3.9H333z"
                          />
                          <path
                            className="st1"
                            d="M337.9,203.2c-0.4-0.1-0.7-0.4-1-0.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.6,0.2-1.2,0.7-1.6
			c0.5-0.4,1.1-0.6,1.8-0.6c0.7-0.1,1.3,0.2,1.8,0.7c0.4,0.4,0.7,1,0.7,1.6c0,0.4-0.1,0.7-0.3,1c-0.3,0.3-0.6,0.6-1,0.7
			c0.5,0.1,0.9,0.4,1.2,0.8c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.8,0-1.5-0.3-2.1-0.8
			s-0.9-1.2-0.8-1.9c0-0.5,0.1-1,0.4-1.4C336.9,203.6,337.4,203.3,337.9,203.2z M337.4,205.3c0,0.3,0.1,0.6,0.2,0.9
			c0.1,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2c0.9,0,1.7-0.8,1.7-1.7c0-0.5-0.2-0.9-0.5-1.3c-0.3-0.4-0.8-0.5-1.3-0.5
			s-0.9,0.2-1.2,0.5S337.4,204.9,337.4,205.3z M337.7,201.4c0,0.8,0.6,1.4,1.4,1.4c0.4,0,0.7-0.1,1-0.4c0.3-0.2,0.4-0.6,0.4-0.9
			c0-0.8-0.6-1.4-1.4-1.4c-0.4,0-0.7,0.1-1,0.4C337.8,200.7,337.7,201.1,337.7,201.4L337.7,201.4z"
                          />
                        </g>
                        <g className="vehicleParts c47" onClick={(event) => this.selectPart(event, 47)}>
                          <circle className="st0" cx="243.1" cy="203.3" r="10" />
                          <path
                            className="st1"
                            d="M240.3,207.8v-2.1h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1H240.3z M240.3,204.7v-3.9l-2.7,3.9H240.3z"
                          />
                          <path
                            className="st1"
                            d="M243.7,200.3v-1h5.6v0.8c-0.6,0.7-1.1,1.5-1.6,2.3c-0.5,0.9-0.9,1.9-1.2,3c-0.2,0.8-0.3,1.6-0.4,2.4H245
			c0-0.8,0.1-1.6,0.4-2.4c0.3-1,0.6-1.9,1.1-2.8c0.4-0.8,0.9-1.5,1.5-2.2L243.7,200.3L243.7,200.3z"
                          />
                        </g>
                        <g className="vehicleParts c46" onClick={(event) => this.selectPart(event, 46)}>
                          <circle className="st0" cx="213.3" cy="196.8" r="10" />
                          <path
                            className="st1"
                            d="M210.5,201.3v-2.1h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1H210.5z M210.5,198.2v-3.9l-2.7,3.9H210.5z"
                          />
                          <path
                            className="st1"
                            d="M219.3,194.8l-1,0.1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.3-0.3-0.7-0.5-1.1-0.5c-0.3,0-0.6,0.1-0.9,0.3
			c-0.4,0.3-0.6,0.7-0.8,1.1c-0.2,0.6-0.3,1.3-0.3,2c0.2-0.3,0.6-0.7,0.9-0.9c0.4-0.2,0.8-0.3,1.2-0.3c0.7,0,1.3,0.3,1.8,0.8
			s0.7,1.3,0.7,2c0,0.5-0.1,1-0.3,1.5s-0.6,0.8-1,1.1s-0.9,0.4-1.4,0.4c-0.8,0-1.7-0.3-2.2-1c-0.6-0.9-0.9-2.1-0.8-3.2
			c-0.1-1.3,0.2-2.5,0.9-3.6c0.5-0.7,1.3-1.1,2.2-1c0.6,0,1.2,0.2,1.7,0.6C219,193.7,219.3,194.2,219.3,194.8z M215,198.5
			c0,0.3,0.1,0.7,0.2,1c0.1,0.3,0.3,0.6,0.6,0.8s0.6,0.3,0.9,0.3c0.4,0,0.8-0.2,1.1-0.5c0.4-0.4,0.5-1,0.5-1.5s-0.2-1-0.5-1.4
			c-0.7-0.7-1.7-0.7-2.4,0l0,0C215.1,197.6,214.9,198,215,198.5z"
                          />
                        </g>
                        <g className="vehicleParts c45" onClick={(event) => this.selectPart(event, 45)}>
                          <circle className="st0" cx="412.3" cy="392.3" r="10" />
                          <path
                            className="st1"
                            d="M409.5,396.8v-2.1h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1H409.5z M409.5,393.7v-3.9l-2.7,3.9H409.5z"
                          />
                          <path
                            className="st1"
                            d="M412.8,394.5l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.2c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,1-0.2,1.3-0.6
			c0.7-0.9,0.7-2,0-2.9c-0.4-0.3-0.8-0.5-1.3-0.5c-0.3,0-0.6,0.1-0.9,0.2s-0.5,0.3-0.6,0.6l-1-0.1l0.8-4.4h4.3v1h-3.4l-0.5,2.3
			c0.5-0.3,1-0.5,1.6-0.5c0.7,0,1.4,0.3,1.9,0.8s0.8,1.3,0.8,2s-0.2,1.4-0.7,2c-0.6,0.7-1.4,1.1-2.3,1c-0.7,0-1.4-0.2-1.9-0.7
			C413.2,395.8,412.8,395.2,412.8,394.5z"
                          />
                        </g>
                        <g className="vehicleParts c44" onClick={(event) => this.selectPart(event, 44)}>
                          <circle className="st0" cx="412.8" cy="367.5" r="10" />
                          <path
                            className="st1"
                            d="M410,372v-2.1h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1H410z M410,369v-3.9l-2.7,3.9H410z"
                          />
                          <path
                            className="st1"
                            d="M416.7,372v-2.1H413v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1H416.7z M416.7,369v-3.9L414,369H416.7z"
                          />
                        </g>
                        <g className="vehicleParts c43" onClick={(event) => this.selectPart(event, 43)}>
                          <circle className="st0" cx="162.2" cy="392.3" r="10" />
                          <path
                            className="st1"
                            d="M159.4,396.8v-2.1h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1H159.4z M159.4,393.7v-3.9l-2.7,3.9H159.4z"
                          />
                          <path
                            className="st1"
                            d="M162.7,394.5l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5
			c0.4-0.3,0.5-0.8,0.5-1.3c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4s0.5-0.7,0.5-1.1
			c0-0.8-0.6-1.4-1.3-1.4c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6
			s1.1-0.6,1.7-0.6c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.3,0.7,0.3,1.1s-0.1,0.7-0.3,1s-0.5,0.6-0.9,0.7
			c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.7,0-1.4-0.2-1.9-0.7
			C163.1,395.7,162.8,395.1,162.7,394.5z"
                          />
                        </g>
                        <g className="vehicleParts c42" onClick={(event) => this.selectPart(event, 42)}>
                          <circle className="st0" cx="162.6" cy="367.5" r="10" />
                          <path
                            className="st1"
                            d="M159.8,372v-2.1h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1H159.8z M159.8,369v-3.9l-2.7,3.9H159.8z"
                          />
                          <path
                            className="st1"
                            d="M168.7,371v1H163c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.8,1.4-1.3
			c0.7-0.6,1.3-1.2,1.9-1.8c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.8-0.4-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5
			s-0.5,0.8-0.5,1.3l-1.1-0.1c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7
			c0,0.3-0.1,0.7-0.2,1c-0.2,0.4-0.4,0.7-0.7,1s-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1s-0.3,0.4-0.4,0.6h4.1L168.7,371z"
                          />
                        </g>
                        <g className="vehicleParts c41" onClick={(event) => this.selectPart(event, 41)}>
                          <circle className="st0" cx="347.1" cy="351.3" r="10" />
                          <path
                            className="st1"
                            d="M344.3,355.8v-2.1h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1H344.3z M344.3,352.7v-3.9l-2.7,3.9H344.3z"
                          />
                          <path
                            className="st1"
                            d="M351.6,355.8h-1.1v-6.7c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L351.6,355.8L351.6,355.8z"
                          />
                        </g>
                        <g className="vehicleParts c40" onClick={(event) => this.selectPart(event, 40)}>
                          <circle className="st0" cx="250" cy="350" r="10" />
                          <path
                            className="st1"
                            d="M247.2,354.5v-2h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1L247.2,354.5L247.2,354.5z M247.2,351.5v-3.9
			l-2.7,3.9H247.2z"
                          />
                          <path
                            className="st1"
                            d="M250.5,350.3c0-0.8,0.1-1.7,0.3-2.5c0.1-0.6,0.4-1.1,0.9-1.4s1-0.5,1.6-0.5c0.4,0,0.8,0.1,1.2,0.3
			c0.3,0.2,0.6,0.5,0.9,0.8c0.3,0.4,0.5,0.8,0.5,1.3c0.1,0.7,0.2,1.3,0.2,2c0,0.8-0.1,1.6-0.3,2.4c-0.2,0.5-0.5,1-0.9,1.4
			c-0.5,0.3-1,0.5-1.6,0.5c-0.8,0-1.5-0.3-2-0.9C250.7,352.7,250.4,351.5,250.5,350.3z M251.6,350.3c-0.1,1,0.1,1.9,0.5,2.8
			c0.4,0.7,1.2,0.9,1.9,0.5c0.2-0.1,0.4-0.3,0.5-0.5c0.4-0.9,0.6-1.8,0.5-2.8c0.1-1-0.1-1.9-0.5-2.8c-0.3-0.4-0.7-0.7-1.2-0.7
			s-0.9,0.2-1.2,0.6C251.7,348.3,251.5,349.3,251.6,350.3z"
                          />
                        </g>
                        <g className="vehicleParts c39" onClick={(event) => this.selectPart(event, 39)}>
                          <circle className="st0" cx="454.8" cy="324" r="10" />
                          <path
                            className="st1"
                            d="M448.7,326.3l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5
			s0.6-0.8,0.5-1.3c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.7,0.5-1.1
			c0-0.8-0.6-1.4-1.3-1.4c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6
			s1.1-0.6,1.7-0.6c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1s-0.1,0.7-0.3,1
			c-0.2,0.3-0.5,0.6-0.9,0.7c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8
			c-0.7,0-1.4-0.2-1.9-0.7C449.1,327.5,448.8,326.9,448.7,326.3z"
                          />
                          <path
                            className="st1"
                            d="M455.5,326.5l1-0.1c0.1,0.4,0.2,0.7,0.5,1c0.3,0.2,0.6,0.3,0.9,0.3s0.6-0.1,0.9-0.2s0.4-0.3,0.6-0.6
			c0.2-0.3,0.3-0.6,0.4-1s0.2-0.9,0.2-1.3v-0.2c-0.2,0.3-0.5,0.6-0.9,0.8c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.3-0.3-1.8-0.8
			s-0.7-1.3-0.7-2c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.2-0.8,1.9-0.8c0.5,0,1.1,0.2,1.5,0.5c0.5,0.3,0.8,0.8,1.1,1.3
			c0.3,0.8,0.4,1.6,0.4,2.4c0.1,0.9-0.1,1.8-0.4,2.6c-0.2,0.6-0.6,1.1-1.1,1.5s-1.1,0.6-1.7,0.5c-0.6,0-1.2-0.2-1.6-0.6
			C455.9,327.6,455.6,327.1,455.5,326.5z M459.8,322.7c0-0.5-0.2-1-0.5-1.4c-0.3-0.3-0.7-0.5-1.2-0.5s-0.9,0.2-1.2,0.6
			c-0.3,0.4-0.5,1-0.5,1.5s0.2,0.9,0.5,1.3c0.7,0.7,1.7,0.7,2.4,0l0,0C459.6,323.8,459.8,323.2,459.8,322.7z"
                          />
                        </g>
                        <g className="vehicleParts c38" onClick={(event) => this.selectPart(event, 38)}>
                          <circle className="st0" cx="443" cy="367.5" r="10" />
                          <path
                            className="st1"
                            d="M436.9,369.8l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5
			s0.6-0.8,0.5-1.3c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.7,0.5-1.1
			c0-0.8-0.6-1.4-1.3-1.4c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6
			s1.1-0.6,1.7-0.6c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1s-0.1,0.7-0.3,1
			c-0.2,0.3-0.5,0.6-0.9,0.7c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8
			c-0.7,0-1.4-0.2-1.9-0.7C437.3,371,437,370.4,436.9,369.8z"
                          />
                          <path
                            className="st1"
                            d="M445.2,367.4c-0.4-0.1-0.7-0.4-1-0.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.6,0.2-1.2,0.7-1.6s1.1-0.6,1.8-0.6
			c0.7-0.1,1.3,0.2,1.8,0.7c0.4,0.4,0.7,1,0.7,1.6c0,0.4-0.1,0.7-0.3,1c-0.3,0.3-0.6,0.6-1,0.7c0.5,0.1,0.9,0.4,1.2,0.8
			c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.8,0-1.5-0.3-2.1-0.8s-0.9-1.2-0.8-1.9
			c0-0.5,0.1-1,0.4-1.4C444.2,367.8,444.7,367.5,445.2,367.4z M444.6,369.5c0,0.3,0.1,0.6,0.2,0.9s0.4,0.5,0.6,0.7
			c0.3,0.2,0.6,0.2,0.9,0.2c0.9,0,1.7-0.8,1.7-1.7c0-0.5-0.2-0.9-0.5-1.3s-0.8-0.6-1.3-0.5c-0.5,0-0.9,0.2-1.2,0.5
			S444.6,369.1,444.6,369.5z M445,365.6c0,0.8,0.6,1.4,1.4,1.4c0.4,0,0.7-0.1,1-0.4c0.3-0.2,0.4-0.6,0.4-0.9c0-0.8-0.6-1.4-1.4-1.4
			c-0.4,0-0.7,0.1-1,0.4C445.1,364.9,445,365.3,445,365.6z"
                          />
                        </g>
                        <g className="vehicleParts c37" onClick={(event) => this.selectPart(event, 37)}>
                          <circle className="st0" cx="193.5" cy="367.5" r="10" />
                          <path
                            className="st1"
                            d="M187.4,369.8l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5
			c0.4-0.3,0.5-0.8,0.5-1.3c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4s0.5-0.7,0.5-1.1
			c0-0.8-0.6-1.4-1.3-1.4c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6
			s1.1-0.6,1.7-0.6c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.3,0.7,0.3,1.1s-0.1,0.7-0.3,1s-0.5,0.6-0.9,0.7
			c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.7,0-1.4-0.2-1.9-0.7
			C187.8,371,187.5,370.4,187.4,369.8z"
                          />
                          <path
                            className="st1"
                            d="M194.1,364.6v-1h5.6v0.8c-0.6,0.7-1.1,1.5-1.6,2.3c-0.5,0.9-0.9,1.9-1.2,3c-0.2,0.8-0.3,1.6-0.4,2.4h-1.1
			c0-0.8,0.1-1.6,0.4-2.4c0.3-1,0.6-1.9,1.1-2.8c0.4-0.8,0.9-1.5,1.5-2.2L194.1,364.6L194.1,364.6z"
                          />
                        </g>
                        <g className="vehicleParts c36" onClick={(event) => this.selectPart(event, 36)}>
                          <circle className="st0" cx="347.6" cy="289" r="10" />
                          <path
                            className="st1"
                            d="M341.5,291.3l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5
			s0.5-0.8,0.5-1.3c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.7,0.5-1.1
			c0-0.8-0.6-1.4-1.3-1.4c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6
			s1.1-0.6,1.7-0.6c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1s-0.1,0.7-0.3,1
			c-0.2,0.3-0.5,0.6-0.9,0.7c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8
			c-0.7,0-1.4-0.2-1.9-0.7C341.9,292.5,341.6,291.9,341.5,291.3z"
                          />
                          <path
                            className="st1"
                            d="M353.6,287l-1,0.1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.3-0.3-0.7-0.5-1.1-0.5c-0.3,0-0.6,0.1-0.9,0.3
			c-0.4,0.3-0.6,0.7-0.8,1.1c-0.2,0.6-0.3,1.3-0.3,2c0.2-0.3,0.6-0.7,0.9-0.9c0.4-0.2,0.8-0.3,1.2-0.3c0.7,0,1.3,0.3,1.8,0.8
			s0.7,1.3,0.7,2c0,0.5-0.1,1-0.3,1.5c-0.2,0.5-0.6,0.8-1,1.1c-0.4,0.3-0.9,0.4-1.4,0.4c-0.8,0-1.7-0.3-2.2-1
			c-0.6-0.9-0.9-2.1-0.8-3.2c-0.1-1.3,0.2-2.5,0.9-3.6c0.5-0.7,1.3-1.1,2.2-1c0.6,0,1.2,0.2,1.7,0.6
			C353.3,285.9,353.5,286.4,353.6,287z M349.3,290.7c0,0.3,0.1,0.7,0.2,1s0.3,0.6,0.6,0.8c0.3,0.2,0.6,0.3,0.9,0.3
			c0.4,0,0.8-0.2,1.1-0.5c0.4-0.4,0.5-1,0.5-1.5s-0.2-1-0.5-1.4c-0.7-0.7-1.7-0.7-2.4,0l0,0C349.4,289.8,349.3,290.2,349.3,290.7z"
                          />
                        </g>
                        <g className="vehicleParts c35" onClick={(event) => this.selectPart(event, 35)}>
                          <circle className="st0" cx="348.1" cy="324" r="10" />
                          <path
                            className="st1"
                            d="M341.9,326.3l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5
			s0.5-0.8,0.5-1.3c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.7,0.5-1.1
			c0-0.8-0.6-1.4-1.3-1.4c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6
			s1.1-0.6,1.7-0.6c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1s-0.1,0.7-0.3,1
			c-0.2,0.3-0.5,0.6-0.9,0.7c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8
			c-0.7,0-1.4-0.2-1.9-0.7C342.3,327.5,342,326.9,341.9,326.3z"
                          />
                          <path
                            className="st1"
                            d="M348.6,326.3l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.2c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,1-0.2,1.3-0.6
			c0.7-0.9,0.7-2,0-2.9c-0.4-0.3-0.8-0.5-1.3-0.5c-0.3,0-0.6,0.1-0.9,0.2s-0.5,0.3-0.6,0.6l-1-0.1l0.8-4.4h4.3v1h-3.4l-0.5,2.3
			c0.5-0.3,1-0.5,1.6-0.5c0.7,0,1.4,0.3,1.9,0.8s0.8,1.3,0.8,2s-0.2,1.4-0.7,2c-0.6,0.7-1.4,1.1-2.3,1c-0.7,0-1.4-0.2-1.9-0.7
			C349,327.6,348.7,327,348.6,326.3z"
                          />
                        </g>
                        <g className="vehicleParts c34" onClick={(event) => this.selectPart(event, 34)}>
                          <circle className="st0" cx="279.5" cy="287.8" r="10" />
                          <path
                            className="st1"
                            d="M273.4,290l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5s0.5-0.8,0.5-1.3
			c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.7,0.5-1.1c0-0.8-0.6-1.4-1.3-1.4
			c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6s1.1-0.6,1.7-0.6
			c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1s-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.6-0.9,0.7
			c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.7,0-1.4-0.2-1.9-0.7
			C273.8,291.2,273.5,290.6,273.4,290z"
                          />
                          <path
                            className="st1"
                            d="M283.4,292.3v-2.1h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1H283.4z M283.4,289.2v-3.9l-2.7,3.9H283.4z"
                          />
                        </g>
                        <g className="vehicleParts c33" onClick={(event) => this.selectPart(event, 33)}>
                          <circle className="st0" cx="250.5" cy="324" r="10" />
                          <path
                            className="st1"
                            d="M244.4,326.3l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5
			c0.4-0.3,0.5-0.8,0.5-1.3c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4s0.5-0.7,0.5-1.1
			c0-0.8-0.6-1.4-1.3-1.4c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6
			s1.1-0.6,1.7-0.6c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.3,0.7,0.3,1.1s-0.1,0.7-0.3,1s-0.5,0.6-0.9,0.7
			c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.7,0-1.4-0.2-1.9-0.7
			C244.8,327.5,244.5,326.9,244.4,326.3z"
                          />
                          <path
                            className="st1"
                            d="M251,326.3l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5
			c0.4-0.3,0.5-0.8,0.5-1.3c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4s0.5-0.7,0.5-1.1
			c0-0.8-0.6-1.4-1.3-1.4c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6
			s1.1-0.6,1.7-0.6c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1s-0.1,0.7-0.3,1s-0.5,0.6-0.9,0.7
			c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.7,0-1.4-0.2-1.9-0.7
			C251.4,327.5,251.1,326.9,251,326.3z"
                          />
                        </g>
                        <g className="vehicleParts c32" onClick={(event) => this.selectPart(event, 32)}>
                          <circle className="st0" cx="220.8" cy="301.8" r="10" />
                          <path
                            className="st1"
                            d="M214.6,304l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5
			c0.4-0.3,0.5-0.8,0.5-1.3c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4s0.5-0.7,0.5-1.1
			c0-0.8-0.6-1.4-1.3-1.4c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6
			s1.1-0.6,1.7-0.6c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.3,0.7,0.3,1.1s-0.1,0.7-0.3,1s-0.5,0.6-0.9,0.7
			c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.7,0-1.4-0.2-1.9-0.7
			C215,305.2,214.7,304.6,214.6,304z"
                          />
                          <path
                            className="st1"
                            d="M226.8,305.3v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.8,1.4-1.3
			c0.7-0.6,1.3-1.2,1.9-1.8c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.8-0.4-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5
			s-0.5,0.8-0.5,1.3l-1.1-0.1c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7
			c0,0.3-0.1,0.7-0.2,1c-0.2,0.4-0.4,0.7-0.7,1s-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1s-0.3,0.4-0.4,0.6h4.1L226.8,305.3z"
                          />
                        </g>
                        <g className="vehicleParts c31" onClick={(event) => this.selectPart(event, 31)}>
                          <circle className="st0" cx="150.4" cy="317.5" r="10" />
                          <path
                            className="st1"
                            d="M144.2,319.8l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5
			c0.4-0.3,0.5-0.8,0.5-1.3c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4s0.5-0.7,0.5-1.1
			c0-0.8-0.6-1.4-1.3-1.4c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6
			s1.1-0.6,1.7-0.6c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.3,0.7,0.3,1.1s-0.1,0.7-0.3,1s-0.5,0.6-0.9,0.7
			c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.7,0-1.4-0.2-1.9-0.7
			C144.6,321,144.3,320.4,144.2,319.8z"
                          />
                          <path
                            className="st1"
                            d="M154.9,322h-1.1v-6.7c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L154.9,322L154.9,322z"
                          />
                        </g>
                        <g className="vehicleParts c30" onClick={(event) => this.selectPart(event, 30)}>
                          <circle className="st0" cx="413.8" cy="20.3" r="10" />
                          <path
                            className="st1"
                            d="M407.7,22.5l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5s0.6-0.8,0.5-1.3
			c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.7,0.5-1.1c0-0.8-0.6-1.4-1.3-1.4
			c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6s1.1-0.6,1.7-0.6
			c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1s-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.6-0.9,0.7
			c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.7,0-1.4-0.2-1.9-0.7
			C408.1,23.6,407.7,23.3,407.7,22.5z"
                          />
                          <path
                            className="st1"
                            d="M414.3,20.6c0-0.8,0.1-1.7,0.3-2.5c0.1-0.6,0.4-1.1,0.9-1.4s1-0.5,1.6-0.5c0.4,0,0.8,0.1,1.2,0.3
			c0.3,0.2,0.6,0.5,0.9,0.8c0.3,0.4,0.5,0.8,0.5,1.3c0.1,0.7,0.2,1.3,0.2,2c0,0.8-0.1,1.6-0.3,2.4c-0.2,0.5-0.5,1-0.9,1.4
			c-0.5,0.3-1,0.5-1.6,0.5c-0.8,0-1.5-0.3-2-0.9C414.5,23,414.2,21.8,414.3,20.6z M415.4,20.6c-0.1,1,0.1,1.9,0.5,2.8
			c0.4,0.7,1.2,0.9,1.9,0.5c0.2-0.1,0.4-0.3,0.5-0.5c0.4-0.9,0.6-1.8,0.5-2.8c0.1-1-0.1-1.9-0.5-2.8c-0.3-0.4-0.7-0.7-1.2-0.7
			s-0.9,0.2-1.2,0.6C415.5,15,415.3,19.6,415.4,20.6z"
                          />
                        </g>
                        <g className="vehicleParts c29" onClick={(event) => this.selectPart(event, 29)}>
                          <circle className="st0" cx="412.8" cy="48" r="10" />
                          <path
                            className="st1"
                            d="M412.2,51.5v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.8,1.4-1.3
			c0.7-0.6,1.3-1.2,1.9-1.8c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.1-0.8-0.4-1.1s-0.8-0.4-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5
			c-0.3,0.3-0.5,0.8-0.5,1.3l-1.1-0.1c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7
			c0,0.3-0.1,0.7-0.2,1c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1c-0.2,0.2-0.3,0.4-0.4,0.6h4.1L412.2,51.5z"
                          />
                          <path
                            className="st1"
                            d="M413.5,50.6l1-0.1c0.1,0.4,0.2,0.7,0.5,1c0.3,0.2,0.6,0.3,0.9,0.3s0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.6-0.6
			s0.3-0.6,0.4-1s0.2-0.9,0.2-1.3v-0.2c-0.2,0.3-0.5,0.6-0.9,0.8s-0.8,0.3-1.2,0.3c-0.7,0-1.3-0.3-1.8-0.8s-0.7-1.3-0.7-2
			c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.2-0.8,1.9-0.8c0.5,0,1.1,0.2,1.5,0.5c0.5,0.3,0.8,0.8,1.1,1.3c0.3,0.8,0.4,1.6,0.4,2.4
			c0.1,0.9-0.1,1.8-0.4,2.6c-0.2,0.6-0.6,1.1-1.1,1.5s-1.1,0.6-1.7,0.5c-0.6,0-1.2-0.2-1.6-0.6C413.9,51.7,413.6,51.2,413.5,50.6z
			 M417.8,46.8c0-0.5-0.2-1-0.5-1.4c-0.3-0.3-0.7-0.5-1.2-0.5s-0.9,0.2-1.2,0.6c-0.3,0.4-0.5,1-0.5,1.5s0.2,0.9,0.5,1.3
			c0.7,0.7,1.7,0.7,2.4,0l0,0C417.6,47.9,417.8,47.3,417.8,46.8L417.8,46.8z"
                          />
                        </g>
                        <g className="vehicleParts c28" onClick={(event) => this.selectPart(event, 28)}>
                          <circle className="st0" cx="162.6" cy="23.3" r="10" />
                          <path
                            className="st1"
                            d="M162,26.8v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.8,1.4-1.3
			c0.7-0.6,1.3-1.2,1.9-1.8c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.8-0.4-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5
			s-0.5,0.8-0.5,1.3l-1.1-0.1c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7
			c0,0.3-0.1,0.7-0.2,1c-0.2,0.4-0.4,0.7-0.7,1s-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1s-0.3,0.4-0.4,0.6h4.1L162,26.8z"
                          />
                          <path
                            className="st1"
                            d="M164.7,23.2c-0.4-0.1-0.7-0.4-1-0.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.6,0.2-1.2,0.7-1.6s1.1-0.6,1.8-0.6
			c0.7-0.1,1.3,0.2,1.8,0.7c0.4,0.4,0.7,1,0.7,1.6c0,0.4-0.1,0.7-0.3,1c-0.3,0.3-0.6,0.6-1,0.7c0.5,0.1,0.9,0.4,1.2,0.8
			s0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8s-1.5-0.3-2.1-0.8s-0.9-1.2-0.8-1.9c0-0.5,0.1-1,0.4-1.4
			C163.7,23.6,164.2,23.3,164.7,23.2z M164.2,25.3c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2
			c0.9,0,1.7-0.8,1.7-1.7c0-0.5-0.2-0.9-0.5-1.3s-0.8-0.6-1.3-0.5c-0.5,0-0.9,0.2-1.2,0.5C164.3,24.4,164.2,24.9,164.2,25.3
			L164.2,25.3z M164.5,21.4c0,0.8,0.6,1.4,1.4,1.4c0.4,0,0.7-0.1,1-0.4c0.3-0.2,0.4-0.6,0.4-0.9c0-0.8-0.6-1.4-1.4-1.4
			c-0.4,0-0.7,0.1-1,0.4C164.6,20.7,164.5,21.1,164.5,21.4L164.5,21.4z"
                          />
                        </g>
                        <g className="vehicleParts c27" onClick={(event) => this.selectPart(event, 27)}>
                          <circle className="st0" cx="162.6" cy="48" r="10" />
                          <path
                            className="st1"
                            d="M162,51.5v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1s0.8-0.8,1.4-1.3c0.7-0.6,1.3-1.2,1.9-1.8
			c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.8-0.4-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5s-0.5,0.8-0.5,1.3l-1.1-0.1
			c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7c0,0.3-0.1,0.7-0.2,1
			c-0.2,0.4-0.4,0.7-0.7,1s-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1c-0.2,0.2-0.3,0.4-0.4,0.6h4.1L162,51.5z"
                          />
                          <path
                            className="st1"
                            d="M163.2,45.1v-1h5.6v0.8c-0.6,0.7-1.1,1.5-1.6,2.3c-0.5,0.9-0.9,1.9-1.2,3c-0.2,0.8-0.3,1.6-0.4,2.4h-1.1
			c0-0.8,0.1-1.6,0.4-2.4c0.3-1,0.6-1.9,1.1-2.8c0.4-0.8,0.9-1.5,1.5-2.2L163.2,45.1L163.2,45.1z"
                          />
                        </g>
                        <g className="vehicleParts c26" onClick={(event) => this.selectPart(event, 26)}>
                          <circle className="st0" cx="348.6" cy="65.7" r="10" />
                          <path
                            className="st1"
                            d="M348,69.2v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.8,1.4-1.3
			c0.7-0.6,1.3-1.2,1.9-1.8c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.1-0.8-0.4-1.1s-0.8-0.4-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5
			c-0.3,0.3-0.5,0.8-0.5,1.3l-1.1-0.1c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7
			c0,0.3-0.1,0.7-0.2,1c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1s-0.3,0.4-0.4,0.6h4.1L348,69.2z"
                          />
                          <path
                            className="st1"
                            d="M354.6,63.7l-1,0.1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.3-0.3-0.7-0.5-1.1-0.5c-0.3,0-0.6,0.1-0.9,0.3
			c-0.4,0.3-0.6,0.7-0.8,1.1c-0.2,0.6-0.3,1.3-0.3,2c0.2-0.3,0.6-0.7,0.9-0.9c0.4-0.2,0.8-0.3,1.2-0.3c0.7,0,1.3,0.3,1.8,0.8
			s0.7,1.3,0.7,2c0,0.5-0.1,1-0.3,1.5c-0.2,0.5-0.6,0.8-1,1.1c-0.4,0.3-0.9,0.4-1.4,0.4c-0.8,0-1.7-0.3-2.2-1
			c-0.6-0.9-0.9-2.1-0.8-3.2c-0.1-1.3,0.2-2.5,0.9-3.6c0.5-0.7,1.3-1.1,2.2-1c0.6,0,1.2,0.2,1.7,0.6
			C354.3,62.6,354.6,63.1,354.6,63.7z M350.3,67.4c0,0.3,0.1,0.7,0.2,1s0.3,0.6,0.6,0.8c0.3,0.2,0.6,0.3,0.9,0.3
			c0.4,0,0.8-0.2,1.1-0.5c0.4-0.4,0.5-1,0.5-1.5s-0.2-1-0.5-1.4c-0.7-0.7-1.7-0.7-2.4,0l0,0C350.4,66.5,350.2,66.9,350.3,67.4
			L350.3,67.4z"
                          />
                        </g>
                        <g className="vehicleParts c25" onClick={(event) => this.selectPart(event, 25)}>
                          <ellipse
                            transform="matrix(0.3417 -0.9398 0.9398 0.3417 104.3604 277.8122)"
                            className="st0"
                            cx="250.5"
                            cy="64.4"
                            rx="10"
                            ry="10"
                          />
                          <path
                            className="st1"
                            d="M249.9,67.9v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.8,1.4-1.3
			c0.7-0.6,1.3-1.2,1.9-1.8c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.8-0.4-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5
			s-0.5,0.8-0.5,1.3l-1.1-0.1c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7
			c0,0.3-0.1,0.7-0.2,1c-0.2,0.4-0.4,0.7-0.7,1s-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1s-0.3,0.4-0.4,0.6h4.1L249.9,67.9z"
                          />
                          <path
                            className="st1"
                            d="M251,66.7l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.2c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,1-0.2,1.3-0.6c0.7-0.9,0.7-2,0-2.9
			c-0.4-0.3-0.8-0.5-1.3-0.5c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.6,0.6l-1-0.1l0.8-4.4h4.3v1H253l-0.5,2.3
			c0.5-0.3,1-0.5,1.6-0.5c0.7,0,1.4,0.3,1.9,0.8s0.8,1.3,0.8,2s-0.2,1.4-0.7,2c-0.6,0.7-1.4,1.1-2.3,1c-0.7,0-1.4-0.2-1.9-0.7
			C251.4,68,251.1,67.4,251,66.7z"
                          />
                        </g>
                        <g className="vehicleParts c24" onClick={(event) => this.selectPart(event, 24)}>
                          <circle className="st0" cx="450.7" cy="91.5" r="10" />
                          <path
                            className="st1"
                            d="M450.1,95v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.8,1.4-1.3
			c0.7-0.6,1.3-1.2,1.9-1.8c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.8-0.5-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5
			c-0.3,0.3-0.5,0.8-0.5,1.3l-1.1-0.1c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7
			c0,0.3-0.1,0.7-0.2,1c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1s-0.3,0.4-0.4,0.6h4.1L450.1,95z"
                          />
                          <path
                            className="st1"
                            d="M454.6,96.1V94h-3.7v-1l3.9-5.6h0.9V93h1.2v1h-1.2v2.1H454.6z M454.6,93v-3.9l-2.7,3.9H454.6z"
                          />
                        </g>
                        <g className="vehicleParts c23" onClick={(event) => this.selectPart(event, 23)}>
                          <circle className="st0" cx="443.2" cy="49.3" r="10" />
                          <path
                            className="st1"
                            d="M442.6,52.8v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.8,1.4-1.3
			c0.7-0.6,1.3-1.2,1.9-1.8c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.8-0.5-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5
			c-0.3,0.3-0.5,0.8-0.5,1.3l-1.1-0.1c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7
			c0,0.3-0.1,0.7-0.2,1c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1c-0.2,0.2-0.3,0.4-0.4,0.6h4.1L442.6,52.8z"
                          />
                          <path
                            className="st1"
                            d="M443.8,51.5l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5s0.6-0.8,0.5-1.3
			c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.7,0.5-1.1c0-0.8-0.6-1.4-1.3-1.4
			c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6c0.5-0.4,1.1-0.6,1.7-0.6
			c0.5,0,0.9,0.1,1.3,0.3s0.7,0.5,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.6-0.9,0.7
			c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.7,0-1.4-0.2-1.9-0.7
			C444.2,52.6,443.8,52.3,443.8,51.5z"
                          />
                        </g>
                        <g className="vehicleParts c22" onClick={(event) => this.selectPart(event, 22)}>
                          <circle className="st0" cx="195.3" cy="48" r="10" />
                          <path
                            className="st1"
                            d="M194.7,51.5v1H189c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1s0.8-0.8,1.4-1.3c0.7-0.6,1.3-1.2,1.9-1.8
			c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.8-0.4-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5s-0.5,0.8-0.5,1.3l-1.1-0.1
			c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7c0,0.3-0.1,0.7-0.2,1
			c-0.2,0.4-0.4,0.7-0.7,1s-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1c-0.2,0.2-0.3,0.4-0.4,0.6h4.1L194.7,51.5z"
                          />
                          <path
                            className="st1"
                            d="M201.4,51.5v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1s0.8-0.8,1.4-1.3c0.7-0.6,1.3-1.2,1.9-1.8
			c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.8-0.4-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5s-0.5,0.8-0.5,1.3l-1.1-0.1
			c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7c0,0.3-0.1,0.7-0.2,1
			c-0.2,0.4-0.4,0.7-0.7,1s-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1c-0.2,0.2-0.3,0.4-0.4,0.6h4.1L201.4,51.5z"
                          />
                        </g>
                        <g className="vehicleParts c21" onClick={(event) => this.selectPart(event, 21)}>
                          <circle className="st0" cx="349.1" cy="128" r="10" />
                          <path
                            className="st1"
                            d="M348.5,131.5v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.8,1.4-1.3
			c0.7-0.6,1.3-1.2,1.9-1.8c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.8-0.4-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5
			c-0.3,0.3-0.5,0.8-0.5,1.3l-1.1-0.1c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7
			c0,0.3-0.1,0.7-0.2,1c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1c-0.2,0.2-0.3,0.4-0.4,0.6h4.1L348.5,131.5z
			"
                          />
                          <path
                            className="st1"
                            d="M353.6,132.6h-1.1v-6.7c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L353.6,132.6L353.6,132.6z"
                          />
                        </g>
                        <g className="vehicleParts c20" onClick={(event) => this.selectPart(event, 20)}>
                          <circle className="st0" cx="349.1" cy="90.3" r="10" />
                          <path
                            className="st1"
                            d="M348.5,93.8v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.8,1.4-1.3
			c0.7-0.6,1.3-1.2,1.9-1.8c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.8-0.4-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5
			c-0.3,0.3-0.5,0.8-0.5,1.3l-1.1-0.1c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7
			c0,0.3-0.1,0.7-0.2,1c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1s-0.3,0.4-0.4,0.6h4.1L348.5,93.8z"
                          />
                          <path
                            className="st1"
                            d="M349.6,90.6c0-0.8,0.1-1.7,0.3-2.5c0.1-0.6,0.4-1.1,0.9-1.4s1-0.5,1.6-0.5c0.4,0,0.8,0.1,1.2,0.3
			c0.3,0.2,0.6,0.5,0.9,0.8c0.3,0.4,0.5,0.8,0.5,1.3c0.1,0.7,0.2,1.3,0.2,2c0,0.8-0.1,1.6-0.3,2.4c-0.2,0.5-0.5,1-0.9,1.4
			c-0.5,0.3-1,0.5-1.6,0.5c-0.8,0-1.5-0.3-2-0.9C349.8,93,349.5,91.8,349.6,90.6z M350.7,90.6c-0.1,1,0.1,1.9,0.5,2.8
			c0.4,0.7,1.2,0.9,1.9,0.5c0.2-0.1,0.4-0.3,0.5-0.5c0.4-0.9,0.6-1.8,0.5-2.8c0.1-1-0.1-1.9-0.5-2.8c-0.3-0.4-0.7-0.7-1.2-0.7
			s-0.9,0.2-1.2,0.6C350.8,88.6,350.6,89.6,350.7,90.6L350.7,90.6z"
                          />
                        </g>
                        <g className="vehicleParts c19" onClick={(event) => this.selectPart(event, 19)}>
                          <circle className="st0" cx="279.5" cy="128" r="10" />
                          <path
                            className="st1"
                            d="M277.3,132.6h-1.1v-6.7c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L277.3,132.6L277.3,132.6z"
                          />
                          <path
                            className="st1"
                            d="M280.2,130.6l1-0.1c0.1,0.4,0.2,0.7,0.5,1c0.3,0.2,0.6,0.3,0.9,0.3s0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.6-0.6
			s0.3-0.6,0.4-1s0.2-0.9,0.2-1.3v-0.2c-0.2,0.3-0.5,0.6-0.9,0.8s-0.8,0.3-1.2,0.3c-0.7,0-1.3-0.3-1.8-0.8s-0.7-1.3-0.7-2
			c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.2-0.8,1.9-0.8c0.5,0,1.1,0.2,1.5,0.5c0.5,0.3,0.8,0.8,1.1,1.3c0.3,0.8,0.4,1.6,0.4,2.4
			c0.1,0.9-0.1,1.8-0.4,2.6c-0.2,0.6-0.6,1.1-1.1,1.5s-1.1,0.6-1.7,0.5c-0.6,0-1.2-0.2-1.6-0.6C280.6,131.7,280.3,131.2,280.2,130.6
			z M284.5,126.8c0-0.5-0.2-1-0.5-1.4c-0.3-0.3-0.7-0.5-1.2-0.5s-0.9,0.2-1.2,0.6c-0.3,0.4-0.5,1-0.5,1.5s0.2,0.9,0.5,1.3
			c0.7,0.7,1.7,0.7,2.4,0l0,0C284.3,127.9,284.5,127.3,284.5,126.8L284.5,126.8z"
                          />
                        </g>
                        <g className="vehicleParts c18" onClick={(event) => this.selectPart(event, 18)}>
                          <ellipse
                            transform="matrix(0.3162 -0.9487 0.9487 0.3162 85.6208 299.3915)"
                            className="st0"
                            cx="250.5"
                            cy="90.3"
                            rx="10"
                            ry="10"
                          />
                          <path
                            className="st1"
                            d="M248.3,94.8h-1.1v-6.7c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L248.3,94.8L248.3,94.8z"
                          />
                          <path
                            className="st1"
                            d="M252.6,90.2c-0.4-0.1-0.7-0.4-1-0.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.6,0.2-1.2,0.7-1.6s1.1-0.6,1.8-0.6
			c0.7-0.1,1.3,0.2,1.8,0.7c0.4,0.4,0.7,1,0.7,1.6c0,0.4-0.1,0.7-0.3,1c-0.3,0.3-0.6,0.6-1,0.7c0.5,0.1,0.9,0.4,1.2,0.8
			c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8s-1.5-0.3-2.1-0.8s-0.9-1.2-0.8-1.9c0-0.5,0.1-1,0.4-1.4
			C251.6,90.6,252.1,90.3,252.6,90.2z M252.1,92.3c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2
			c0.9,0,1.7-0.8,1.7-1.7c0-0.5-0.2-0.9-0.5-1.3s-0.8-0.6-1.3-0.5c-0.5,0-0.9,0.2-1.2,0.5S252.1,91.9,252.1,92.3L252.1,92.3z
			 M252.4,88.4c0,0.8,0.6,1.4,1.4,1.4c0.4,0,0.7-0.1,1-0.4c0.3-0.2,0.4-0.6,0.4-0.9c0-0.8-0.6-1.4-1.4-1.4c-0.4,0-0.7,0.1-1,0.4
			C252.5,87.7,252.4,88.1,252.4,88.4L252.4,88.4z"
                          />
                        </g>
                        <g className="vehicleParts c17" onClick={(event) => this.selectPart(event, 17)}>
                          <circle className="st0" cx="220.8" cy="109.8" r="10" />
                          <path
                            className="st1 st17"
                            d="M215,114.3h-1.1v-6.7c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L215,114.3L215,114.3z"
                          />
                          <path
                            className="st1"
                            d="M221.3,106.8v-1h5.6v0.8c-0.6,0.7-1.1,1.5-1.6,2.3c-0.5,0.9-0.9,1.9-1.2,3c-0.2,0.8-0.3,1.6-0.4,2.4h-1.2
			c0-0.8,0.1-1.6,0.4-2.4c0.3-1,0.6-1.9,1.1-2.8c0.4-0.8,0.9-1.5,1.5-2.2L221.3,106.8L221.3,106.8z"
                          />
                        </g>
                        <g className="vehicleParts c16" onClick={(event) => this.selectPart(event, 16)}>
                          <circle className="st0" cx="150.8" cy="95.5" r="10" />
                          <path
                            className="st1"
                            d="M148.7,100.1h-1.1v-6.7c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L148.7,100.1L148.7,100.1z"
                          />
                          <path
                            className="st1"
                            d="M156.8,93.6l-1,0.1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.3-0.3-0.7-0.5-1.1-0.5c-0.3,0-0.6,0.1-0.9,0.3
			c-0.4,0.3-0.6,0.7-0.8,1.1c-0.2,0.6-0.3,1.3-0.3,2c0.2-0.3,0.6-0.7,0.9-0.9c0.4-0.2,0.8-0.3,1.2-0.3c0.7,0,1.3,0.3,1.8,0.8
			s0.7,1.3,0.7,2c0,0.5-0.1,1-0.3,1.5s-0.6,0.8-1,1.1c-0.4,0.3-0.9,0.4-1.4,0.4c-0.8,0-1.7-0.3-2.2-1c-0.6-0.9-0.9-2.1-0.8-3.2
			c-0.1-1.3,0.2-2.5,0.9-3.6c0.5-0.7,1.3-1.1,2.2-1c0.6,0,1.2,0.2,1.7,0.6C156.5,92.4,156.8,93,156.8,93.6z M152.5,97.3
			c0,0.3,0.1,0.7,0.2,1c0.1,0.3,0.3,0.6,0.6,0.8c0.3,0.2,0.6,0.3,0.9,0.3c0.4,0,0.8-0.2,1.1-0.5c0.4-0.4,0.5-1,0.5-1.5
			s-0.2-1-0.5-1.4c-0.7-0.7-1.7-0.7-2.4,0l0,0C152.6,96.4,152.4,96.8,152.5,97.3L152.5,97.3z"
                          />
                        </g>
                        <g className="vehicleParts c15" onClick={(event) => this.selectPart(event, 15)}>
                          <circle className="st0" cx="531.8" cy="212" r="10" />
                          <path
                            className="st1"
                            d="M529.6,216.5h-1.1v-6.7c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L529.6,216.5L529.6,216.5z"
                          />
                          <path
                            className="st1"
                            d="M532.3,214.3l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.2s0.7,0.4,1.1,0.4c0.5,0,1-0.2,1.3-0.6c0.7-0.9,0.7-2,0-2.9
			c-0.4-0.3-0.8-0.5-1.3-0.5c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.6,0.6l-1-0.1l0.8-4.4h4.3v1h-3.4l-0.5,2.3
			c0.5-0.3,1-0.5,1.6-0.5c0.7,0,1.4,0.3,1.9,0.8s0.8,1.3,0.8,2s-0.2,1.4-0.7,2c-0.6,0.7-1.4,1.1-2.3,1c-0.7,0-1.4-0.2-1.9-0.7
			C532.7,215.6,532.4,215,532.3,214.3z"
                          />
                        </g>
                        <g className="vehicleParts c14 " onClick={(event) => this.selectPart(event, 14)}>
                          <circle className="st0" cx="487.6" cy="210" r="10" />
                          <path
                            className="st1 c14numbers"
                            d="M485.4,216.3h-1.1v-6.8c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L485.4,216.3L485.4,216.3z"
                          />
                          <path
                            className="st1 c14numbers"
                            d="M491.5,216.3v-2.1h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1H491.5z M491.5,213.2v-3.9l-2.7,3.9H491.5z"
                          />
                        </g>
                        <g className="vehicleParts c13" onClick={(event) => this.selectPart(event, 13)}>
                          <circle className="st0" cx="567.2" cy="145.5" r="10" />
                          <path
                            className="st1"
                            d="M565,150h-1.1v-6.7c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L565,150L565,150z"
                          />
                          <path
                            className="st1"
                            d="M567.7,147.7l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5
			c0.4-0.3,0.6-0.8,0.5-1.3c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.7,0.5-1.1
			c0-0.8-0.6-1.4-1.3-1.4c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6
			c0.5-0.4,1.1-0.6,1.7-0.6c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.3,0.7,0.3,1.1c0,0.4-0.1,0.7-0.3,1s-0.5,0.6-0.9,0.7
			c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.7,0-1.4-0.2-1.9-0.7
			C568.1,148.9,567.8,148.3,567.7,147.7z"
                          />
                        </g>
                        <g className="vehicleParts c12" onClick={(event) => this.selectPart(event, 12)}>
                          <circle className="st0" cx="567.2" cy="269.5" r="10" />
                          <path
                            className="st1"
                            d="M565,274h-1.1v-6.7c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L565,274L565,274z"
                          />
                          <path
                            className="st1"
                            d="M573.3,273v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1c0.3-0.3,0.8-0.8,1.4-1.3
			c0.7-0.6,1.3-1.2,1.9-1.8c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.8-0.5-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5
			s-0.5,0.8-0.5,1.3l-1.1-0.1c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7
			c0,0.3-0.1,0.7-0.2,1c-0.2,0.4-0.4,0.7-0.7,1s-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1s-0.3,0.4-0.4,0.6h4.1L573.3,273z"
                          />
                        </g>
                        <g className="vehicleParts c11" onClick={(event) => this.selectPart(event, 11)}>
                          <circle className="st0" cx="534.4" cy="269.5" r="10" />
                          <path
                            className="st1"
                            d="M532.3,274h-1.1v-6.7c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L532.3,274L532.3,274z"
                          />
                          <path
                            className="st1"
                            d="M538,274h-1.1v-6.7c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L538,274L538,274z"
                          />
                        </g>
                        <g className="vehicleParts c10" onClick={(event) => this.selectPart(event, 10)}>
                          <circle className="st0" cx="530.1" cy="144.2" r="10" />
                          <path
                            className="st1"
                            d="M528,148.7h-1.1V142c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L528,148.7L528,148.7z"
                          />
                          <path
                            className="st1"
                            d="M530.7,144.5c0-0.8,0.1-1.7,0.3-2.5c0.1-0.6,0.4-1.1,0.9-1.4c0.5-0.3,1-0.5,1.6-0.5c0.4,0,0.8,0.1,1.2,0.3
			c0.3,0.2,0.6,0.5,0.9,0.8c0.3,0.4,0.5,0.8,0.5,1.3c0.1,0.7,0.2,1.3,0.2,2c0,0.8-0.1,1.6-0.3,2.4c-0.2,0.5-0.5,1-0.9,1.4
			c-0.5,0.3-1,0.5-1.6,0.5c-0.8,0-1.5-0.3-2-0.9C530.9,147.3,530.7,146.1,530.7,144.5z M531.7,144.5c-0.1,1,0.1,1.9,0.5,2.8
			c0.4,0.7,1.2,0.9,1.9,0.5c0.2-0.1,0.4-0.3,0.5-0.5c0.4-0.9,0.6-1.8,0.5-2.8c0.1-1-0.1-1.9-0.5-2.8c-0.3-0.4-0.7-0.7-1.2-0.7
			s-0.9,0.2-1.2,0.6C531.8,142.5,531.6,143.5,531.7,144.5z"
                          />
                        </g>
                        <g className="vehicleParts c9" onClick={(event) => this.selectPart(event, 9)}>
                          <circle className="st0" cx="558.3" cy="210" r="10" />
                          <path
                            className="st1"
                            d="M555.8,213.3l1-0.1c0.1,0.4,0.2,0.7,0.5,1c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.2
			c0.3-0.1,0.4-0.3,0.6-0.6c0.2-0.3,0.3-0.6,0.4-1c0.1-0.4,0.2-0.9,0.2-1.3v-0.2c-0.2,0.3-0.5,0.6-0.9,0.8s-0.8,0.3-1.2,0.3
			c-0.7,0-1.3-0.3-1.8-0.8s-0.7-1.3-0.7-2c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.2-0.8,1.9-0.8c0.5,0,1.1,0.2,1.5,0.5
			c0.5,0.3,0.8,0.8,1.1,1.3c0.3,0.8,0.4,1.6,0.4,2.4c0.1,0.9-0.1,1.8-0.4,2.6c-0.2,0.6-0.6,1.1-1.1,1.5s-1.1,0.6-1.7,0.5
			c-0.6,0-1.2-0.2-1.6-0.6C556.2,214.4,555.9,213.9,555.8,213.3z M560.2,209.5c0-0.5-0.2-1-0.5-1.4c-0.3-0.3-0.7-0.5-1.2-0.5
			s-0.9,0.2-1.2,0.6c-0.3,0.4-0.5,1-0.5,1.5s0.2,0.9,0.5,1.3c0.7,0.7,1.7,0.7,2.4,0l0,0C560,210.6,560.2,210,560.2,209.5
			L560.2,209.5z"
                          />
                          <rect x="552.8" y="206.8" className="st2" width="10.9" height="10.5" />
                        </g>
                        <g className="vehicleParts c8" onClick={(event) => this.selectPart(event, 8)}>
                          <circle className="st0" cx="49.6" cy="270.3" r="10" />
                          <path
                            className="st1"
                            d="M48.6,269.1c-0.4-0.1-0.7-0.4-1-0.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.6,0.2-1.2,0.7-1.6s1.1-0.6,1.8-0.6
			c0.7-0.1,1.3,0.2,1.8,0.7c0.4,0.4,0.7,1,0.7,1.6c0,0.4-0.1,0.7-0.3,1c-0.3,0.3-0.6,0.6-1,0.7c0.5,0.1,0.9,0.4,1.2,0.8
			s0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8s-1.5-0.3-2.1-0.8s-0.9-1.2-0.8-1.9c0-0.5,0.1-1,0.4-1.4
			C47.6,269.5,48.1,269.2,48.6,269.1z M48.1,271.3c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2
			c0.9,0,1.7-0.8,1.7-1.7c0-0.5-0.2-0.9-0.5-1.3s-0.8-0.5-1.3-0.5s-0.9,0.2-1.2,0.5S48.1,270.9,48.1,271.3z M48.4,267.3
			c0,0.8,0.6,1.4,1.4,1.4c0.4,0,0.7-0.1,1-0.4c0.3-0.2,0.4-0.6,0.4-0.9c0-0.8-0.6-1.4-1.4-1.4c-0.4,0-0.7,0.1-1,0.4
			C48.5,266.6,48.4,267,48.4,267.3L48.4,267.3z"
                          />
                          <rect x="44.1" y="265.3" className="st2" width="10.9" height="10.5" />
                        </g>
                        <g className="vehicleParts c7" onClick={(event) => this.selectPart(event, 7)}>
                          <circle className="st0" cx="49.6" cy="146.5" r="10" />
                          <path
                            className="st1"
                            d="M47.1,142.6v-1h5.6v0.8c-0.6,0.7-1.1,1.5-1.6,2.3c-0.5,0.9-0.9,1.9-1.2,3c-0.2,0.8-0.3,1.6-0.4,2.4h-1.1
			c0-0.8,0.1-1.6,0.4-2.4c0.3-1,0.6-1.9,1.1-2.8c0.4-0.8,0.9-1.5,1.5-2.2L47.1,142.6L47.1,142.6z"
                          />
                          <rect x="44.1" y="141.5" className="st2" width="10.9" height="10.5" />
                        </g>
                        <g className="vehicleParts c6" onClick={(event) => this.selectPart(event, 6)}>
                          <circle className="st0" cx="78.5" cy="264.8" r="10" />
                          <path
                            className="st1"
                            d="M81.4,261.8l-1,0.1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.3-0.3-0.7-0.5-1.1-0.5c-0.3,0-0.6,0.1-0.9,0.3
			c-0.4,0.3-0.6,0.7-0.8,1.1c-0.2,0.6-0.3,1.3-0.3,2c0.2-0.3,0.6-0.7,0.9-0.9c0.4-0.2,0.8-0.3,1.2-0.3c0.7,0,1.3,0.3,1.8,0.8
			s0.7,1.3,0.7,2c0,0.5-0.1,1-0.3,1.5c-0.2,0.5-0.6,0.8-1,1.1c-0.4,0.3-0.9,0.4-1.4,0.4c-0.8,0-1.7-0.3-2.2-1
			c-0.6-0.9-0.9-2.1-0.8-3.2c-0.1-1.3,0.2-2.5,0.9-3.6c0.5-0.7,1.3-1.1,2.2-1c0.6,0,1.2,0.2,1.7,0.6
			C81.1,260.7,81.4,261.2,81.4,261.8z M77.1,265.5c0,0.3,0.1,0.7,0.2,1s0.3,0.6,0.6,0.8c0.3,0.2,0.6,0.3,0.9,0.3
			c0.4,0,0.8-0.2,1.1-0.5c0.4-0.4,0.5-1,0.5-1.5s-0.2-1-0.5-1.4c-0.7-0.7-1.7-0.7-2.4,0l0,0C77.2,264.6,77,265,77.1,265.5
			L77.1,265.5z"
                          />
                          <rect x="73.1" y="259.8" className="st2" width="10.9" height="10.5" />
                        </g>
                        <g className="vehicleParts c5" onClick={(event) => this.selectPart(event, 5)}>
                          <circle className="st0" cx="78.5" cy="151.5" r="10" />
                          <path
                            className="st1"
                            d="M75.9,152.8l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.2c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,1-0.2,1.3-0.6
			c0.7-0.9,0.7-2,0-2.9c-0.4-0.3-0.8-0.5-1.3-0.5c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.6,0.6l-1-0.1l0.8-4.4h4.3v1h-3.4
			l-0.5,2.3c0.5-0.3,1-0.5,1.6-0.5c0.7,0,1.4,0.3,1.9,0.8s0.8,1.3,0.8,2s-0.2,1.4-0.7,2c-0.6,0.7-1.4,1.1-2.3,1
			c-0.7,0-1.4-0.2-1.9-0.7C76.3,154.1,76,153.5,75.9,152.8z"
                          />
                          <rect x="73.1" y="146.5" className="st2" width="10.9" height="10.5" />
                        </g>
                        <g className="vehicleParts c4" onClick={(event) => this.selectPart(event, 4)}>
                          <circle className="st0" cx="150.8" cy="208.5" r="10" />
                          <path
                            className="st1"
                            d="M151.6,212v-2.1h-3.7v-1l3.9-5.6h0.9v5.6h1.2v1h-1.2v2.1H151.6z M151.6,209v-3.9l-2.7,3.9H151.6z"
                          />
                          <rect x="145.4" y="203.5" className="st2" width="10.9" height="10.5" />
                        </g>
                        <g className="vehicleParts c3" onClick={(event) => this.selectPart(event, 3)}>
                          <circle className="st0" cx="79.5" cy="208.5" r="10" />
                          <path
                            className="st1"
                            d="M76.4,209.8l1.1-0.1c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.3,0.7,0.4,1.1,0.4c0.5,0,0.9-0.2,1.3-0.5s0.5-0.8,0.5-1.3
			c0-0.9-0.8-1.7-1.7-1.7c-0.2,0-0.5,0-0.7,0.1l0.1-0.9h0.2c0.4,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.7,0.5-1.1c0-0.8-0.6-1.4-1.3-1.4
			c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1,0.4c-0.3,0.4-0.4,0.8-0.5,1.2l-1.1-0.2c0-0.6,0.4-1.2,0.9-1.6c0.5-0.4,1.1-0.6,1.7-0.6
			c0.5,0,0.9,0.1,1.3,0.3s0.7,0.5,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.6-0.9,0.7
			c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.4,0.4,0.9,0.4,1.4c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.3,0.8-2.1,0.8c-0.7,0-1.4-0.2-1.9-0.7
			C76.8,211,76.5,210.4,76.4,209.8z"
                          />
                          <rect x="73.6" y="203.5" className="st2" width="10.9" height="10.5" />
                        </g>
                        <g className="vehicleParts c2" onClick={(event) => this.selectPart(event, 2)}>
                          <circle className="st0" cx="54.9" cy="210.5" r="10" />
                          <path
                            className="st1"
                            d="M57.9,213v1h-5.7c0-0.2,0-0.5,0.1-0.7c0.1-0.4,0.4-0.8,0.7-1.1s0.8-0.8,1.4-1.3c0.7-0.6,1.3-1.2,1.9-1.8
			c0.3-0.4,0.5-0.8,0.5-1.3c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.8-0.4-1.2-0.4c-0.5,0-0.9,0.2-1.2,0.5s-0.5,0.8-0.5,1.3l-1.1-0.1
			c0.1-0.7,0.3-1.3,0.8-1.8c0.6-0.4,1.3-0.7,2-0.6c0.7,0,1.5,0.2,2,0.7c0.5,0.4,0.7,1.1,0.7,1.7c0,0.3-0.1,0.7-0.2,1
			c-0.2,0.4-0.4,0.7-0.7,1s-0.9,0.9-1.6,1.5s-1,0.9-1.2,1.1c-0.2,0.2-0.3,0.4-0.4,0.6h4.1L57.9,213z"
                          />
                          <rect x="49.5" y="205.5" className="st2" width="10.9" height="10.5" />
                        </g>
                        <g className="vehicleParts c1" onClick={(event) => this.selectPart(event, 1)}>
                          <circle className="st0" cx="25.1" cy="211.3" r="10" />
                          <path
                            className="st1"
                            d="M25.6,215.3h-1.1v-6.8c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.4-1.1,0.5v-1c0.6-0.3,1.1-0.6,1.5-1
			c0.4-0.3,0.7-0.7,0.9-1.1h0.7L25.6,215.3L25.6,215.3z"
                          />
                          <rect x="19.7" y="206.3" className="st2" width="10.9" height="10.5" />
                        </g>
                      </g>
                    </svg>
                  </div>
                </PivotItem>
                <PivotItem headerText={Resource.modules.PDICheck.interior} className="interior">
                  <div className="img">
                    {scaleVehicle === 1 ? <div className="overlay" onMouseDown={this.zoom}></div> : null}
                    <svg
                      viewBox="0 0 800 500"
                      style={{
                        position: "absolute",
                        transform: "translate(" + clientX + "px, " + clientY + "px) scale(" + scaleVehicle + ")",
                        left: 0 + "%",
                        top: 0 + "%",
                        transition: "all 0.4s ease 0s",
                      }}
                    >
                      <image
                        width="556"
                        height="600"
                        transform="translate(70 -105) scale(1.2)"
                        href={require("../../assets/images/interior.svg")}
                        onMouseDown={this.zoomOut}
                      />
                      <g id="Numbers" className={!showNumbers ? "circleNumbers hideNumbers" : "circleNumbers"}>
                        <g className="vehicleParts c69" onClick={(event) => this.selectPart(event, 69)}>
                          <path
                            d="M702,474.2c0.7-0.1,1.3-0.2,2-0.2c0.8-0.1,1.7,0.2,2.3,0.7c0.4,0.4,0.6,0.9,0.6,1.5c0,1-0.6,1.8-1.5,2.1l0,0
							c0.6,0.3,1.1,0.9,1.2,1.6c0.3,1.1,0.5,1.9,0.6,2.2h-1.1c-0.2-0.6-0.4-1.3-0.5-1.9c-0.2-1.1-0.7-1.5-1.6-1.6h-1v3.5h-1V474.2z
							M703,477.9h1.1c1.1,0,1.8-0.6,1.8-1.5s-0.8-1.5-1.9-1.5c-0.3,0-0.7,0-1,0.1L703,477.9L703,477.9z"
                          />
                          <path
                            d="M713.7,479.2c0.1,1.6-1.1,3-2.7,3.1c-0.1,0-0.2,0-0.2,0c-1.5,0-2.8-1.2-2.8-2.8c0-0.1,0-0.2,0-0.2c-0.1-1.6,1.1-3,2.7-3.1
							c0.1,0,0.2,0,0.2,0c1.5,0,2.8,1.1,2.8,2.6C713.7,478.9,713.7,479.1,713.7,479.2z M709.1,479.3c0,1.3,0.7,2.2,1.8,2.2
							s1.8-0.9,1.8-2.3c0-1-0.5-2.2-1.7-2.2C709.6,477,709.1,478.2,709.1,479.3L709.1,479.3z"
                          />
                          <path
                            d="M720.3,479.2c0.1,1.6-1.1,3-2.7,3.1c-0.1,0-0.2,0-0.2,0c-1.5,0-2.8-1.2-2.8-2.8c0-0.1,0-0.2,0-0.2c-0.1-1.6,1.1-3,2.7-3.1
							c0.1,0,0.2,0,0.2,0c1.5,0,2.8,1.1,2.8,2.6C720.3,478.9,720.3,479.1,720.3,479.2z M715.7,479.3c0,1.3,0.7,2.2,1.8,2.2
							s1.8-0.9,1.8-2.3c0-1-0.5-2.2-1.7-2.2C716.2,477,715.7,478.2,715.7,479.3L715.7,479.3z"
                          />
                          <path
                            d="M721.7,482.1v-5h-0.8v-0.8h0.8V476c-0.1-0.7,0.2-1.5,0.7-2c0.4-0.4,0.9-0.5,1.4-0.5c0.3,0,0.6,0.1,0.9,0.2l-0.1,0.8
							c-0.2-0.1-0.5-0.1-0.7-0.1c-0.9,0-1.1,0.8-1.1,1.7v0.3h1.4v0.8h-1.4v5L721.7,482.1L721.7,482.1z"
                          />
                          <path
                            d="M727.7,474.2c0.7-0.1,1.3-0.2,2-0.2c0.8-0.1,1.7,0.2,2.3,0.7c0.4,0.5,0.7,1.1,0.7,1.7s-0.2,1.2-0.6,1.7
							c-0.7,0.6-1.6,0.9-2.5,0.9c-0.3,0-0.5,0-0.8-0.1v3.2h-1L727.7,474.2L727.7,474.2z M728.7,478.1c0.3,0.1,0.6,0.1,0.9,0.1
							c1.3,0,2-0.6,2-1.7s-0.8-1.6-1.9-1.6c-0.3,0-0.7,0-1,0.1V478.1z"
                          />
                          <path
                            d="M737,482.1l-0.1-0.7l0,0c-0.4,0.6-1.1,0.9-1.8,0.9c-0.9,0.1-1.7-0.6-1.8-1.5c0-0.1,0-0.1,0-0.2c0-1.4,1.2-2.2,3.5-2.2
							v-0.1c0.1-0.6-0.3-1.2-1-1.3c-0.1,0-0.2,0-0.3,0c-0.5,0-1,0.2-1.5,0.4l-0.2-0.7c0.6-0.3,1.2-0.5,1.9-0.5c1.8,0,2.2,1.2,2.2,2.4
							v2.2c0,0.5,0,0.9,0.1,1.4L737,482.1L737,482.1z M736.8,479.2c-1.2,0-2.5,0.2-2.5,1.3c0,0.5,0.4,1,0.9,1h0.1c0.6,0,1.2-0.4,1.4-1
							c0-0.1,0.1-0.2,0.1-0.3V479.2z"
                          />
                          <path
                            d="M739.5,477.9v-1.6h0.9l0.1,1l0,0c0.4-0.7,1.1-1.1,1.9-1.1c0.8,0,2.1,0.5,2.1,2.5v3.5h-1.1v-3.3c0-0.9-0.3-1.7-1.3-1.7
							c-0.7,0-1.2,0.5-1.4,1.1c0,0.2-0.1,0.3-0.1,0.5v3.5h-1.1L739.5,477.9L739.5,477.9z"
                          />
                          <path
                            d="M746.8,479.4c-0.1,1,0.7,1.9,1.7,2c0.1,0,0.2,0,0.3,0c0.6,0,1.1-0.1,1.6-0.3l0.2,0.8c-0.6,0.3-1.2,0.4-1.9,0.4
							c-1.5,0.1-2.8-1-2.9-2.5c0-0.1,0-0.2,0-0.4c0-1.7,1-3.1,2.7-3.1c1.4,0,2.4,1.1,2.4,2.5c0,0.1,0,0.1,0,0.2v0.5h-4.1V479.4z
							M749.9,478.7c0.1-0.8-0.5-1.6-1.3-1.7c-0.1,0-0.1,0-0.2,0c-0.9,0.1-1.6,0.8-1.6,1.7H749.9z"
                          />
                          <path d="M752.2,473.6h1.1v8.5h-1.1V473.6z" />
                          <g>
                            <circle className="st0" cx="677.5" cy="478.3" r="16" />
                            <path
                              className="st1"
                              d="M675.6,472.9h-1.3c-2.2,0.3-3.9,2.2-4.3,4.4l0,0c1.3-1.6,3.6-1.9,5.4-0.6c0.9,0.8,1.6,1.9,1.4,3.2
								c0,2.2-1.7,4.1-3.9,4.3h-0.2c-2.7,0-4.4-2.1-4.4-5.2c0-2.1,0.8-3.9,2.1-5.5c1.1-0.9,2.5-1.6,3.9-1.9c0.5,0,0.9-0.2,1.3-0.2
								L675.6,472.9L675.6,472.9z M675.1,480c0.2-1.4-0.8-2.7-2.1-2.8h-0.5c-0.9,0-1.9,0.6-2.4,1.6c-0.2,0.2-0.2,0.5-0.2,0.8
								c0,2.1,0.9,3.5,2.7,3.5C674.2,482.7,675.3,481.5,675.1,480L675.1,480z"
                            />
                            <path
                              className="st1"
                              d="M679.2,482.9h1.4c0.9-0.2,1.9-0.5,2.5-1.3c0.9-0.9,1.4-2.1,1.6-3.3l0,0c-0.8,0.8-1.7,1.3-2.8,1.3
								c-1.9,0-3.6-1.6-3.6-3.5v-0.2v-0.2c0-2.4,1.7-4.3,4.1-4.4h0.2c2.5,0,4.1,2.1,4.1,5.2c0,2.2-0.6,4.3-2.1,5.8
								c-1.6,1.1-3.3,1.7-5.2,1.7L679.2,482.9L679.2,482.9z M679.9,475.9c-0.2,1.4,0.9,2.5,2.4,2.7l0,0c0.9,0,1.9-0.5,2.4-1.3
								c0.2-0.2,0.2-0.3,0.2-0.6c0-2.1-0.8-3.8-2.5-3.8C680.8,472.9,679.9,474.2,679.9,475.9L679.9,475.9z"
                            />
                          </g>
                        </g>
                        <g className="vehicleParts c67-2" onClick={(event) => this.selectPart(event, 67)}>
                          <circle className="st0" cx="605.6" cy="352" r="16" />
                          <path
                            className="st1"
                            d="M603.7,346.8h-1.3c-2.2,0.3-3.9,2.2-4.3,4.4l0,0c1.3-1.6,3.6-1.9,5.4-0.6c0.9,0.8,1.6,1.9,1.4,3.2
							c0,2.2-1.7,4.1-3.9,4.3h-0.2c-2.7,0-4.4-2.1-4.4-5.2c0-2.1,0.8-3.9,2.1-5.5c1.1-0.9,2.5-1.6,3.9-1.9c0.5,0,0.9-0.2,1.3-0.2
							L603.7,346.8L603.7,346.8z M603.2,353.9c0.2-1.4-0.8-2.7-2.1-2.8h-0.5c-0.9,0-1.9,0.6-2.4,1.6c-0.2,0.2-0.2,0.5-0.2,0.8
							c0,2.1,0.9,3.5,2.7,3.5C602.3,356.7,603.2,355.6,603.2,353.9z"
                          />
                          <path className="st1" d="M614.4,345.7v1.1l-5.4,11.2h-1.7l5.4-10.9h-6v-1.4H614.4z" />
                        </g>
                        <g className="vehicleParts c67" onClick={(event) => this.selectPart(event, 67)}>
                          <circle className="st0" cx="605.6" cy="206" r="16" />
                          <path
                            className="st1"
                            d="M603.7,200.6h-1.3c-2.2,0.3-3.9,2.2-4.3,4.4l0,0c1.3-1.6,3.6-1.9,5.4-0.6c0.9,0.8,1.6,1.9,1.4,3.2
							c0,2.2-1.7,4.1-3.9,4.3h-0.2c-2.7,0-4.4-2.1-4.4-5.2c0-2.1,0.8-3.9,2.1-5.5c1.1-0.9,2.5-1.6,3.9-1.9c0.5,0,0.9-0.2,1.3-0.2
							L603.7,200.6L603.7,200.6z M603.2,207.7c0.2-1.4-0.8-2.7-2.1-2.8h-0.5c-0.9,0-1.9,0.6-2.4,1.6c-0.2,0.2-0.2,0.5-0.2,0.8
							c0,2.1,0.9,3.5,2.7,3.5C602.3,210.4,603.4,209.2,603.2,207.7z"
                          />
                          <path className="st1" d="M614.4,199.4v1.1l-5.4,11.2h-1.7l5.4-10.9h-6v-1.4L614.4,199.4z" />
                        </g>
                        <g className="vehicleParts c66" onClick={(event) => this.selectPart(event, 66)}>
                          <circle className="st0" cx="667.9" cy="274.6" r="16" />
                          <path
                            className="st1"
                            d="M665.8,269.2h-1.3c-2.2,0.3-3.9,2.2-4.3,4.4l0,0c1.3-1.6,3.6-1.9,5.4-0.6c0.9,0.8,1.6,1.9,1.4,3.2
							c0,2.2-1.7,4.1-3.9,4.3H663c-2.7,0-4.4-2.1-4.4-5.2c0-2.1,0.8-3.9,2.1-5.5c1.1-0.9,2.5-1.6,3.9-1.9c0.5,0,0.9-0.2,1.3-0.2
							L665.8,269.2L665.8,269.2z M665.4,276.3c0.2-1.4-0.8-2.7-2.1-2.8h-0.5c-0.9,0-1.9,0.6-2.4,1.6c-0.2,0.2-0.2,0.5-0.2,0.8
							c0,2.1,0.9,3.5,2.7,3.5C664.4,279.3,665.4,278.1,665.4,276.3L665.4,276.3z"
                          />
                          <path
                            className="st1"
                            d="M675.6,269.2h-1.3c-2.2,0.3-3.9,2.2-4.3,4.4l0,0c1.3-1.6,3.6-1.9,5.4-0.6c0.9,0.8,1.6,1.9,1.4,3.2
							c0,2.2-1.7,4.1-3.9,4.3h-0.2c-2.7,0-4.4-2.1-4.4-5.2c0-2.1,0.8-3.9,2.1-5.5c1.1-0.9,2.5-1.6,3.9-1.9c0.5,0,0.9-0.2,1.3-0.2V269.2
							L675.6,269.2z M675.2,276.3c0.2-1.4-0.8-2.7-2.1-2.8h-0.5c-0.9,0-1.9,0.6-2.4,1.6c-0.2,0.2-0.2,0.5-0.2,0.8c0,2.1,0.9,3.5,2.7,3.5
							C674.2,279.3,675.2,278.1,675.2,276.3L675.2,276.3z"
                          />
                        </g>
                        <g className="vehicleParts c65" onClick={(event) => this.selectPart(event, 65)}>
                          <circle className="st0" cx="406.4" cy="350.5" r="16" />
                          <path
                            className="st1"
                            d="M404.3,345.1h-1.3c-2.2,0.3-3.9,2.2-4.3,4.4l0,0c1.3-1.6,3.6-1.9,5.4-0.6c0.9,0.8,1.4,1.9,1.4,3.2
							c0,2.2-1.7,4.1-3.9,4.3h-0.2c-2.7,0-4.4-2.1-4.4-5.2c0-2.1,0.8-3.9,2.1-5.5c1.1-0.9,2.5-1.6,3.9-1.9c0.5,0,0.9-0.2,1.3-0.2
							L404.3,345.1L404.3,345.1z M403.9,352.2c0.2-1.4-0.8-2.7-2.1-2.8h-0.5c-0.9,0-1.9,0.6-2.4,1.6c-0.2,0.2-0.2,0.5-0.2,0.8
							c0,2.1,0.9,3.5,2.7,3.5C402.9,355.2,403.9,354,403.9,352.2z"
                          />
                          <path
                            className="st1"
                            d="M414.5,345.4h-4.7l-0.5,3.2h0.9c0.9,0,1.9,0.2,2.7,0.6c1.1,0.6,1.7,1.9,1.7,3.2c0,2.4-2.1,4.3-4.4,4.3H410
							c-1.1,0.2-2.2-0.2-3-0.8l0.5-1.3c0.8,0.5,1.7,0.6,2.7,0.6c1.4,0.2,2.7-0.9,2.8-2.4v-0.3c0-1.6-1.1-2.7-3.5-2.7
							c-0.6,0-1.1,0-1.7,0.2l0.8-5.8h5.8L414.5,345.4L414.5,345.4z"
                          />
                        </g>
                        <g className="vehicleParts c64" onClick={(event) => this.selectPart(event, 64)}>
                          <circle className="st0" cx="406.4" cy="199.5" r="16" />
                          <path
                            className="st1"
                            d="M404.3,194.1h-1.3c-2.2,0.3-3.9,2.2-4.3,4.4l0,0c1.3-1.6,3.6-1.9,5.4-0.6c0.9,0.8,1.4,1.9,1.4,3.2
							c0,2.2-1.7,4.1-3.9,4.3h-0.2c-2.7,0-4.4-2.1-4.4-5.2c0-2.1,0.8-3.9,2.1-5.5c1.1-0.9,2.5-1.6,3.9-1.9c0.5,0,0.9-0.2,1.3-0.2
							L404.3,194.1L404.3,194.1z M403.9,201.2c0.2-1.4-0.8-2.7-2.1-2.8h-0.5c-0.9,0-1.9,0.6-2.4,1.6c-0.2,0.2-0.2,0.5-0.2,0.8
							c0,2.1,0.9,3.5,2.7,3.5C402.9,204.1,403.9,202.7,403.9,201.2z"
                          />
                          <path
                            className="st1"
                            d="M412.2,205.2v-3.3h-5.7v-1.1l5.5-7.9h1.7v7.6h1.7v1.3h-1.7v3.3L412.2,205.2L412.2,205.2z M412.2,200.6v-6l0,0
							l-0.9,1.9l-3,4.3L412.2,200.6L412.2,200.6z"
                          />
                        </g>
                        <g className="vehicleParts c63" onClick={(event) => this.selectPart(event, 63)}>
                          <circle className="st0" cx="475.4" cy="446.6" r="16" />
                          <path
                            className="st1"
                            d="M473.5,441.2h-1.3c-2.2,0.3-3.9,2.2-4.3,4.4l0,0c1.3-1.6,3.6-1.9,5.4-0.6c0.9,0.8,1.4,1.9,1.4,3.2
							c0,2.2-1.7,4.1-3.9,4.3h-0.2c-2.7,0-4.4-2.1-4.4-5.2c0-2.1,0.8-3.9,2.1-5.5c1.1-0.9,2.5-1.6,3.9-1.9c0.5,0,0.9-0.2,1.3-0.2
							L473.5,441.2L473.5,441.2z M473,448.3c0.2-1.4-0.8-2.7-2.1-2.8h-0.5c-0.9,0-1.9,0.6-2.4,1.6c-0.2,0.2-0.2,0.5-0.2,0.8
							c0,2.1,0.9,3.5,2.7,3.5C472.1,451.3,473,450.1,473,448.3z"
                          />
                          <path
                            className="st1"
                            d="M476.5,450.4c0.8,0.5,1.7,0.8,2.7,0.8c2.2,0,2.8-1.4,2.8-2.4c0-1.7-1.6-2.5-3.2-2.5h-0.9V445h0.9
							c1.3,0,2.7-0.6,2.7-2.1c0-0.9-0.6-1.9-2.2-1.9c-0.9,0-1.7,0.3-2.5,0.8l-0.5-1.3c0.9-0.6,2.1-0.9,3.2-0.9c1.6-0.2,3.2,0.9,3.5,2.5
							v0.5c0,1.3-0.9,2.5-2.2,2.8l0,0c1.6,0.2,2.7,1.6,2.7,3.2c0,1.9-1.6,3.6-4.4,3.6c-1.1,0-2.2-0.3-3.2-0.8L476.5,450.4z"
                          />
                        </g>
                        <g className="vehicleParts c62" onClick={(event) => this.selectPart(event, 62)}>
                          <circle className="st0" cx="475.4" cy="101.3" r="16" />
                          <path
                            className="st1"
                            d="M473.5,96.1h-1.3c-2.2,0.3-3.9,2.2-4.3,4.4l0,0c1.3-1.6,3.6-1.9,5.4-0.6c0.9,0.8,1.4,1.9,1.4,3.2
							c0,2.2-1.7,4.1-3.9,4.3h-0.2c-2.7,0-4.4-2.1-4.4-5.2c0-2.1,0.8-3.9,2.1-5.5c1.1-0.9,2.5-1.6,3.9-1.9c0.5,0,0.9-0.2,1.3-0.2
							L473.5,96.1L473.5,96.1z M473,103.2c0.2-1.4-0.8-2.7-2.1-2.8h-0.5c-0.9,0-1.9,0.6-2.4,1.6c-0.2,0.2-0.2,0.5-0.2,0.8
							c0,2.1,0.9,3.5,2.7,3.5C472.1,106,473.2,104.6,473,103.2L473,103.2z"
                          />
                          <path
                            className="st1"
                            d="M476,107.1v-0.9l1.3-1.3c3.2-3,4.6-4.6,4.6-6.5c0.2-1.1-0.8-2.2-1.9-2.4h-0.5c-0.9,0-1.9,0.5-2.7,1.1
							l-0.5-1.1c0.9-0.8,2.2-1.3,3.5-1.3c1.9-0.2,3.6,1.3,3.8,3.2v0.5c0,2.2-1.6,4.1-4.3,6.6l-0.9,0.9h5.5v1.4H476V107.1L476,107.1z"
                          />
                        </g>
                        <g className="vehicleParts c61" onClick={(event) => this.selectPart(event, 61)}>
                          <circle className="st0" cx="462.1" cy="350.9" r="16" />
                          <path
                            className="st1"
                            d="M460.2,345.5h-1.3c-2.2,0.3-3.9,2.2-4.3,4.4l0,0c1.3-1.6,3.6-1.9,5.4-0.6c0.9,0.8,1.4,1.9,1.4,3.2
							c0,2.2-1.7,4.1-3.9,4.3h-0.2c-2.7,0-4.4-2.1-4.4-5.2c0-2.1,0.8-3.9,2.1-5.5c1.1-0.9,2.5-1.6,3.9-1.9c0.5,0,0.9-0.2,1.3-0.2
							L460.2,345.5L460.2,345.5z M459.7,352.6c0.2-1.4-0.8-2.7-2.1-2.8h-0.5c-0.9,0-1.9,0.6-2.4,1.6c-0.2,0.2-0.2,0.5-0.2,0.8
							c0,2.1,0.9,3.5,2.7,3.5C458.8,355.6,459.7,354.4,459.7,352.6z"
                          />
                          <path className="st1" d="M466.5,346L466.5,346l-2.2,1.1l-0.3-1.3l2.7-1.4h1.4v12.3h-1.6V346z" />
                        </g>
                        <g className="vehicleParts c60" onClick={(event) => this.selectPart(event, 60)}>
                          <circle className="st0" cx="463.6" cy="205.3" r="16" />
                          <path
                            className="st1"
                            d="M461.7,199.9h-1.3c-2.2,0.3-3.9,2.2-4.3,4.4l0,0c1.3-1.6,3.6-1.9,5.4-0.6c0.9,0.8,1.4,1.9,1.4,3.2
							c0,2.2-1.7,4.1-3.9,4.3h-0.2c-2.7,0-4.4-2.1-4.4-5.2c0-2.1,0.8-3.9,2.1-5.5c1.1-0.9,2.5-1.6,3.9-1.9c0.5,0,0.9-0.2,1.3-0.2
							L461.7,199.9L461.7,199.9z M461.2,207c0.2-1.4-0.8-2.7-2.1-2.8h-0.5c-0.9,0-1.9,0.6-2.4,1.6c-0.2,0.2-0.2,0.5-0.2,0.8
							c0,2.1,0.9,3.5,2.7,3.5C460.3,209.7,461.4,208.5,461.2,207z"
                          />
                          <path
                            className="st1"
                            d="M472.6,204.7c0,4.3-1.6,6.5-4.3,6.5c-2.4,0-4.1-2.2-4.1-6.3c0-4.1,1.7-6.5,4.3-6.5
							C471,198.4,472.6,200.9,472.6,204.7z M465.8,205c0,3.2,0.9,5.1,2.5,5.1s2.5-2.1,2.5-5.2s-0.8-5.1-2.5-5.1
							C466.9,199.8,465.8,201.5,465.8,205L465.8,205z"
                          />
                        </g>
                        <g className="vehicleParts c59" onClick={(event) => this.selectPart(event, 59)}>
                          <circle className="st0" cx="204" cy="374.2" r="16" />
                          <path
                            className="st1"
                            d="M202.4,369.2h-4.7l-0.5,3.2h0.9c0.9,0,1.9,0.2,2.7,0.6c1.1,0.6,1.7,1.9,1.7,3.2c0,2.4-2.1,4.3-4.4,4.3H198
							c-1.1,0.2-2.2-0.2-3-0.8l0.5-1.3c0.8,0.5,1.7,0.6,2.7,0.6c1.4,0.2,2.7-0.9,2.8-2.4v-0.3c0-1.6-1.1-2.7-3.5-2.7
							c-0.6,0-1.1,0-1.7,0.2l0.8-5.8h5.8V369.2z"
                          />
                          <path
                            className="st1"
                            d="M205.7,378.8h1.4c0.9-0.2,1.9-0.5,2.5-1.3c0.9-0.9,1.4-2.1,1.6-3.3l0,0c-0.8,0.8-1.7,1.3-2.8,1.3
							c-1.9,0-3.6-1.6-3.6-3.5v-0.2v-0.2c0-2.4,1.7-4.3,4.1-4.4h0.2c2.5,0,4.1,2.1,4.1,5.2c0,2.2-0.6,4.3-2.1,5.8
							c-1.6,1.1-3.3,1.7-5.2,1.7L205.7,378.8L205.7,378.8z M206.4,371.7c-0.2,1.4,0.9,2.5,2.4,2.7l0,0c0.9,0,1.9-0.5,2.4-1.3
							c0.2-0.2,0.2-0.3,0.2-0.6c0-2.1-0.8-3.8-2.5-3.8C207.3,368.7,206.4,370,206.4,371.7z"
                          />
                        </g>
                        <g className="vehicleParts c58" onClick={(event) => this.selectPart(event, 58)}>
                          <circle className="st0" cx="192.5" cy="202.3" r="16" />
                          <path
                            className="st1"
                            d="M190.9,197.1h-4.7l-0.5,3.2h0.9c0.9,0,1.9,0.2,2.7,0.6c1.1,0.6,1.7,1.9,1.7,3.2c0,2.4-2.1,4.3-4.4,4.3h-0.2
							c-1.1,0.2-2.2-0.2-3-0.8l0.5-1.3c0.8,0.5,1.7,0.6,2.7,0.6c1.4,0.2,2.7-0.9,2.8-2.4v-0.3c0-1.6-1.1-2.7-3.5-2.7
							c-0.6,0-1.1,0-1.7,0.2l0.8-5.8h5.8V197.1L190.9,197.1z"
                          />
                          <path
                            className="st1"
                            d="M193.1,204.8c0-1.4,0.9-2.8,2.4-3.3l0,0c-1.1-0.5-1.9-1.6-1.9-2.8c0-1.9,1.6-3.3,3.5-3.3h0.2h0.2
							c2.4,0,3.6,1.4,3.6,3c0,1.3-0.8,2.5-2.1,2.8l0,0c1.4,0.5,2.4,1.7,2.5,3.2c-0.2,2.2-1.9,3.8-4.1,3.6h-0.2c-1.7,0.5-3.5-0.5-3.9-2.2
							v-0.2C193.1,205.3,193,205.1,193.1,204.8z M199.8,204.8c0-1.4-1.1-2.2-2.7-2.7c-1.3,0.3-2.1,1.3-2.2,2.5c0,1.3,1.1,2.4,2.4,2.4
							h0.2c1.3,0.2,2.2-0.8,2.4-1.9c0,0,0,0,0-0.2L199.8,204.8z M195.2,198.7c0,1.3,0.9,1.9,2.4,2.2c1.1-0.2,1.9-1.1,1.9-2.2
							c0-1.1-0.8-2.1-1.9-2.1l0,0h-0.2C195.8,196.8,195.2,197.6,195.2,198.7z"
                          />
                        </g>
                        <g className="vehicleParts c57" onClick={(event) => this.selectPart(event, 57)}>
                          <circle className="st0" cx="249.2" cy="478.3" r="16" />
                          <path
                            className="st1"
                            d="M247.5,473.1h-4.7l-0.5,3.2h0.9c0.9,0,1.9,0.2,2.7,0.6c1.1,0.6,1.7,1.9,1.7,3.2c0,2.4-2.1,4.3-4.4,4.3H243
							c-1.1,0.2-2.2-0.2-3-0.8l0.5-1.3c0.8,0.5,1.7,0.6,2.7,0.6c1.4,0.2,2.7-0.9,2.8-2.4v-0.3c0-1.6-1.1-2.7-3.5-2.7
							c-0.6,0-1.1,0-1.7,0.2l0.8-5.8h5.8V473.1z"
                          />
                          <path className="st1" d="M257.9,471.7v1.1l-5.4,11.2h-1.7l5.4-10.9h-6v-1.4L257.9,471.7z" />
                        </g>
                        <g className="vehicleParts c56" onClick={(event) => this.selectPart(event, 56)}>
                          <ellipse
                            transform="matrix(0.592 -0.8059 0.8059 0.592 30.691 232.0878)"
                            className="st0"
                            cx="244.6"
                            cy="85.7"
                            rx="16"
                            ry="16"
                          />
                          <path
                            className="st1"
                            d="M243,80.5h-4.7l-0.5,3.2h0.9c0.9,0,1.9,0.2,2.7,0.6c1.1,0.6,1.7,1.9,1.7,3.2c0,2.4-2.1,4.3-4.4,4.3h-0.2
							c-1.1,0.2-2.2-0.2-3-0.8l0.5-1.3c0.8,0.5,1.7,0.6,2.7,0.6c1.4,0.2,2.7-0.9,2.8-2.4v-0.3c0-1.6-1.1-2.7-3.5-2.7
							c-0.6,0-1.1,0-1.7,0.2l0.8-5.8h5.8V80.5L243,80.5z"
                          />
                          <path
                            className="st1"
                            d="M252.4,80.3h-1.3c-2.2,0.3-3.9,2.2-4.3,4.4l0,0c1.3-1.6,3.6-1.9,5.4-0.6c0.9,0.8,1.4,1.9,1.4,3.2
							c0,2.2-1.7,4.1-3.9,4.3h-0.2c-2.7,0-4.4-2.1-4.4-5.2c0-2.1,0.8-3.9,2.1-5.5c1.1-0.9,2.5-1.6,3.9-1.9c0.5,0,0.9-0.2,1.3-0.2
							L252.4,80.3L252.4,80.3z M251.9,87.4c0.2-1.4-0.8-2.7-2.1-2.8h-0.5c-0.9,0-1.9,0.6-2.4,1.6c-0.2,0.2-0.2,0.5-0.2,0.8
							c0,2.1,0.9,3.5,2.7,3.5C250.9,90.3,252,89,251.9,87.4z"
                          />
                        </g>
                        <g className="vehicleParts c55" onClick={(event) => this.selectPart(event, 55)}>
                          <circle className="st0" cx="277.7" cy="349.4" r="16" />
                          <path
                            className="st1"
                            d="M276.1,344.3h-4.7l-0.5,3.2h0.9c0.9,0,1.9,0.2,2.7,0.6c1.1,0.6,1.7,1.9,1.7,3.2c0,2.4-2.1,4.3-4.4,4.3h-0.2
							c-1.1,0.2-2.2-0.2-3-0.8l0.5-1.3c0.8,0.5,1.7,0.6,2.7,0.6c1.4,0.2,2.7-0.9,2.8-2.4v-0.3c0-1.6-1.1-2.7-3.5-2.7
							c-0.6,0-1.1,0-1.7,0.2l0.8-5.8h5.8L276.1,344.3L276.1,344.3z"
                          />
                          <path
                            className="st1"
                            d="M285.8,344.3H281l-0.5,3.2h0.9c0.9,0,1.9,0.2,2.7,0.6c1.1,0.6,1.7,1.9,1.7,3.2c0,2.4-2.1,4.3-4.4,4.3h-0.2
							c-1.1,0.2-2.2-0.2-3-0.8l0.5-1.3c0.8,0.5,1.7,0.6,2.7,0.6c1.4,0.2,2.7-0.9,2.8-2.4v-0.3c0-1.6-1.1-2.7-3.5-2.7
							c-0.6,0-1.1,0-1.7,0.2l0.8-5.8h5.8V344.3L285.8,344.3z"
                          />
                        </g>
                        <g className="vehicleParts c54" onClick={(event) => this.selectPart(event, 54)}>
                          <circle className="st0" cx="278.6" cy="198.7" r="16" />
                          <path
                            className="st1"
                            d="M277,193.5h-4.7l-0.5,3.2h0.9c0.9,0,1.9,0.2,2.7,0.6c1.1,0.6,1.7,1.9,1.7,3.2c0,2.4-2.1,4.3-4.4,4.3h-0.2
							c-1.1,0.2-2.2-0.2-3-0.8l0.5-1.3c0.8,0.5,1.7,0.6,2.7,0.6c1.4,0.2,2.7-0.9,2.8-2.4v-0.3c0-1.6-1.1-2.7-3.5-2.7
							c-0.6,0-1.1,0-1.7,0.2l0.8-5.8h5.8L277,193.5L277,193.5z"
                          />
                          <path
                            className="st1"
                            d="M284.4,204.4v-3.3h-5.7V200l5.5-7.9h1.7v7.6h1.7v1.3H286v3.3L284.4,204.4L284.4,204.4z M284.4,199.8v-6l0,0
							l-0.9,1.9l-3,4.3L284.4,199.8L284.4,199.8z"
                          />
                        </g>
                        <g className="vehicleParts c53" onClick={(event) => this.selectPart(event, 53)}>
                          <circle className="st0" cx="213" cy="275.2" r="16" />
                          <path
                            className="st1"
                            d="M211.4,270h-4.7l-0.5,3.2h0.9c0.9,0,1.9,0.2,2.7,0.6c1.1,0.6,1.7,1.9,1.7,3.2c0,2.4-2.1,4.3-4.4,4.3H207
							c-1.1,0.2-2.2-0.2-3-0.8l0.5-1.3c0.8,0.5,1.7,0.6,2.7,0.6c1.4,0.2,2.7-0.9,2.8-2.4v-0.3c0-1.6-1.1-2.7-3.5-2.7
							c-0.6,0-1.1,0-1.7,0.2l0.8-5.8h5.8V270L211.4,270z"
                          />
                          <path
                            className="st1"
                            d="M214.1,279c0.8,0.5,1.7,0.8,2.7,0.8c2.2,0,2.8-1.4,2.8-2.4c0-1.7-1.6-2.5-3.2-2.5h-0.9v-1.3h0.9
							c1.3,0,2.7-0.6,2.7-2.1c0-0.9-0.6-1.9-2.2-1.9c-0.9,0-1.7,0.3-2.5,0.8l-0.5-1.3c0.9-0.6,2.1-0.9,3.2-0.9c1.6-0.2,3.2,0.9,3.5,2.5
							v0.5c0,1.3-0.9,2.5-2.2,2.8l0,0c1.6,0.2,2.7,1.6,2.7,3.2c0,1.9-1.6,3.6-4.4,3.6c-1.1,0-2.2-0.3-3.2-0.8L214.1,279z"
                          />
                        </g>
                        <g className="vehicleParts c52" onClick={(event) => this.selectPart(event, 52)}>
                          <circle className="st0" cx="173.9" cy="341.3" r="16" />
                          <path
                            className="st1"
                            d="M172.3,336.2h-4.7l-0.5,3.2h0.9c0.9,0,1.9,0.2,2.7,0.6c1.1,0.6,1.7,1.9,1.7,3.2c0,2.4-2.1,4.3-4.4,4.3h-0.2
							c-1.1,0.2-2.2-0.2-3-0.8l0.5-1.3c0.8,0.5,1.7,0.6,2.7,0.6c1.4,0.2,2.7-0.9,2.8-2.4v-0.3c0-1.6-1.1-2.7-3.5-2.7
							c-0.6,0-1.1,0-1.7,0.2l0.8-5.8h5.8V336.2z"
                          />
                          <path
                            className="st1"
                            d="M174.7,347.1v-0.9l1.3-1.3c3.2-3,4.6-4.6,4.6-6.5c0.2-1.1-0.8-2.2-1.9-2.4h-0.5c-0.9,0-1.9,0.5-2.7,1.1
							l-0.5-1.1c0.9-0.8,2.2-1.3,3.5-1.3c1.9-0.2,3.6,1.3,3.8,3.2v0.5c0,2.2-1.6,4.1-4.3,6.6L177,346h5.5v1.4h-7.9L174.7,347.1
							L174.7,347.1z"
                          />
                        </g>
                        <g className="vehicleParts c51" onClick={(event) => this.selectPart(event, 51)}>
                          <ellipse
                            transform="matrix(6.652053e-02 -0.9978 0.9978 6.652053e-02 -201.5211 326.5432)"
                            className="st0"
                            cx="73.8"
                            cy="271"
                            rx="16"
                            ry="16"
                          />
                          <path
                            className="st1"
                            d="M72.9,265.4h-5.4L67,269h1.1c1.1,0,2.1,0.2,3,0.7c1.3,0.7,2,2.1,2,3.6c0,2.7-2.3,4.8-5,4.8h-0.2
							c-1.3,0.2-2.5-0.2-3.4-0.9l0.5-1.4c0.9,0.5,2,0.7,3,0.7c1.6,0.2,3-1.1,3.2-2.7v-0.4c0-1.8-1.3-3-3.9-3c-0.7,0-1.3,0-2,0.2l0.9-6.6
							h6.6V265.4L72.9,265.4z"
                          />
                          <path className="st1" d="M79.7,265.6L79.7,265.6l-2.5,1.3l-0.4-1.4l3-1.6h1.6v14h-1.8V265.6z" />
                        </g>
                      </g>
                    </svg>
                  </div>
                </PivotItem>
              </Pivot>
            </section>
            <section className="PDIChecks__damage col-12">
              <h5 className="title">{Resource.modules.PDICheck.damageDetails}</h5>
              <div className="PDIChecks__list">
                {selectedPDI && selectedPDI.PDIChecks.length > 0 ? (
                  selectedPDI.PDIChecks.map((item, index) => {
                    const img = item.PhotoURL && item.PhotoURL;
                    return (
                      <div className="PDIChecks__item" key={index} onClick={() => this.previewCheck(item)}>
                        {img ? (
                          <div
                            className="img"
                            style={{
                              backgroundImage: "url(" + img + ")",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}
                          >
                            <img src={img} alt="" />
                          </div>
                        ) : (
                          <div className="no-image">
                            <img src={require("../../assets/images/no-photo.svg")} alt="no-photo" />
                            <span>{Resource.modules.PDICheck.noImage}</span>
                          </div>
                        )}
                        <div className="name">
                          {item.PartId}. {item.DamageType}
                        </div>
                        {this.state.status !== "Active" && (
                          <div className="remove" onClick={(event) => this.confirmation(event, item.CheckId)}>
                            <img src={require("../../assets/images/remove.svg")} alt="bin" />
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <span className="NoDamage">{Resource.modules.PDICheck.noDamage}</span>
                )}
              </div>
            </section>
            <section className="PDIChecks__comment col-12">
              <div className="comment">
                <TextField
                  autoComplete="off"
                  label={Resource.modules.PDICheck.comment}
                  placeholder={Resource.modules.PDICheck.commentPH}
                  multiline
                  resizable={false}
                  disabled={this.state.status == "Active" ? true : false}
                  rows={3}
                  onChange={this.handleCommentChange}
                  value={comment === undefined ? (selectedPDI ? selectedPDI.Comment : undefined) : comment}
                />
              </div>
            </section>

            <div className="col-12 goBack text-center">
              {this.state.status !== "Active" ? (
                <div>
                  <button className="cancel ripple" onClick={this.goBack}>
                    {Resource.shared.Modules.Confirmation.Cancel}
                  </button>
                  <button
                    disabled={this.state.isFinishPDILoading}
                    onClick={() => this.finishPDI(this.state.VIN, this.state.PDIId)}
                  >
                    {this.state.isFinishPDILoading ? (
                      <Spinner color="white" scale={1} />
                    ) : (
                      Resource.shared.Modules.Confirmation.finish
                    )}
                  </button>
                </div>
              ) : (
                <button className="back ripple" onClick={this.goBack}>
                  {Resource.shared.Modules.Confirmation.back}
                </button>
              )}
            </div>
            <AddPdiDialog
              hideAddPdiDialog={hideAddPdiDialog}
              PDICheckPhoto={PDICheckPhoto}
              uploadPDIPhoto={uploadPDIPhoto}
              deletePhoto={this.deletePhoto}
              PDIphotoLoading={PDIphotoLoading}
              title={`${this.state.selectedPartNumber}. ${this.state.selectedPartName}`}
              DemageType={DemageType}
              handleDemageType={this.handleDemageType}
              closeAddPdiDialog={this.closeAddPdiDialog}
              checkPreview={checkPreview}
              addPDICheckLoading={this.state.addPDICheckLoading}
            ></AddPdiDialog>
            <Confirmation
              text={Resource.shared.Modules.Confirmation.printPromptText}
              hideConfirmationDialog={hidePrintDialog}
              title={Resource.shared.Modules.Confirmation.printPromptTitle}
              closeConfirmationDialog={this.PDIPrint}
            ></Confirmation>
            <Confirmation
              text={Resource.shared.Modules.Confirmation.deletePDIDamage}
              hideConfirmationDialog={hideConfirmationDialog}
              title={Resource.shared.Modules.Confirmation.deleteDamageInfoTitle}
              closeConfirmationDialog={this.deleteCheck}
            ></Confirmation>
          </main>
        </div>
      </>
    );
  }
}
