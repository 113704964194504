import { AuthenticationHeader } from "../../modules/helpers/Authentication-Header";
import { PdiPhotoUploadResponse, PhotoUploadResponse } from "../Models/PhotoUploadResponse";
import Compressor from "compressorjs";

export class PhotoUpload {
  readonly Photo: File;
  constructor(Photo: File) {
    this.Photo = Photo;
  }

  createObjectURL(photo: File) {
    return window.URL ? window.URL.createObjectURL(photo) : window.webkitURL.createObjectURL(photo);
  }

  compressImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 1920,
        maxHeight: 1080,
        minWidth: 1920,
        minHeight: 1080,
        success(result: File) {
          resolve(result);
        },
        error(err) {
          reject(err.message);
        },
      });
    });
  }

  PdiResult(): Promise<PdiPhotoUploadResponse> {
    return this.compressImage(this.Photo).then((compressedPhoto) => ({
      OriginURL: "",
      URL: this.createObjectURL(compressedPhoto),
      OriginName: "",
      Name: this.Photo.name,
      Photo: compressedPhoto,
    }));
  }

  Result(): Promise<PhotoUploadResponse> {
    const url = `/api/Vehicle/UploadPhoto`;

    const formData = new FormData();
    return this.compressImage(this.Photo).then((compressedPhoto) => {
      formData.append("file", compressedPhoto, compressedPhoto.name);

      return fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: AuthenticationHeader(),
        },
      })
        .then((response) => response.json())
        .then((photoUploadResponse: PhotoUploadResponse) => {
          return photoUploadResponse;
        });
    });
  }
}
