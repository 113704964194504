import { CompoundButton } from 'office-ui-fabric-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import "./Home.scss";
import App from '../../App';
import { Resource } from '../../assets/Localization';

interface Props {
	context: App
}
export default class Home extends React.Component<Props, {}> {
	constructor(props: Props) {
		super(props);
		this.props.context.setState({ pageTitle: Resource.modules.Home.pageTitle });
	}

	render(): JSX.Element {
		const { Home } = Resource.modules;
		return <div className="homePage">
			<h3>{Home.Action}</h3>
			<div className="pageLinks d-flex flex-column">
				<Link to="/Vehicle-Stocking" className="pageLink">
					<button className='ripple'>
						<span className='icon-container'>
							<img src={require('../../assets/images/home-car.svg')} alt='stocking' />
						</span>
						{Home.VehicleStocking}
					</button>
				</Link>
				<Link to="/Prepare-for-Dispatch" className="pageLink">
					<button className='ripple'>
						<span className='icon-container'>
							<img src={require('../../assets/images/home-security.svg')} alt='dispatch' />
						</span>
						{Home.PrepareforDispatch}
					</button>
				</Link>
				<Link to="/Put-on-Truck" className="pageLink">
				<button className='ripple'>
						<span className='icon-container'>
							<img src={require('../../assets/images/home-moving-car.svg')} alt='put on truck' />
						</span>
						{Home.PutonTruck}
					</button>
				</Link>
			</div >
		</div >
	}

}
