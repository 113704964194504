import { Command } from "../../../shared/Models/Command";
import { AuthFetch } from "../../../shared/Models/AuthFetch";
import { AddPDIResult } from "../Models/AddPDIResult";

export class PDIPrint implements Command<Response> {
  readonly pdiId: string;
  constructor(pdiId: string) {
    this.pdiId = pdiId;
  }

  Result(): Promise<Response> {
    const myFetch = AuthFetch();
    const url = `/api/Vehicle/PDIPrint?pdiId=${this.pdiId}`;
    return myFetch(url, {
      method: "POST",
    });
  }
}
