import { UnregisterCallback } from 'history';
import { CompoundButton, Icon, ITextField, SpinnerSize, TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import App from '../../App';
import barcode_scanner from "../../assets/images/Barcode_Scanner.svg";
import { Resource } from '../../assets/Localization';
import { GetQueryParamsInUrl } from '../../shared/Models/GetQueryParamsInUrl';
import { SetQueryParamsInUrl } from '../../shared/Models/SetQueryParamsInUrl';
import Barcode from '../Barcode/Barcode';
import { history } from '../helpers/history';
import { VehicleForDispatch } from './Models/VehicleForDispatch';
import "./PrepareForDispatchPage.scss";
import { Spinner } from '../../shared/components/header/Spiner'
interface Props {
	context: App
}

interface State {
	VIN: string;
	scannerModeOn: boolean;
	disableCanAddPDIButton: boolean;
	selectedRetailerCode: string;
	tableTitle: string;
	searchedVehicles: VehicleForDispatch[];
	showVehicleSearchBox: boolean;
};

export default class PrepareForDispatchPage extends React.Component<Props, State> {
	unlisten: UnregisterCallback;
	node: any;
	vinTextfield: ITextField | null;
	constructor(props: Props) {
		super(props);
		this.state = {
			VIN: new GetQueryParamsInUrl('VIN').Result() || '',
			scannerModeOn: false,
			disableCanAddPDIButton: false,
			selectedRetailerCode: new GetQueryParamsInUrl('selectedRetailerCode').Result() || 'All',
			tableTitle: Resource.modules.PrepareforDispatch.tableTitle,
			searchedVehicles: [],
			showVehicleSearchBox: false
		}
		props.context.setState({ pageTitle: Resource.modules.PrepareforDispatch.pageTitle });
	}


	getPageData() {
		this.props.context.getVehiclesForDispatch(this.state.selectedRetailerCode);
		this.props.context.getRetailersForDispatch();
	}

	componentDidMount() {
		this.unlisten = history.listen((location, action) => {
			this.setState({
				VIN: new GetQueryParamsInUrl('VIN').Result() || '',
				selectedRetailerCode: new GetQueryParamsInUrl('selectedRetailerCode').Result() || 'All'
			});
			setTimeout(() => {
				this.scrollSelectedRow();
			}, 500);
		});
		this.getPageData();
		document.addEventListener('mousedown', this.handleDocumentClick, false);
	}


	componentWillUnmount() {
		this.unlisten();
		document.removeEventListener('mousedown', this.handleDocumentClick, false);
	}

	handleDocumentClick = (e: MouseEvent) => {
		if (this.node && !this.node.contains(e.target)) {
			this.setState({
				searchedVehicles: [],
				showVehicleSearchBox: false
			})
		}
	}

	getKey = (item: VehicleForDispatch, index?: number): string => {
		return index ? (index + 1).toString() : '1';
	};

	toggleScannerMode = () => {
		this.setState({ scannerModeOn: !this.state.scannerModeOn });
	}


	handleScan = (vinCode: string) => {
		const VIN = vinCode.slice(0, 17);
		new SetQueryParamsInUrl('VIN', VIN).Result();
		this.toggleScannerMode();
	}


	getSelectedVehicleForDispatch(): VehicleForDispatch | undefined {
		const { vehiclesForDispatch } = this.props.context.state;
		const { VIN } = this.state;
		let selectedVehicleForDispatch = vehiclesForDispatch.find(vehicle => vehicle.VIN === VIN);
		return selectedVehicleForDispatch;
	}


	handleError = (err: any) => {
		console.error(err)
	}

	handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		new SetQueryParamsInUrl('VIN', (newValue || '')).Result();
		this.handleVinSearch((newValue || ''));
	};


	clearVin = () => {
		new SetQueryParamsInUrl('VIN', '').Result();
		this.setState({
			searchedVehicles: [],
			showVehicleSearchBox: false
		});
		setTimeout(() => {
			if (this.vinTextfield) {
				this.vinTextfield.focus();
			}
		}, 100);
	}

	handleVinSearch = (VIN: string): void => {
		if (VIN.length > 2) {
			const { vehiclesForDispatch } = this.props.context.state;
			this.setState({
				searchedVehicles: vehiclesForDispatch.filter(vehicle => vehicle.VIN.toLowerCase().includes(VIN.toLowerCase())),
				showVehicleSearchBox: true
			});
		} else {
			this.setState({
				searchedVehicles: [],
				showVehicleSearchBox: false
			});
		}
	}


	selectVinFromSearchBox = (VIN: string): void => {
		new SetQueryParamsInUrl('VIN', VIN).Result();
		this.setState({
			showVehicleSearchBox: false
		});
		this.scrollSelectedRow();
	}

	handleOptionChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
		let selectedRetailerCode = changeEvent.target.value;
		this.setTableTitle(selectedRetailerCode);
		new SetQueryParamsInUrl('selectedRetailerCode', selectedRetailerCode).Result();
		this.props.context.getVehiclesForDispatch(selectedRetailerCode);
	};


	setTableTitle(selectedRetailerCode: string) {
		const selectedRetailer = this.props.context.state.retailersForDispatch.find(retailer => retailer.Code === selectedRetailerCode);
		if (selectedRetailer) {
			this.setState({
				tableTitle: selectedRetailer.Code === 'All' ? Resource.modules.PrepareforDispatch.tableTitle : `${selectedRetailer.Name} (${selectedRetailer.Code}) ${Resource.modules.PrepareforDispatch.vehicles}`
			});
		}
	}

	scrollSelectedRow = (): void => {
		const selectedRow = document.getElementById("selectedRow");
		if (selectedRow) {
			selectedRow.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			})
		}
	}


	vehicleDispatch = () => {
		const { VIN, selectedRetailerCode } = this.state;
		this.props.context.vehicleDispatch(VIN, selectedRetailerCode);
	}

	addPDI = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, VIN: string, PDIState: string) => {
		event.stopPropagation();
		const { addPDI } = this.props.context;
		this.props.context.setState({ prevPath: `${history.location.pathname}${history.location.search}` });
		addPDI(VIN, PDIState);
	}


	selectVehicle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, VIN: string) => {
		event.stopPropagation();
		new SetQueryParamsInUrl('VIN', VIN).Result();
	}

	render(): JSX.Element {
		const { PrepareforDispatch } = Resource.modules;
		const { vehiclesForDispatch, retailersForDispatch, vehicleDispatchLoading } = this.props.context.state;
		const { scannerModeOn, tableTitle, showVehicleSearchBox, searchedVehicles } = this.state;
		const selectedVehicleForDispatch: VehicleForDispatch | undefined = this.getSelectedVehicleForDispatch();

		return <>
			<div className="PrepareForDispatchPage page-container">
				{
					scannerModeOn ?
						<div className="BarcodeReader">
							<Barcode onDetected={this.handleScan} handleScannerMode={this.toggleScannerMode} />
						</div>
						:
						<>
							<div className="top">
								<form>
									<section className="retailers-list">
										{retailersForDispatch.map((item, index) => {
											return <div className="retailers-list__item" key={index}>
												<input type="radio" name="retailer"
													id={'retailer-' + (index + 1).toString()}
													value={item.Code}
													hidden
													onChange={this.handleOptionChange}
													checked={this.state.selectedRetailerCode === item.Code} />
												<label htmlFor={'retailer-' + (index + 1).toString()} className={item.VehiclesForDispatchCount > 0 ? 'label hasVehicles' : 'label'}>
													<div className="label__inner ripple">
														<span className="txt">{item.Code}</span>
														<span className="budge">{item.VehiclesForDispatchCount}</span>
														<span className='notification-dot'></span>
													</div>
												</label>
											</div>
										})}
									</section>
								</form>

								<section className="VehicleSearch-section d-flex align-items-center justify-content-between">
									<div className="qr-wrapper" onClick={this.toggleScannerMode} >
										<img src={barcode_scanner} alt="" />
									</div>
									<div className="textField-wrapper" ref={node => this.node = node}>
										<img src={require('../../assets/images/search-icon.svg')} alt='search icon' className='searchIcon' />
										<TextField maxLength={17} autoComplete='off' value={this.state.VIN} onChange={this.handleChange} placeholder={PrepareforDispatch.enterVin}
											onFocus={() => this.handleVinSearch(this.state.VIN)} componentRef={((ref: ITextField | null) => {
												this.vinTextfield = ref;
											})} />
										{
											showVehicleSearchBox ?
												<div className='VehicleSearchBox'>
													{
														searchedVehicles.map((item, index) => {
															return <div className="VehicleSearchBox__item" key={index} onClick={() => this.selectVinFromSearchBox(item.VIN)}>
																{item.VIN}
															</div>
														})
													}
													{
														searchedVehicles.length === 0 ?
															<span className="VehicleSearchBox__item">
																{Resource.shared.Messages.noResults}
															</span> :
															null
													}
												</div> :
												null
										}
										{
											this.state.VIN ?
												<Icon iconName="Cancel" className="ClearVin" onClick={this.clearVin} /> :
												null
										}
									</div>
								</section>

								<section className="VehicleDetails-section">
									<h3 className="VehicleDetails-section__title">{this.state.tableTitle}</h3>
									<div className="vehicles">
										{
											vehiclesForDispatch.map((item, index) => {
												return <div className={item.VIN === this.state.VIN ? 'vehicle active' : 'vehicle'} key={index} onClick={(event) => this.selectVehicle(event, item.VIN)}>
													<div className="status">
														{item.State == 'ReadyforDispatch' || item.State == 'Removed' ? <img src={require('../../assets/images/green-mark.svg')} alt='checkmark' /> : <img src={require('../../assets/images/clock.svg')} alt='checkmark' />}
													</div>
													<div className="vehicleDetailsItem">
														<div className="value VIN">{item.VIN}</div>
														<div className="value">{item.Color}</div>
													</div>
													<div className="vehicleDetailsItem">
														<div className="value">{item.Model} </div>
														{/* <div className={item.PutOnTruck ? 'value active' : 'value'} >{item.State}</div> */}
													</div>
													{(item.State !== 'ReadyforDispatch' && item.State !== 'Removed') &&
														<button className="addPDI" onClick={(event) => this.addPDI(event, item.VIN, item.PDIState)}>
															<span className="addPDIWrapper">
																<Icon iconName="CircleAddition" className="CircleAdditionSolidIcon" />
																<div className="txt">{Resource.modules.PrepareforDispatch.addPDI}</div>
															</span>
														</button>
													}
												</div>
											})
										}
									</div>
								</section>

							</div>
							<div className="bottom">
								<section className="buttons-section d-flex flex-column align-items-center justify-content-center">
									<span className="command-button">
										{this.state.selectedRetailerCode == 'All'  ?
												<p className='choose-dealer'>{Resource.modules.PrepareforDispatch.chooseDealer}</p>
												:
											<button disabled={vehicleDispatchLoading || !(selectedVehicleForDispatch && selectedVehicleForDispatch.CanDispatch)} onClick={this.vehicleDispatch}>
												{PrepareforDispatch.dispatch}
											</button>
										}
										{
											vehicleDispatchLoading ?
												<span className="button-loading">
													<Spinner scale={1} />
												</span> :
												null
										}
									</span>
								</section>
							</div>
						</>
				}
			</div>
		</>


	}
}