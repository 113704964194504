import { Command } from "../../../shared/Models/Command";
import { AuthFetch } from "../../../shared/Models/AuthFetch";
import { AddPDIResult } from "../Models/AddPDIResult";
export class AddPDI implements Command<AddPDIResult> {
  readonly vin: string;
  readonly PDIState: string;
  constructor(vin: string, PDIState: string) {
    this.vin = vin;
    this.PDIState = PDIState;
  }

  Result(): Promise<AddPDIResult> {
    const myFetch = AuthFetch();
    const url = `/api/Vehicle/PDI?vin=${this.vin}&pdiState=${this.PDIState}`;
    return myFetch(url, {
      method: "POST",
      body: JSON.stringify({
        vin: this.vin,
        PDIState: this.PDIState,
      }),
    })
      .then((response) => response.json())
      .then(({ Payload }) => {
        return {
          VIN: JSON.parse(Payload).VIN,
          Id: JSON.parse(Payload).PDIId,
        };
      });
  }
}
