import * as React from 'react';
import "./Confirmation.scss";
import { Resource } from '../../../assets/Localization';
import { Dialog, DialogType, DialogFooter, DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
interface Props {
	hideConfirmationDialog: boolean;
	closeConfirmationDialog: (success: boolean) => void;
	title: string;
	text: any;
}
const Confirmation: React.FunctionComponent<Props> = ({ closeConfirmationDialog, hideConfirmationDialog, text, title }) => {
	return (
		<Dialog
			hidden={hideConfirmationDialog}
			onDismiss={() => closeConfirmationDialog(false)}
			dialogContentProps={{
				type: DialogType.largeHeader,
				title: title,
			}}
			modalProps={{
				isBlocking: false,
				styles: { main: { maxWidth: 620 } },
				className: "confirmationModalClass"
			}}
		>
			<div className="dialog-content">
				<div className="text">{text}</div>
			</div>
			<DialogFooter>
				<DefaultButton onClick={() => closeConfirmationDialog(false)} text={Resource.shared.Modules.Confirmation.Cancel} />
				<PrimaryButton onClick={() => closeConfirmationDialog(true)} text={Resource.shared.Modules.Confirmation.Yes} />
			</DialogFooter>


		</Dialog>
	);
};
export default Confirmation;