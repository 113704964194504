import { IUser } from '../Models/User';
import { Login } from '../Commands/Login';
export const AuthService = {
	getCurrentUser,
	setCurrentUser,
	login
}


function getCurrentUser(): IUser | null {
	const currentUser = localStorage.getItem('CurrentUser');
	const User: IUser | null = currentUser ? JSON.parse(currentUser) : null;
	return User;
}

function setCurrentUser(user: IUser): void {
	localStorage.setItem('CurrentUser', JSON.stringify(user));
}

function login(userName: string, password: string): Promise<IUser> {
	return new Login(userName, password).Result()
}
