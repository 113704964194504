import { Query } from "../../../shared/Models/Query";
import { RetailerForPutOnTruck } from "../Models/RetailerForPutOnTruck";
import { AuthFetch } from "../../../shared/Models/AuthFetch";

export class RetailersForPutOnTruck implements Query<RetailerForPutOnTruck[]> {
  Result(): Promise<RetailerForPutOnTruck[]> {
    const myFetch = AuthFetch();
    const url = "/api/Vehicle/RetailersForPutOnTruck";
    return myFetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((locationsJSON: RetailerForPutOnTruck[]) => {
        return locationsJSON;
      });
  }
}
