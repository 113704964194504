import { Command } from "../../../shared/Models/Command";
import { AuthFetch } from "../../../shared/Models/AuthFetch";
import { AddPDIResult } from "../Models/AddPDIResult";

export class FinishPDI implements Command<Response> {
  readonly vin: string;
  readonly pdiId: string;
  constructor(vin: string, pdiId: string) {
    this.vin = vin;
    this.pdiId = pdiId;
  }

  Result(): Promise<Response> {
    const hubName = localStorage.getItem("SelectedLocationName");
    const myFetch = AuthFetch();
    const url = `/api/Vehicle/FinishPDI`;
    return myFetch(url, {
      method: "POST",
      body: JSON.stringify({ vin: this.vin, pdiId: this.pdiId, hub: hubName }),
    });
  }
}
