import { AuthFetch } from "../../../shared/Models/AuthFetch";

export type FetchArrivalVehiclesInput = {
  arrivalDate: string;
};

export type ArrivalVehicleDTO = {
  VIN: string;
  Model: string;
  Stocked: boolean;
  Color: string;
  State: string;
};

export const fetchArrivalVehicles = ({ arrivalDate }: FetchArrivalVehiclesInput) => {
  const arrivalHub = localStorage.getItem("SelectedLocationName");
  const myFetch = AuthFetch();
  const url = `/api/Vehicle/GetArrivalVehicles?arrivalDate=${arrivalDate}&arrivalHub=${arrivalHub}`;
  return myFetch(url, {
    method: "GET",
  });
};
