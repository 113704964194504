import { Query } from "../../../shared/Models/Query";
import { AuthFetch } from "../../../shared/Models/AuthFetch";
import { EDamageType } from "../../../shared/Modules/AddPdiDialog/Models/EDamageType";
import { PhotoUpload } from "../../../shared/Commands/PhotoUpload";
import { PhotoUploadResponse } from "../../../shared/Models/PhotoUploadResponse";

export class AddPDICheck implements Query<Response> {
  readonly VIN: string;
  readonly partId: number;
  readonly photoName: string;
  readonly pdiId: string;
  readonly damageType: EDamageType | undefined;
  readonly damaged: boolean;
  readonly partName: string;
  readonly photo: File | undefined;
  constructor(
    VIN: string,
    photoName: string,
    originPhotoName: string,
    partId: number,
    pdiId: string,
    damageType: EDamageType | undefined,
    damaged: boolean,
    partName: string,
    photo?: File
  ) {
    this.VIN = VIN;
    this.photoName = photoName;
    this.partId = partId;
    this.pdiId = pdiId;
    this.damageType = damageType;
    this.damaged = damaged;
    this.partName = partName;
    this.photo = photo;
  }

  photoUpload(): Promise<PhotoUploadResponse | void> {
    if (this.damageType === EDamageType.Missing && this.photo === undefined) {
      return Promise.resolve();
    }
    return new PhotoUpload(this.photo || new File([], "")).Result();
  }

  Result(): Promise<Response> {
    const myFetch = AuthFetch();
    const url = `/api/Vehicle/Check`;
    console.log("phto", this.photo);
    return this.photoUpload().then((photoName) => {
      return myFetch(url, {
        method: "POST",
        body: JSON.stringify({
          vin: this.VIN,
          photoName: photoName && photoName.Name,
          partId: this.partId,
          pdiId: this.pdiId,
          damageType: this.damageType,
          damaged: this.damaged,
          partName: this.partName,
        }),
      });
    });
  }
}
