import { UnregisterCallback } from 'history';
import { CompoundButton, Icon, ITextField, SpinnerSize, TextField } from 'office-ui-fabric-react';
import { DetailsRow, IDetailsRowStyles } from 'office-ui-fabric-react/lib/DetailsList';
import * as React from 'react';
import App from '../../App';
import barcode_scanner from "../../assets/images/Barcode_Scanner.svg";
import { Resource } from '../../assets/Localization';
import { GetQueryParamsInUrl } from '../../shared/Models/GetQueryParamsInUrl';
import { SetQueryParamsInUrl } from '../../shared/Models/SetQueryParamsInUrl';
import Barcode from '../Barcode/Barcode';
import { history } from '../helpers/history';
import { VehicleForPutOnTruck } from './Models/VehicleForPutOnTruck';
import "./PutOnTruckPage.scss";
import { Spinner } from '../../shared/components/header/Spiner';
interface Props {
	context: App
}

interface State {
	VIN: string;
	scannerModeOn: boolean;
	disableCanAddPDIButton: boolean;
	selectedRetailerCode: string;
	tableTitle: string;
	searchedVehicles: VehicleForPutOnTruck[];
	showVehicleSearchBox: boolean;
};

export default class PutOnTruckPage extends React.Component<Props, State> {
	unlisten: UnregisterCallback;
	node: any;
	vinTextfield: ITextField | null;
	constructor(props: Props) {
		super(props);
		this.state = {
			VIN: new GetQueryParamsInUrl('VIN').Result() || '',
			scannerModeOn: false,
			disableCanAddPDIButton: false,
			selectedRetailerCode: new GetQueryParamsInUrl('selectedRetailerCode').Result() || 'All',
			tableTitle: Resource.modules.PutOnTruckPage.tableTitle,
			searchedVehicles: [],
			showVehicleSearchBox: false
		}
		props.context.setState({ pageTitle: Resource.modules.PutOnTruckPage.pageTitle });

	}

	getPageData() {
		this.props.context.getVehiclesForPutOnTruck(this.state.selectedRetailerCode);
		this.props.context.getRetailersForPutOnTruck();
	}


	componentDidMount() {
		this.unlisten = history.listen((location, action) => {
			this.setState({
				VIN: new GetQueryParamsInUrl('VIN').Result() || '',
				selectedRetailerCode: new GetQueryParamsInUrl('selectedRetailerCode').Result() || 'All'
			});
			setTimeout(() => {
				this.scrollSelectedRow();
			}, 500);
		});
		this.getPageData();
		document.addEventListener('mousedown', this.handleDocumentClick, false);
	}


	componentWillUnmount() {
		this.unlisten();
		document.removeEventListener('mousedown', this.handleDocumentClick, false);
	}

	handleDocumentClick = (e: MouseEvent) => {
		if (this.node && !this.node.contains(e.target)) {
			this.setState({
				searchedVehicles: [],
				showVehicleSearchBox: false
			})
		}
	}


	getKey = (item: VehicleForPutOnTruck, index?: number): string => {
		return index ? (index + 1).toString() : '1';
	};

	toggleScannerMode = () => {
		this.setState({ scannerModeOn: !this.state.scannerModeOn });
	}


	handleScan = (vinCode: string) => {
		const VIN = vinCode.slice(0, 17);
		new SetQueryParamsInUrl('VIN', VIN).Result();
		this.toggleScannerMode();
	}


	getSelectedVehicleForPutOnTruck(): VehicleForPutOnTruck | undefined {
		const { vehiclesForPutOnTruck } = this.props.context.state;
		const { VIN } = this.state;
		let selectedVehicleForPutOnTruck = vehiclesForPutOnTruck.find(vehicle => vehicle.VIN === VIN);
		return selectedVehicleForPutOnTruck;
	}


	handleError = (err: any) => {
		console.error(err)
	}


	handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		new SetQueryParamsInUrl('VIN', (newValue || '')).Result();
		this.handleVinSearch((newValue || ''));
	};


	clearVin = () => {
		new SetQueryParamsInUrl('VIN', '').Result();
		this.setState({
			searchedVehicles: [],
			showVehicleSearchBox: false
		});
		setTimeout(() => {
			if (this.vinTextfield) {
				this.vinTextfield.focus();
			}
		}, 100);
	}


	handleVinSearch = (VIN: string): void => {
		if (VIN.length > 2) {
			const { vehiclesForPutOnTruck } = this.props.context.state;
			this.setState({
				searchedVehicles: vehiclesForPutOnTruck.filter(vehicle => vehicle.VIN.toLowerCase().includes(VIN.toLowerCase())),
				showVehicleSearchBox: true
			});
		} else {
			this.setState({
				searchedVehicles: [],
				showVehicleSearchBox: false
			});
		}
	}


	selectVinFromSearchBox = (VIN: string): void => {
		new SetQueryParamsInUrl('VIN', VIN).Result();
		this.setState({
			showVehicleSearchBox: false
		});
		this.scrollSelectedRow();
	}

	handleOptionChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
		let selectedRetailerCode = changeEvent.target.value;
		this.setTableTitle(selectedRetailerCode);
		new SetQueryParamsInUrl('selectedRetailerCode', selectedRetailerCode).Result();
		this.props.context.getVehiclesForPutOnTruck(selectedRetailerCode);
	};


	setTableTitle(selectedRetailerCode: string) {
		const selectedRetailer = this.props.context.state.retailersForPutOnTruck.find(retailer => retailer.Code === selectedRetailerCode);
		if (selectedRetailer) {
			this.setState({
				tableTitle: selectedRetailer.Code === 'All' ? Resource.modules.PutOnTruckPage.tableTitle : `${selectedRetailer.Name} (${selectedRetailer.Code}) ${Resource.modules.PutOnTruckPage.vehicles}`
			});
		}
	}

	scrollSelectedRow = (): void => {
		const selectedRow = document.getElementById("selectedRow");
		if (selectedRow) {
			selectedRow.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			})
		}
	}

	_onRenderRow = (props: any): JSX.Element => {
		const customStyles: Partial<IDetailsRowStyles> = {};
		let id = '';
		const selectedVehicleForPutOnTruck: VehicleForPutOnTruck | undefined = this.getSelectedVehicleForPutOnTruck();
		if (selectedVehicleForPutOnTruck && props.item.VIN === selectedVehicleForPutOnTruck.VIN) {
			customStyles.root = { backgroundColor: 'rgb(0, 120, 212) !important', color: 'white !important' };
			id = "selectedRow";
		}

		return <DetailsRow {...props} styles={customStyles} id={id} />;
	};

	vehiclePutOnTruck = () => {
		const { VIN, selectedRetailerCode } = this.state;
		this.props.context.vehiclePutOnTruck(VIN, selectedRetailerCode);
	}


	addPDI = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, VIN: string, PDIState: string) => {
		event.stopPropagation();
		const { addPDI } = this.props.context;
		this.props.context.setState({ prevPath: `${history.location.pathname}${history.location.search}` });
		addPDI(VIN, PDIState);
	}


	selectVehicle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, VIN: string) => {
		event.stopPropagation();
		new SetQueryParamsInUrl('VIN', VIN).Result();
	}

	render(): JSX.Element {
		const { PutOnTruckPage } = Resource.modules;
		const { vehiclesForPutOnTruck, retailersForPutOnTruck, vehiclePutOnTruckLoading } = this.props.context.state;
		const { scannerModeOn, tableTitle, showVehicleSearchBox, searchedVehicles } = this.state;
		const selectedVehicleForPutOnTruck: VehicleForPutOnTruck | undefined = this.getSelectedVehicleForPutOnTruck();

		return <div className="PutOnTruckPage page-container">
			{
				scannerModeOn ?
					<div className="BarcodeReader">
						<Barcode onDetected={this.handleScan} handleScannerMode={this.toggleScannerMode} />
					</div>
					:
					<>
						<div className="top">
							<form>
								<section className="retailers-list">

									{retailersForPutOnTruck.map((item, index) => {
										return <div className="retailers-list__item" key={index}>
											<input type="radio" name="retailer"
												id={'retailer-' + (index + 1).toString()}
												value={item.Code}
												hidden
												onChange={this.handleOptionChange}
												checked={this.state.selectedRetailerCode === item.Code} />
											<label htmlFor={'retailer-' + (index + 1).toString()} className={item.VehiclesForPutOnTruckCount > 0 ? 'label hasVehicles' : 'label'}>
												<div className="label__inner ripple">
													<span className="txt">{item.Code}</span>
													<span className="budge">{item.VehiclesForPutOnTruckCount}</span>
													<span className='notification-dot'></span>
												</div>
											</label>
										</div>
									})}
								</section>
							</form>

							<section className="VehicleSearch-section d-flex align-items-center justify-content-between">
								<div className="qr-wrapper" onClick={this.toggleScannerMode} >
									<img src={barcode_scanner} alt="" />
								</div>
								<div className="textField-wrapper" ref={node => this.node = node}>
									<img src={require('../../assets/images/search-icon.svg')} alt='search icon' className='searchIcon' />
									<TextField maxLength={17} autoComplete='off' value={this.state.VIN} onChange={this.handleChange} placeholder={PutOnTruckPage.enterVin}
										onFocus={() => this.handleVinSearch(this.state.VIN)} componentRef={((ref: ITextField | null) => {
											this.vinTextfield = ref;
										})} />
									{
										showVehicleSearchBox ?
											<div className='VehicleSearchBox'>
												{
													searchedVehicles.map((item, index) => {
														return <div className="VehicleSearchBox__item" key={index} onClick={() => this.selectVinFromSearchBox(item.VIN)}>
															{item.VIN}
														</div>
													})
												}
												{
													searchedVehicles.length === 0 ?
														<span className="VehicleSearchBox__item">
															{Resource.shared.Messages.noResults}
														</span> :
														null
												}
											</div> :
											null
									}
									{
										this.state.VIN ?
											<Icon iconName="Cancel" className="ClearVin" onClick={this.clearVin} /> :
											null
									}
								</div>
							</section>

							<section className="VehicleDetails-section">
								<h3 className="VehicleDetails-section__title">{this.state.tableTitle}</h3>
								<div className="vehicles">
									{
										vehiclesForPutOnTruck.map((item, index) => {
											return <div className={item.VIN === this.state.VIN ? 'vehicle active' : 'vehicle'} key={index} onClick={(event) => this.selectVehicle(event, item.VIN)}>
												<div className="status">

													{item.State == 'Removed' ? <img src={require('../../assets/images/green-mark.svg')} alt='checkmark' /> : <img src={require('../../assets/images/clock.svg')} alt='checkmark' />}
												</div>
												<div className="vehicleDetailsItem">
													<div className="value VIN">{item.VIN}</div>
													<div className="value">{item.Color}</div>
												</div>
												<div className="vehicleDetailsItem">
													<div className="value">{item.Model} </div>
													{/* <div className={item.PutOnTruck ? 'value active' : 'value'} >{item.State}</div> */}
												</div>
												{item.State !== 'Removed' &&
													<button className="addPDI" onClick={(event) => this.addPDI(event, item.VIN, item.PDIState)}>
														<span className="addPDIWrapper">
															<Icon iconName="CircleAddition" className="CircleAdditionSolidIcon" />
															<div className="txt">{Resource.modules.PutOnTruckPage.addPDI}</div>
														</span>
													</button>
												}
											</div>
										})
									}

								</div>
							</section>

						</div>
						<div className="bottom">
							<section className="buttons-section d-flex flex-column align-items-center justify-content-center">


								<span className="command-button">
								{this.state.selectedRetailerCode == 'All'  ?
												<p className='choose-dealer'>{Resource.modules.PutOnTruckPage.chooseDealer}</p>
												:
									<button onClick={this.vehiclePutOnTruck} disabled={vehiclePutOnTruckLoading || !(selectedVehicleForPutOnTruck && selectedVehicleForPutOnTruck.CanPutOnTruck)}>
										{PutOnTruckPage.putonTruck}
									</button>
								}
									{
										vehiclePutOnTruckLoading ?
											<span className="button-loading">
												<Spinner scale={1} />
											</span> :
											null
									}
								</span>

							</section>
						</div>
					</>
			}

		</div>


	}
}