import { UnregisterCallback } from "history";
import { CompoundButton, Icon, ITextField, SpinnerSize, TextField } from "office-ui-fabric-react";
import * as React from "react";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import App from "../../App";
import barcode_scanner from "../../assets/images/Barcode_Scanner.svg";
import { Resource } from "../../assets/Localization";
import { history } from "../../modules/helpers/history";
import { GetQueryParamsInUrl } from "../../shared/Models/GetQueryParamsInUrl";
import { SetQueryParamsInUrl } from "../../shared/Models/SetQueryParamsInUrl";
import Confirmation from "../../shared/Modules/Confirmation/Confirmation";
import Barcode from "../Barcode/Barcode";
import "./CMRPage.scss";
import { VehicleForCMR } from "./Models/VehicleForCMR";
import { Spinner } from "../../shared/components/header/Spiner";
import exifr from "exifr";
interface Props {
  context: App;
}

interface State {
  VIN: string;
  scannerModeOn: boolean;
  searchedVehicles: VehicleForCMR[];
  showVehicleSearchBox: boolean;
  hideConfirmationDialog: boolean;
}

export default class CMRPage extends React.Component<Props, State> {
  unlisten: UnregisterCallback;
  node: any;
  vinTextfield: ITextField | null;
  constructor(props: Props) {
    super(props);
    this.state = {
      VIN: new GetQueryParamsInUrl("VIN").Result() || "",
      scannerModeOn: false,
      searchedVehicles: [],
      showVehicleSearchBox: false,
      hideConfirmationDialog: true,
    };
    props.context.setState({ pageTitle: Resource.modules.CMR.pageTitle });
  }

  getPageData() {
    this.props.context.getVehiclesForCMR();
  }

  componentDidMount() {
    this.unlisten = history.listen((location, action) => {
      this.setState({ VIN: new GetQueryParamsInUrl("VIN").Result() || "" });
    });
    this.getPageData();
    document.addEventListener("mousedown", this.handleDocumentClick, false);
  }

  componentWillUnmount() {
    this.unlisten();
    document.removeEventListener("mousedown", this.handleDocumentClick, false);
  }

  handleDocumentClick = (e: MouseEvent) => {
    if (this.node && !this.node.contains(e.target)) {
      this.setState({
        searchedVehicles: [],
        showVehicleSearchBox: false,
      });
    }
  };

  getKey = (item: VehicleForCMR, index?: number): string => {
    return index ? (index + 1).toString() : "1";
  };

  toggleScannerMode = () => {
    this.setState({ scannerModeOn: !this.state.scannerModeOn });
  };

  handleScan = (vinCode: string) => {
    const VIN = vinCode.slice(0, 17);
    new SetQueryParamsInUrl("VIN", VIN).Result();
    this.toggleScannerMode();
  };

  getSelectedVehicleForCMR(): VehicleForCMR | undefined {
    const { vehiclesForCMR } = this.props.context.state;
    const { VIN } = this.state;
    const selectedVehicleForCMR = vehiclesForCMR.find((vehicle) => vehicle.VIN === VIN);

    if (selectedVehicleForCMR) {
      exifr.parse(selectedVehicleForCMR.CMRPhotos[0] && selectedVehicleForCMR.CMRPhotos[0].URL).then((output) => {
        console.log(output);
        if (output && output.Orientation && output.Orientation == "Rotate 90 CW") {
          let photo = document.getElementById("PhotoBlock");
          photo!.classList.add("rotateImage");
        }
      });
    }

    return selectedVehicleForCMR;
  }

  handleError = (err: any) => {
    console.error(err);
  };

  handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    new SetQueryParamsInUrl("VIN", newValue || "").Result();
    this.handleVinSearch(newValue || "");
  };

  clearVin = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    new SetQueryParamsInUrl("VIN", "").Result();
    this.setState({
      searchedVehicles: [],
      showVehicleSearchBox: false,
    });

    setTimeout(() => {
      if (this.vinTextfield) {
        this.vinTextfield.focus();
      }
    }, 100);
  };

  handleVinSearch = (VIN: string): void => {
    if (VIN.length > 2) {
      const { vehiclesForCMR } = this.props.context.state;
      this.setState({
        searchedVehicles: vehiclesForCMR.filter((vehicle) => vehicle.VIN.toLowerCase().includes(VIN.toLowerCase())),
        showVehicleSearchBox: true,
      });
    } else {
      this.setState({
        searchedVehicles: [],
        showVehicleSearchBox: false,
      });
    }
  };

  selectVinFromSearchBox = (VIN: string): void => {
    new SetQueryParamsInUrl("VIN", VIN).Result();
    this.setState({
      showVehicleSearchBox: false,
    });
  };

  handleImageChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { uploadPhoto } = this.props.context;
    const { VIN } = this.state;
    changeEvent.preventDefault();
    const photo = changeEvent.target.files ? changeEvent.target.files[0] : null;
    if (photo) {
      uploadPhoto(VIN, photo);
      changeEvent.target.value = "";
    }
  };

  deletePhoto = (success: boolean) => {
    if (success) {
      const selectedVehicleForCMR: VehicleForCMR | undefined = this.getSelectedVehicleForCMR();
      if (selectedVehicleForCMR) {
        const { CMRPhotoDelete } = this.props.context;
        const { photoLoading } = this.props.context.state;
        if (!photoLoading) {
          CMRPhotoDelete(selectedVehicleForCMR.VIN);
        }
      }
    }
    this.setState({ hideConfirmationDialog: true });
  };

  confirmation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState({ hideConfirmationDialog: false });
  };

  transformDate(inputDate: string, whithTime: boolean) {
    if (inputDate) {
      const options = whithTime
        ? {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
            timeZone: "Asia/Tbilisi",
          }
        : {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          };
      const date: Date = new Date(inputDate);
      let newDate = new Intl.DateTimeFormat("uk-UK", options).format(date);
      newDate = newDate.substr(0, 2) + "/" + newDate.substr(3);
      newDate = newDate.substr(0, 5) + "/" + newDate.substr(6);
      return newDate;
    } else {
      return "";
    }
  }

  render(): JSX.Element {
    const { CMR } = Resource.modules;
    const { vehicleColumns } = Resource.shared;
    const { photoLoading } = this.props.context.state;
    const { scannerModeOn, showVehicleSearchBox, searchedVehicles, hideConfirmationDialog } = this.state;
    const selectedVehicleForCMR: VehicleForCMR | undefined = this.getSelectedVehicleForCMR();

    return (
      <div className="CMRPage page-container">
        {scannerModeOn ? (
          <div className="BarcodeReader">
            <Barcode onDetected={this.handleScan} handleScannerMode={this.toggleScannerMode} />
          </div>
        ) : (
          <>
            <div className="top">
              <section className="VehicleSearch-section d-flex align-items-center justify-content-between">
                <div className="qr-wrapper" onClick={this.toggleScannerMode}>
                  <img src={barcode_scanner} alt="" />
                </div>
                <div className="textField-wrapper" ref={(node) => (this.node = node)}>
                  <img src={require("../../assets/images/search-icon.svg")} alt="search icon" className="searchIcon" />
                  <TextField
                    maxLength={17}
                    autoComplete="off"
                    value={this.state.VIN}
                    onChange={this.handleChange}
                    placeholder={CMR.enterVin}
                    onFocus={() => this.handleVinSearch(this.state.VIN)}
                    componentRef={(ref: ITextField | null) => {
                      this.vinTextfield = ref;
                    }}
                  />
                  {showVehicleSearchBox ? (
                    <div className="VehicleSearchBox">
                      {searchedVehicles.map((item, index) => {
                        return (
                          <div
                            className="VehicleSearchBox__item"
                            key={index}
                            onClick={() => this.selectVinFromSearchBox(item.VIN)}
                          >
                            {item.VIN}
                          </div>
                        );
                      })}
                      {searchedVehicles.length === 0 ? (
                        <span className="VehicleSearchBox__item">{Resource.shared.Messages.noResults}</span>
                      ) : null}
                    </div>
                  ) : null}
                  {this.state.VIN ? <Icon iconName="Cancel" className="ClearVin" onClick={this.clearVin} /> : null}
                </div>
              </section>
              {selectedVehicleForCMR ? (
                <>
                  <section className="VehicleDetails-section">
                    <h3 className="VehicleDetails-section__title">{CMR.vehicleDetails}</h3>
                    <div className="vehicle-card">
                      <div className="items">
                        <div className="item">
                          <div className="labels">
                            <div className="labelsTxt">{vehicleColumns.Model}</div>
                            <div className="labelsTxt">{vehicleColumns.Color}</div>
                            <div className="labelsTxt">{vehicleColumns.ProductionDate}</div>
                          </div>
                          <div className="values">
                            <div className="valuesTxt">{selectedVehicleForCMR.Model}</div>
                            <div className="valuesTxt">{selectedVehicleForCMR.Color}</div>
                            <div className="valuesTxt">
                              {this.transformDate(selectedVehicleForCMR.ProductionDate, false)}
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="labels">
                            <div className="labelsTxt">{vehicleColumns.TTCInvoiceNumber}</div>
                            <div className="labelsTxt">{vehicleColumns.Location}</div>
                            <div className="labelsTxt">{vehicleColumns.State}</div>
                          </div>
                          <div className="values">
                            <div className="valuesTxt">{selectedVehicleForCMR.TTCInvoiceNumber}</div>
                            <div className="valuesTxt">{selectedVehicleForCMR.Location}</div>
                            <div className="valuesTxt">{selectedVehicleForCMR.State}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="damage-photo-chooser">
                    <h3 className="title">{CMR.photos.title}</h3>
                    <div className="content">
                      {selectedVehicleForCMR && selectedVehicleForCMR.CanUploadCMRPhoto ? (
                        <label
                          htmlFor="getImage"
                          className={
                            !selectedVehicleForCMR ||
                            (selectedVehicleForCMR && !selectedVehicleForCMR.CanUploadCMRPhoto)
                              ? "label disableUpload"
                              : "label"
                          }
                        >
                          <img src={require("../../assets/images/camera-blue.svg")} alt="camera" />
                          <span>{Resource.modules.CMR.photos.uploadPhoto}</span>
                        </label>
                      ) : null}

                      {/* accept="image/*;capture=camera" */}
                      <input
                        type="file"
                        id="getImage"
                        accept="image/*"
                        capture="capture"
                        onChange={this.handleImageChange}
                        hidden
                        disabled={
                          !selectedVehicleForCMR || (selectedVehicleForCMR && !selectedVehicleForCMR.CanUploadCMRPhoto)
                        }
                      />

                      {selectedVehicleForCMR ? (
                        <>
                          {selectedVehicleForCMR.CMRPhotos.map((item, index) => {
                            return (
                              <div
                                id="PhotoBlock"
                                className="img"
                                key={index}
                                style={{
                                  backgroundImage: "url(" + item.URL + ")",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                }}
                              >
                                <ReactFancyBox thumbnail={item.URL} image={item.URL} />
                                <div className="remove" onClick={this.confirmation}>
                                  <img src={require("../../assets/images/remove.svg")} alt="bin" />
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : null}

                      {photoLoading ? (
                        <div className="loadingMode">
                          <Spinner scale={1} />
                        </div>
                      ) : null}
                      <Confirmation
                        text={Resource.shared.Modules.Confirmation.cmrPhotoDeleteText}
                        hideConfirmationDialog={hideConfirmationDialog}
                        title={Resource.shared.Modules.Confirmation.deletePhoto}
                        closeConfirmationDialog={this.deletePhoto}
                      ></Confirmation>
                    </div>
                  </section>
                </>
              ) : (
                <div className="search-car-icon">
                  <img src={require("../../assets/images/car-search.png")} alt="search car" />
                  <div className="search-icon">
                    <img src={require("../../assets/images/search-white.svg")} alt="search white" />
                  </div>
                  <p>{Resource.modules.CMR.searchPlaceholder}</p>
                </div>
              )}
            </div>
            {selectedVehicleForCMR && (
              <div className="bottom">
                <div className="text-center finish">
                  <button onClick={this.clearVin} disabled={!selectedVehicleForCMR}>
                    {Resource.shared.Modules.Confirmation.finish}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
