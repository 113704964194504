import { Query } from "../../../shared/Models/Query";
import { VehicleForPutOnTruck } from "../Models/VehicleForPutOnTruck";
import { AuthFetch } from "../../../shared/Models/AuthFetch";

export class VehiclesForPutOnTruck implements Query<VehicleForPutOnTruck[]> {
  readonly selectedRetailerCode: string;
  constructor(selectedRetailerCode: string) {
    this.selectedRetailerCode = selectedRetailerCode;
  }
  Result(): Promise<VehicleForPutOnTruck[]> {
    console.log("VehiclesForPutOnTruck is called");
    const myFetch = AuthFetch();
    const url = "/api/Vehicle/VehiclesForPutOnTruck?retailerCode=" + this.selectedRetailerCode;
    return myFetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((locationsJSON: VehicleForPutOnTruck[]) => {
        return locationsJSON;
      });
  }
}
