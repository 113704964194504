import { Query } from "../../../shared/Models/Query";
import { RetailerForStocking } from "../Models/RetailerForStocking";
import { AuthFetch } from "../../../shared/Models/AuthFetch";

export class RetailersForStocking implements Query<RetailerForStocking[]> {
  Result(): Promise<RetailerForStocking[]> {
    const myFetch = AuthFetch();
    const url = "/api/Vehicle/RetailersForStocking";
    return myFetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((locationsJSON: RetailerForStocking[]) => {
        return locationsJSON;
      });
  }
}
