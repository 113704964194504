import { Query } from "../../../shared/Models/Query";
import { AuthFetch } from "../../../shared/Models/AuthFetch";
import { VehicleForCMR } from "../Models/VehicleForCMR";

export class VehiclesForCMR implements Query<VehicleForCMR[]> {
  Result(): Promise<VehicleForCMR[]> {
    const myFetch = AuthFetch();
    const url = "/api/Vehicle/VehiclesForCMR";
    return myFetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((locationsJSON: VehicleForCMR[]) => {
        return locationsJSON;
      });
  }
}
