import { history } from '../../modules/helpers/history';
export class GetQueryParamsInUrl {
	readonly key: string;
	constructor(key: string) {
		this.key = key;
	}
	Result(): string | null {
		const params = new URLSearchParams(history.location.search);
		const found = params.get(this.key);
		return found;
	}
}