import LocalizedStrings from "react-localization";

export const Resource = new LocalizedStrings({
  en: {
    score: "Score",
    time: "Time",
    modules: {
      Home: {
        pageTitle: "Home",
        Action: "Choose an Action",
        VehicleStocking: "Vehicle Stocking",
        PrepareforDispatch: "Prepare for Dispatch",
        PutonTruck: "Removal",
      },
      PrepareforDispatch: {
        enterVin: "Enter VIN",
        tableTitle: "All Dealers Vehicles",
        pageTitle: "Prepare for Dispatch",
        chooseDealer: "Choose Dealer",
        vehicles: "Vehicles",
        vehicleColumns: {
          number: "#",
          state: "",
          vin: "VIN",
          model: "Model",
          color: "Color",
        },
        dispatch: "Ready for Dispatch",
        addPDI: "Add PDI",
        PDI: {
          enterVin: "Search VIN",
          vehicleDetailsColumns: {
            model: "Model",
            location: "Location",
            state: "State",
          },
          PDIDetailsColumns: {
            date: "Date",
            name: "Name",
          },
          PDICheck: "PDI Check",
          vehicleDetails: "Vehicle Details",
          allParts: "All Parts",
        },
      },
      VehicleStocking: {
        enterVin: "Enter VIN",
        pageTitle: "Vehicle Stocking",
        pdiCheck: "PDI Check",
        vehicles: "Vehicles",
        noDamageShort: "No Damage",
        arrivals: "Arrivals",
        stocked: "Stocked",
        all: "All",
        vehicleColumns: {
          number: "#",
          state: "",
          vin: "VIN",
          model: "Model",
          productionDate: "Prod. Date",
          TTCInvoiceNumber: "TTC Invoice #",
        },
        vehicleLocations: {
          title: "Vehicle Locations",
          ok: "OK",
          cancel: "Cancel",
        },
        stock: "Stock",
        addPDI: "Add PDI",
        searchPlaceholder: "Search VIN",
        photos: "Photos",
        addCarPhoto: "Vehicle Photo Upload",
        addCarVINPhoto: "VIN Photo Upload",
      },
      PutOnTruckPage: {
        enterVin: "Enter VIN",
        tableTitle: "All Dealers Vehicles",
        pageTitle: "Removal",
        chooseDealer: "Choose Dealer",
        vehicles: "Vehicles",
        vehicleColumns: {
          number: "#",
          state: "",
          vin: "VIN",
          model: "Model",
          color: "Color",
        },
        putonTruck: "Remove",
        addPDI: "Add PDI",
      },
      CMR: {
        pageTitle: "CMR Upload",
        enterVin: "Enter VIN",
        vehicleDetails: "Vehicle Details",
        photos: {
          title: "Photos",
          uploadPhoto: "Upload CMR Photo",
        },
        vehicleColumns: {
          model: "Model",
          location: "Location",
          state: "State",
        },
        searchPlaceholder: "Search VIN",
      },
      PDICheck: {
        pageTitle: "PDI Check",
        enterVin: "Enter VIN",
        addPDI: "Add PDI",
        damage: "Damage",
        vehicleDetailsColumns: {
          model: "Model",
          location: "Location",
          state: "State",
        },
        PDIDetailsColumns: {
          date: "Date/Time",
          name: "Name",
        },
        PDICheck: "PDI Check",
        vehicleDetails: "Vehicle Details",
        PreviousPDIs: "Previous PDIs",
        allParts: "All Parts",
        searchPlaceholder: "Search VIN",
        exterior: "Exterior",
        interior: "Interior",
        showCircles: "Show Circles",
        hideCircles: "Hide Circles",
        print: "Print",
        comment: "Comment",
        commentPH: "Type a comment",
        damageDetails: "Damage Info",
        noDamage: "No Damage Was Found",
        noDamageShort: "No Damage",
        dialogTitle: "Choose Damage Type",
        uploadPhoto: "Upload Photo",
        noImage: "Photo Not Found",
        damageTypes: {
          grazemark: "Grazemark",
          scratch: "Scratch",
          chip: "Chip",
          Dent: "Dent",
          Missing: "Missing",
          Broken: "Broken",
          Severe_Damage: "Severe_Damage",
        },
      },
    },
    shared: {
      Login: {
        avmsLogistics: "AVMS Logistics",
        signin: "Sign In",
        password: "Password",
        passWordPH: "Type a Password",
        username: "Username",
        usernamePH: "Type Username",
        title: "Sign In To System",
      },
      Header: {
        title: "AVMS Logistics",
        home: "Home",
        vehicleStocking: "Vehicle Stocking",
        prepareForDispatch: "Prepare for Dispatch",
        removal: "Removal",
        pdiCheck: "PDI Check",
        cmrUpload: "CMR Upload",
        logout: "Sign Out",
        language: "ქართული",
      },
      Modules: {
        AddPdiDialog: {
          title: "Add PDI",
          damageType: "Damage Type",
          photos: "Photos",
          comment: "Comment",
          ok: "Save",
          cancel: "Cancel",
          add: "Add",
          save: "Save",
          error: "Unstable network connection. Please try again.",
        },
        Confirmation: {
          Cancel: "Cancel",
          Ok: "Ok",
          Delete: "Delete",
          Yes: "Yes",
          back: "Back",
          finish: "Finish",
          cmrPhotoDeleteText: "Are you sure you want to delete CMR Photo?",
          deletePhoto: "Delete Photo",
          deleteDamageInfoTitle: "Delete Damage Info",
          deletePDIPhoto: "Are you sure you want to delete PDI Photo?",
          deletePDIDamage: "Are you sure you want delete Damage Info?",
          deleteVINPhoto: "Are you sure you want to delete VIN Photo?",
          deleteVehiclePhoto: "Are you sure you want to delete Vehicle Photo?",
          printPromptTitle: "Print Document",
          printPromptText: "Are you sure you want to print document?",
        },
      },
      Messages: {
        offline: "No Internet",
        internetError: "Unstable network connection. Please try again.",
        noResults: "No Results Found",
        vehicleStock: {
          title: "",
          success: "Stock completed successfully",
        },
        vehicleDispatch: {
          title: "",
          success: "Ready for Dispatch completed successfully",
        },
        vehiclePutOnTruck: {
          title: "",
          success: "Remove completed successfully",
        },
        addPDI: {
          title: "",
          success: "New record added successfully",
        },
        PDIPrint: {
          success: "Document Printed Successfully",
        },
        addCheck: {
          title: "",
          success: "Damage info added successfully",
        },
        addPhoto: {
          success: "Photo uploaded successfully",
        },
        addVINPhoto: {
          success: "VIN Photo uploaded successfully!",
        },
        addVehiclePhoto: {
          success: "Vehicle Photo uploaded successfully!",
        },
        addCMRPhoto: {
          success: "CMR Photo uploaded successfully!",
        },
        deletePhoto: {
          success: "Photo deleted successfully",
        },
        deleteVINPhoto: {
          success: "VIN Photo deleted successfully!",
        },
        deleteVehiclePhoto: {
          success: "Vehicle Photo deleted successfully!",
        },
        deleteCheck: {
          title: "",
          success: "Damage info deleted successfully",
        },
        deleteCMRPhoto: {
          success: "CMR Photo Deleted Successfully!",
        },
        deleteDamageInfo: {
          title: "",
          success: "Are you sure you want to delete Damage Info?",
        },
        comment: {
          title: "",
          success: "Comment saved successfully",
        },
        loaderMessage: {
          loading: "Please Wait",
        },
        loginError: {
          text: "Username or password is incorrect",
        },
        PDIFinish: {
          success: "PDI finished successfully",
        },
        generalError: "Something went wrong",
      },
      vehicleColumns: {
        Title: "Vehicle Details",
        State: "State",
        Model: "Model",
        Location: "Location",
        Color: "Color",
        ProductionDate: "Prod. Date",
        TTCInvoiceNumber: "TTC Invoice #",
      },
    },
  },
  ka: {
    score: "ქულა",
    time: "დრო",
    modules: {
      Home: {
        pageTitle: "მთავარი",
        Action: "აირჩიეთ მოქმედება",
        VehicleStocking: "მანქანის დასაწყობება",
        PrepareforDispatch: "მომზადება გასაყვანად",
        PutonTruck: "მანქანის გაყვანა",
      },
      PrepareforDispatch: {
        enterVin: "მიუთითეთ VIN-ი",
        tableTitle: "ყველა დილერის მანქანა",
        pageTitle: "მომზადება გასაყვანად",
        chooseDealer: "აირჩიეთ დილერი",
        vehicles: "Vehicles",
        vehicleColumns: {
          number: "#",
          state: "",
          vin: "VIN",
          model: "მოდელი",
          color: "ფერი",
          productionDate: "წარმ. თარ.",
        },
        dispatch: "მზადაა გასაყვანად",
        addPDI: "დაამატეთ PDI",
        PDI: {
          enterVin: "მიუთითეთ VIN-ი",
          vehicleDetailsColumns: {
            model: "მოდელი",
            location: "მდებარეობა",
            state: "მდგომარეობა",
          },
          PDIDetailsColumns: {
            date: "თარიღი",
            name: "სახელი",
          },
          PDICheck: "PDI შემოწმება",
          vehicleDetails: "მანქანის დეტალები",
          allParts: "ყველა ნაწილი",
        },
      },
      VehicleStocking: {
        enterVin: "მიუთითეთ VIN-ი",
        pageTitle: "მანქანის დასაწყობება",
        pdiCheck: "PDI შემოწმებები",
        vehicles: "Vehicles",
        arrivals: "შემოსული",
        stocked: "დასაწყობებული",
        vehicleColumns: {
          number: "#",
          state: "",
          vin: "VIN კოდი",
          model: "მოდელი",
          productionDate: "წარმ. თარ.",
          TTCInvoiceNumber: "TTC ინვოისი",
        },
        vehicleLocations: {
          title: "მანქანების მდებარეობები",
          ok: "OK",
          cancel: "გაუქმება",
        },
        stock: "დასაწყობება",
        addPDI: "დაამატეთ PDI",
        noDamageShort: "დაზიანება არ არის",
        searchPlaceholder: "მოძებნეთ მანქანა VIN კოდით",
        photos: "სურათები",
        addCarPhoto: "მანქანის ფოტოს ატვირთვა",
        addCarVINPhoto: "VIN-ის ფოტოს ატვირთვა",
      },
      PutOnTruckPage: {
        enterVin: "მიუთითეთ VIN-ი",
        tableTitle: "ყველა დილერის მანქანა",
        pageTitle: "მანქანის გაყვანა",
        chooseDealer: "აირჩიეთ დილერი",
        vehicles: "მანქანები",
        vehicleColumns: {
          number: "#",
          state: "",
          vin: "VIN კოდი",
          model: "მოდელი",
          color: "ფერი",
        },
        putonTruck: "მანქანის გაყვანა",
        addPDI: "დაამატეთ PDI",
      },
      CMR: {
        pageTitle: "CMR-ის ატვირთვა",
        enterVin: "მიუთითეთ VIN-ი",
        vehicleDetails: "მანქანის დეტალები",
        photos: {
          title: "სურათები",
          uploadPhoto: "ატვირთეთ CMR-ის ფოტო",
        },
        vehicleColumns: {
          model: "მოდელი",
          location: "მდებარეობა",
          state: "მდგომარეობა",
        },
        searchPlaceholder: "მოძებნეთ მანქანა VIN კოდით",
      },
      PDICheck: {
        pageTitle: "PDI შემოწმება",
        addPDI: "დაამატეთ PDI",
        enterVin: "მიუთითეთ VIN-ი",
        damage: "ხარვეზი",
        vehicleDetailsColumns: {
          model: "მოდელი",
          location: "მდებარეობა",
          state: "მდგომარეობა",
        },
        PDIDetailsColumns: {
          date: "თარიღი/დრო",
          name: "სახელი",
        },
        PDICheck: "PDI შემოწმება",
        vehicleDetails: "მანქანის დეტალები",
        PreviousPDIs: "წინა PDI-ები",
        allParts: "ყველა ნაწილი",
        searchPlaceholder: "მოძებნეთ მანქანა VIN კოდით",
        exterior: "ექტერიერი",
        interior: "ინტერიერი",
        showCircles: "ნომრების ჩვენება",
        hideCircles: "ნომრების დამალვა",
        print: "ბეჭდვა",
        comment: "კომენტარი",
        commentPH: "მიუთითეთ კომენტარი",
        damageDetails: "დაზიანების ინფორმაცია",
        noDamage: "დაზიანება არ არის",
        noDamageShort: "დაზიანება არ არის",
        dialogTitle: "აირჩიეთ დაზიანების ტიპი",
        uploadPhoto: "ატვირთეთ ფოტო",
        noImage: "ფოტო არ მოიძებნა",
        damageTypes: {
          grazemark: "გაჩხაპნილი",
          scratch: "გაკაწრული",
          chip: "აქერცლილი",
          Dent: "შეღუნული",
          Missing: "დაკარგული",
          Broken: "გატეხილი",
          Severe_Damage: "ნაწილობრივი დაზიანება",
        },
      },
    },
    shared: {
      Login: {
        avmsLogistics: "AVMS Logistics",
        signin: "შესვლა",
        password: "პაროლი",
        passWordPH: "მიუთითეთ პაროლი",
        username: "სახელი",
        usernamePH: "მიუთითეთ სახელი",
        title: "გაიარეთ ავტორიზაცია",
      },
      Header: {
        title: "AVMS Logistics",
        home: "მთავარი",
        vehicleStocking: "მანქანის დასაწყობება",
        prepareForDispatch: "მომზადება გასაყვანად",
        removal: "მანქანის გაყვანა",
        pdiCheck: "PDI შემოწმება",
        cmrUpload: "CMR-ის ატვირთვა",
        logout: "გასვლა",
        language: "English",
      },
      Modules: {
        AddPdiDialog: {
          title: "დაამატეთ PDI",
          damageType: "ზიანის ტიპი",
          photos: "ფოტოები",
          comment: "კომენტარი",
          ok: "დამახსოვრება",
          cancel: "გაუქმება",
          add: "დამატება",
          save: "შენახვა",
          error: "ქსელური კავშირის ხარვეზი. გთხოვთ, ცადეთ თავიდან.",
        },
        Confirmation: {
          Cancel: "გაუქმება",
          Ok: "Ok",
          Delete: "წაშლა",
          Yes: "დიახ",
          back: "დაბრუნება",
          finish: "დასრულება",
          cmrPhotoDeleteText: "ნამდვილად გსურთ CMR ფოტოს წაშლა?",
          deletePhoto: "ფოტოს წაშლა",
          deleteDamageInfoTitle: "დაზიანების ინფორმაციის წაშლა",
          deletePDIPhoto: "ნამდვილად გსურთ, PDI-ის ფოტოს წაშლა?",
          deletePDIDamage: "ნამდვილად გსურთ დაზიანების ინფორმაციის წაშლა?",
          deleteVINPhoto: "ნამდვილად გსურთ VIN-ის ფოტოს წაშლა?",
          deleteVehiclePhoto: "ნამდვილად გსურთ მანქანის ფოტოს წაშლა?",
          printPromptTitle: "დოკუმენტის ბეჭდვა",
          printPromptText: "ნამდვილად გსურთ დოკუმენტის ბეჭდვა?",
        },
      },
      Messages: {
        offline: "ინტერნეტთან წვდომა არ არის",
        internetError: "ქსელური კავშირის ხარვეზი. გთხოვთ, ცადეთ თავიდან.",
        noResults: "შედეგები ვერ მოიძებნა",
        vehicleStock: {
          title: "",
          success: "დასაწყობება დასრულდა წარმატებით",
        },
        vehicleDispatch: {
          title: "",
          success: "მანქანის მომზადება დასრულდა წარმატებით",
        },
        vehiclePutOnTruck: {
          title: "",
          success: "მანქანის გაყვანა დასრულდა წარმატებით",
        },
        addPDI: {
          title: "",
          success: "ახალი ჩანაწერი შეინახა წარმატებით",
        },
        PDIPrint: {
          success: "დოკუმენტი წარმატებით დაიბეჭდა",
        },
        addPhoto: {
          success: "ფოტო აიტვირთა წარმატებით",
        },
        addVINPhoto: {
          success: "VIN-ის ფოტო აიტვირთა წარმატებით!",
        },
        addVehiclePhoto: {
          success: "მანქანის ფოტო აიტვირთა წარმატებით!",
        },
        addCMRPhoto: {
          success: "CMR-ის ფოტო აიტვირთა წარმატებით!",
        },
        deletePhoto: {
          success: "ფოტო წაიშალა წარმატებით",
        },
        deleteVINPhoto: {
          success: "VIN-ის ფოტო წაიშალა წარმატებით!",
        },
        deleteVehiclePhoto: {
          success: "მანქანის ფოტო წაიშალა წარმატებით!",
        },
        deleteCMRPhoto: {
          success: "CMR-ის ფოტო წაიშალა წარმატებით!",
        },
        deleteDamageInfo: {
          title: "",
          success: "ნამდვილად გსურთ დაზიანების ინფორმაციის წაშლა?",
        },
        addCheck: {
          title: "",
          success: "დაზიანების ინფორმაცია შეინახა წარმატებით",
        },
        deleteCheck: {
          title: "",
          success: "დაზიანების ინფორმაცია წაიშლა წარმატებით",
        },
        comment: {
          title: "",
          success: "კომენტარი წაიშალა წარმატებით",
        },
        loaderMessage: {
          loading: "მიმდინარეობს ჩატვირთვა",
        },
        loginError: {
          text: "მომხმარებელი ან პაროლი არასწორია",
        },
        PDIFinish: {
          success: "PDI წარმატებით დასრულდა",
        },
        generalError: "დაფიქსირდა შეცდომა",
      },
      vehicleColumns: {
        Title: "მანქანის დეტალები",
        State: "მდგომარეობა",
        Model: "მოდელი",
        Location: "მდებარეობა",
        Color: "ფერი",
        ProductionDate: "წარმ. თარ.",
        TTCInvoiceNumber: "TTC ინვოისი",
      },
    },
  },
});
