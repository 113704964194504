import { history } from '../../modules/helpers/history';

export class SetQueryParamsInUrl {
	readonly key: string;
	readonly value: string;
	constructor(key: string, value: string) {
		this.key = key;
		this.value = value;
	}
	Result(): void {
		const params = new URLSearchParams(history.location.search);
		params.set(this.key, this.value);
		history.push({
			pathname: history.location.pathname,
			search: params.toString()
		});
	}
}