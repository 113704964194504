import * as React from 'react';
import { PrimaryButton } from 'office-ui-fabric-react';
import "./PageNotFound.scss";
import { history } from '../helpers/history';
interface Props {
}
const PageNotFound: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<div className="error-page">
			<span className="code">404</span>
			<h1 className="title">PAGE NOT FOUND</h1>
			<span className="txt">Sorry, the page you are looking for could not be found.</span>
			<PrimaryButton text="Back to Home" onClick={() => history.push({ pathname: "/" })} />
		</div>
	);
};
export default PageNotFound;