import React from "react";
import { Resource } from "../../../assets/Localization";

const NoStockingVehicle = () => {
  const { VehicleStocking } = Resource.modules;
  return (
    <div className="search-car-icon">
      <img src={require("../../../assets/images/car-search.png")} alt="search car" />
      <div className="search-icon">
        <img src={require("../../../assets/images/search-white.svg")} alt="search white" />
      </div>
      <p>{VehicleStocking.searchPlaceholder}</p>
    </div>
  );
};

export default NoStockingVehicle;
