import { Command } from "../../../shared/Models/Command";
import { AuthFetch } from "../../../shared/Models/AuthFetch";

export class DeleteCheck implements Command<Response> {
  readonly vin: string;
  readonly checkId: string;
  readonly pdiId: string;
  readonly damaged: boolean;
  constructor(vin: string, checkId: string, pdiId: string, damaged: boolean) {
    this.vin = vin;
    this.checkId = checkId;
    this.pdiId = pdiId;
    this.damaged = damaged;
  }

  Result(): Promise<Response> {
    const myFetch = AuthFetch();
    const url = `/api/Vehicle/DeleteCheck`;
    return myFetch(url, {
      method: "POST",
      body: JSON.stringify({ vin: this.vin, checkId: this.checkId, pdiId: this.pdiId, damaged: this.damaged }),
    });
  }
}
