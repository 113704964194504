import { Query } from "../../../shared/Models/Query";
import { AuthFetch } from "../../../shared/Models/AuthFetch";
import { IUser } from "../Models/User";

export class Login implements Query<IUser> {
  readonly username: string;
  readonly password: string;
  constructor(username: string, password: string) {
    this.username = username;
    this.password = password;
  }
  Result(): Promise<IUser> {
    const myFetch = AuthFetch();
    const url = "/api/Auth/Login";
    return myFetch(url, {
      method: "POST",
      body: JSON.stringify({ username: this.username, password: this.password }),
    })
      .then((response) => {
        return response.json();
      })
      .then((User: IUser) => {
        return User;
      });
  }
}
