import React, {
	Component
} from 'react';
import Quagga from 'quagga';
import { Icon } from 'office-ui-fabric-react';
import './Barcode.scss' // ES6

export default class Barcode extends Component {
	constructor() {
		super();
		this.cancelScanner = this.cancelScanner.bind(this);
	}

	startScanner() {
		const props = this.props;
		Quagga.init({
			inputStream: {
				type: "LiveStream",
				target: document.querySelector('#scanner-container'),
				constraints: {
					width: 800,
					height: 600,
					facingMode: "environment"
				}
			},
			// locator: {
			// 	patchSize: "medium",
			// 	halfSample: true
			// },
			// numOfWorkers: 2,
			// frequency: 10,
			decoder: {
				readers: [
					// "code_128_reader",
					// "ean_reader",
					// "ean_8_reader",
					"code_39_reader",
					// "code_39_vin_reader",
					// "codabar_reader",
					// "upc_reader",
					// "upc_e_reader",
					// "i2of5_reader"
				],
				debug: {
					showCanvas: true,
					showPatches: true,
					showFoundPatches: true,
					showSkeleton: true,
					showLabels: true,
					showPatchLabels: true,
					showRemainingPatchLabels: true,
					boxFromPatches: {
						showTransformed: true,
						showTransformedBox: true,
						showBB: true
					}
				}
			},

			// locate: true
		}, function (err) {
			if (err) {
				console.log(err);
				return
			}
			// Quagga.CameraAccess.getActiveTrack();
			Quagga.start();
		});

		Quagga.onProcessed(function (result) {
			var drawingCtx = Quagga.canvas.ctx.overlay,
				drawingCanvas = Quagga.canvas.dom.overlay;

			if (result) {
				if (result.boxes) {
					drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
					result.boxes.filter(function (box) {
						return box !== result.box;
					}).forEach(function (box) {
						Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
					});
				}

				if (result.box) {
					Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
				}

				if (result.codeResult && result.codeResult.code) {
					Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
				}
			}
		});

		Quagga.onDetected(function (data) {
			if (data.codeResult.code) {
				props.onDetected(data.codeResult.code);
			}
		});
	}
	render() {
		return (
			<>
				<div className="scanner-container__wrapper">
					<Icon iconName="Cancel" title="Delete Demage" className="Close-Camera" onClick={this.cancelScanner} />
					<div id="scanner-container"></div>
				</div>
			</>
		);
	}
	componentDidMount() {
		this.startScanner();
	}

	componentWillUnmount() {
		Quagga.stop();
	}

	cancelScanner() {
		this.props.handleScannerMode();
	}

}