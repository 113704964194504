import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { history } from './modules/helpers/history'
import { initializeIcons } from '@uifabric/icons';
initializeIcons();
// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
	// basename={baseUrl}
	<Router history={history}>
		<App />
	</Router>,
	rootElement);
registerServiceWorker();

