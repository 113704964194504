import { UnregisterCallback } from "history";
import { Icon, ITextField, TextField } from "office-ui-fabric-react";
import * as React from "react";
import App from "../../App";
import barcode_scanner from "../../assets/images/Barcode_Scanner.svg";
import { Resource } from "../../assets/Localization";
import { history } from "../../modules/helpers/history";
import { GetQueryParamsInUrl } from "../../shared/Models/GetQueryParamsInUrl";
import { SetQueryParamsInUrl } from "../../shared/Models/SetQueryParamsInUrl";
import Barcode from "../Barcode/Barcode";
import { PDI } from "../PDI/Models/PDI";
import { VehicleForStocking } from "./Models/VehicleForStocking";
import "./VehicleStockingPage.scss";
import { Spinner } from "../../shared/components/header/Spiner";
import StockingVehicle from "./components/StockingVehicle";
import NoStockingVehicle from "./components/NoStockingVehicle";
import ArrivalList from "./components/Arrival/ArrivalList";
import ArrivalFilter from "./components/Arrival/ArrivalFilter";
interface Props {
  context: App;
}

interface State {
  VIN: string;
  scannerModeOn: boolean;
  disableCanAddPDIButton: boolean;
  searchedVehicles: VehicleForStocking[];
  showVehicleSearchBox: boolean;
  hideConfirmationDialog: boolean;
  hideConfirmationDialogVIN: boolean;
  isVIN: boolean;
  date: Date;
  areAllVehiclesVisible: boolean;
}

export default class VehicleStockingPage extends React.Component<Props, State> {
  unlisten: UnregisterCallback;
  node: any;
  vinTextfield: ITextField | null;
  constructor(props: Props) {
    super(props);
    this.state = {
      VIN: new GetQueryParamsInUrl("VIN").Result() || "",
      scannerModeOn: false,
      disableCanAddPDIButton: false,
      searchedVehicles: [],
      showVehicleSearchBox: false,
      hideConfirmationDialog: true,
      hideConfirmationDialogVIN: true,
      isVIN: false,
      date: new Date(),
      areAllVehiclesVisible: true,
    };
    props.context.setState({ pageTitle: Resource.modules.VehicleStocking.pageTitle });
  }

  getPageData() {
    this.props.context.getVehiclesForStocking();
    this.props.context.getRetailersForStocking();
  }

  componentDidMount() {
    this.unlisten = history.listen((location, action) => {
      this.setState({ VIN: new GetQueryParamsInUrl("VIN").Result() || "" });
      setTimeout(() => {
        this.scrollSelectedRow();
      }, 500);
    });
    this.getPageData();
    this.selectLocation();
    this.props.context.getArrivalVehicles({ arrivalDate: this.state.date.toUTCString() });
    document.addEventListener("mousedown", this.handleDocumentClick, false);
  }

  componentWillUnmount() {
    this.unlisten();
    document.removeEventListener("mousedown", this.handleDocumentClick, false);
    this.props.context.setState({
      hideLocationSelector: false,
    });
  }

  handleDocumentClick = (e: MouseEvent) => {
    if (this.node && !this.node.contains(e.target)) {
      this.setState({
        searchedVehicles: [],
        showVehicleSearchBox: false,
      });
    }
  };

  toggleScannerMode = () => {
    this.setState({ scannerModeOn: !this.state.scannerModeOn });
  };

  vehicleStock = (): void => {
    const { VIN } = this.state;
    const { selectedLocationId } = this.props.context.state;
    this.props.context.vehicleStock(VIN, selectedLocationId).then(() => {
      this.props.context.getArrivalVehicles({ arrivalDate: this.state.date.toUTCString() });
    });
  };

  handleScan = (vinCode: string) => {
    const VIN = vinCode.slice(0, 17);
    new SetQueryParamsInUrl("VIN", VIN).Result();
    this.toggleScannerMode();
  };

  getSelectedVehicleForStocking(): VehicleForStocking | undefined {
    const { vehiclesForStocking } = this.props.context.state;
    const { VIN } = this.state;
    let selectedVehicleForStocking = vehiclesForStocking.find((vehicle) => vehicle.VIN === VIN);

    // if (selectedVehicleForStocking) {
    // 	exifr.parse(selectedVehicleForStocking.VINPhotoName)
    // 		.then(output => {
    // 			console.log(output);
    // 			if (output && output.Orientation == 'Rotate 90 CW') {
    // 				let photo = document.getElementById('VINPhoto');
    // 				photo!.classList.add('rotateImage');
    // 			}
    // 		})

    // 	exifr.parse(selectedVehicleForStocking.VehiclePhotoName)
    // 		.then(output => {
    // 			if (output && output.Orientation == 'Rotate 90 CW') {

    // 				let photo = document.getElementById('VehiclePhoto');
    // 				photo!.classList.add('rotateImage');
    // 			}
    // 		})
    // 	}

    return selectedVehicleForStocking;
  }

  handleError = (err: any) => {
    console.error(err);
  };

  handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    new SetQueryParamsInUrl("VIN", newValue || "").Result();
    this.handleVinSearch(newValue || "");
  };

  clearVin = () => {
    new SetQueryParamsInUrl("VIN", "").Result();
    this.setState({
      searchedVehicles: [],
      showVehicleSearchBox: false,
    });
    setTimeout(() => {
      if (this.vinTextfield) {
        this.vinTextfield.focus();
      }
    }, 100);
  };

  handleVinSearch = (VIN: string): void => {
    if (VIN.length > 2) {
      const { vehiclesForStocking } = this.props.context.state;
      this.setState({
        searchedVehicles: vehiclesForStocking.filter((vehicle) =>
          vehicle.VIN.toLowerCase().includes(VIN.toLowerCase())
        ),
        showVehicleSearchBox: true,
      });
    } else {
      this.setState({
        searchedVehicles: [],
        showVehicleSearchBox: false,
      });
    }
  };

  selectVinFromSearchBox = (VIN: string): void => {
    new SetQueryParamsInUrl("VIN", VIN).Result();
    this.setState({
      showVehicleSearchBox: false,
    });
    this.scrollSelectedRow();
  };

  scrollSelectedRow = (): void => {
    const selectedRow = document.getElementById("selectedRow");
    if (selectedRow) {
      selectedRow.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  transformDate(inputDate: string, whithTime: boolean) {
    if (inputDate) {
      const options = whithTime
        ? {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
            timeZone: "Asia/Tbilisi",
          }
        : {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          };
      const date: Date = new Date(inputDate);
      let newDate = new Intl.DateTimeFormat("uk-UK", options).format(date);
      newDate = newDate.substr(0, 2) + "/" + newDate.substr(3);
      newDate = newDate.substr(0, 5) + "/" + newDate.substr(6);
      return newDate;
    } else {
      return "";
    }
  }

  addPDI = (VIN: string, PDIState: string) => {
    const { addPDI } = this.props.context;
    this.props.context.setState({ prevPath: `${history.location.pathname}${history.location.search}` });
    addPDI(VIN, PDIState);
  };

  PDIPreview = (PDI: PDI, VIN: string) => {
    this.props.context.setState({ prevPath: `${history.location.pathname}${history.location.search}` });
    history.push(`/Add-PDI-Check?VIN=${VIN}&PDIId=${PDI.Id}`);
  };

  selectLocation = () => {
    const { selectedLocationId } = this.props.context.state;
    this.props.context.setState({
      selectedLocationId: selectedLocationId,
      hideLocationSelector: true,
    });
  };

  handleImageChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { uploadVehiclePhoto } = this.props.context;
    const { VIN } = this.state;
    changeEvent.preventDefault();
    const photo = changeEvent.target.files ? changeEvent.target.files[0] : null;

    const compress = (e: any) => {
      const width = 500;
      const height = 300;
      const fileName = e.target.files[0].name;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        // 	const img: any = new Image();
        // 	img.src = event.target.result;
        // 	img.onload = () => {
        // 		const elem = document.createElement('canvas');
        // 		elem.width = width;
        // 		elem.height = height;
        // 		const ctx:any = elem.getContext('2d');
        // 		// img.width and img.height will contain the original dimensions
        // 		ctx.drawImage(img, 0, 0, width, height);
        // 		ctx.canvas.toBlob((blob: any) => {
        // 			const file = new File([blob], fileName, {
        // 				type: 'image/jpeg',
        // 				lastModified: Date.now()
        // 			});
        // 		}, 'image/jpeg', 1);
        // 	},
        // 		reader.onerror = error => console.log(error);
      };
    };

    if (photo) {
      uploadVehiclePhoto(VIN, photo);
      changeEvent.target.value = "";
    }
  };

  handleVINImageChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { uploadVINPhoto } = this.props.context;
    const { VIN } = this.state;
    changeEvent.preventDefault();
    const photo = changeEvent.target.files ? changeEvent.target.files[0] : null;
    if (photo) {
      uploadVINPhoto(VIN, photo);
      changeEvent.target.value = "";
    }
  };

  confirmation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState({ hideConfirmationDialog: false, isVIN: false });
  };

  VINconfirmation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState({ hideConfirmationDialog: false, isVIN: true });
  };

  deletePhoto = (success: boolean) => {
    if (success) {
      const selectedVehicleForStocking: VehicleForStocking | undefined = this.getSelectedVehicleForStocking();
      if (selectedVehicleForStocking) {
        const { vehiclePhotoDelete } = this.props.context;
        const { photoLoading } = this.props.context.state;
        if (!photoLoading) {
          vehiclePhotoDelete(selectedVehicleForStocking.VIN);
        }
      }
    }
    this.setState({ hideConfirmationDialog: true });
  };

  deleteVINPhoto = (success: boolean) => {
    if (success) {
      const selectedVehicleForStocking: VehicleForStocking | undefined = this.getSelectedVehicleForStocking();
      if (selectedVehicleForStocking) {
        const { vehicleVINPhotoDelete } = this.props.context;
        const { VINPhotoLoading } = this.props.context.state;
        if (!VINPhotoLoading) {
          vehicleVINPhotoDelete(selectedVehicleForStocking.VIN);
        }
      }
    }
    this.setState({ hideConfirmationDialog: true });
  };

  handleDateChange = (date: Date | null | undefined) => {
    if (date) {
      this.setState({ date });
      this.props.context.getArrivalVehicles({ arrivalDate: date.toUTCString() });
    }
  };

  handleShowAllArrivalVehicles = (isAll: boolean) => {
    this.setState({ areAllVehiclesVisible: isAll });
  };

  render(): JSX.Element {
    const { VehicleStocking } = Resource.modules;
    const { vehicleStockLoading, photoLoading, VINPhotoLoading, arrivalVehiclesLoading } = this.props.context.state;
    const { scannerModeOn, showVehicleSearchBox, searchedVehicles, hideConfirmationDialog } = this.state;
    const selectedVehicleForStocking: VehicleForStocking | undefined = this.getSelectedVehicleForStocking();

    const isNoStockingVehicleVisible = (() => {
      if (!this.state.areAllVehiclesVisible) {
        return (
          !selectedVehicleForStocking &&
          this.props.context.state.arrivalVehicles.filter((vehicle) => vehicle.Stocked).length === 0
        );
      }
      return !selectedVehicleForStocking && !this.props.context.state.arrivalVehicles.length;
    })();

    return (
      <div className="VehicleStockingPage page-container">
        <>
          {scannerModeOn ? (
            <div className="BarcodeReader">
              <Barcode onDetected={this.handleScan} handleScannerMode={this.toggleScannerMode} />
            </div>
          ) : (
            <>
              <div className="top">
                <section className="VehicleSearch-section d-flex align-items-center justify-content-between">
                  <div className="qr-wrapper" onClick={this.toggleScannerMode}>
                    <img src={barcode_scanner} alt="" />
                  </div>
                  <div className="textField-wrapper" ref={(node) => (this.node = node)}>
                    <img
                      src={require("../../assets/images/search-icon.svg")}
                      alt="search icon"
                      className="searchIcon"
                    />
                    <TextField
                      maxLength={17}
                      autoComplete="off"
                      value={this.state.VIN}
                      onChange={this.handleChange}
                      placeholder={VehicleStocking.enterVin}
                      onFocus={() => this.handleVinSearch(this.state.VIN)}
                      componentRef={(ref: ITextField | null) => {
                        this.vinTextfield = ref;
                      }}
                    />
                    {showVehicleSearchBox ? (
                      <div className="VehicleSearchBox">
                        {searchedVehicles.map((item, index) => {
                          return (
                            <div
                              className="VehicleSearchBox__item"
                              key={index}
                              onClick={() => this.selectVinFromSearchBox(item.VIN)}
                            >
                              {item.VIN}
                            </div>
                          );
                        })}
                        {searchedVehicles.length === 0 ? (
                          <span className="VehicleSearchBox__item">{Resource.shared.Messages.noResults}</span>
                        ) : null}
                      </div>
                    ) : null}
                    {this.state.VIN ? <Icon iconName="Cancel" className="ClearVin" onClick={this.clearVin} /> : null}
                  </div>
                </section>
                {!selectedVehicleForStocking && (
                  <ArrivalFilter
                    date={this.state.date}
                    onSelectDate={this.handleDateChange}
                    vehicles={this.props.context.state.arrivalVehicles}
                    areAllVehiclesVisible={this.state.areAllVehiclesVisible}
                    handleShowAllArrivalVehicles={this.handleShowAllArrivalVehicles}
                    arrivalVehiclesLoading={arrivalVehiclesLoading}
                  />
                )}

                {!selectedVehicleForStocking && (
                  <ArrivalList
                    vehicles={this.props.context.state.arrivalVehicles}
                    areAllVehiclesVisible={this.state.areAllVehiclesVisible}
                  />
                )}
                {selectedVehicleForStocking && (
                  <StockingVehicle
                    PDIPreview={this.PDIPreview}
                    addPDI={this.addPDI}
                    transformDate={this.transformDate}
                    confirmation={this.confirmation}
                    VINconfirmation={this.VINconfirmation}
                    deletePhoto={this.deletePhoto}
                    selectedVehicleForStocking={selectedVehicleForStocking}
                    photoLoading={photoLoading}
                    VINPhotoLoading={VINPhotoLoading}
                    handleImageChange={this.handleImageChange}
                    handleVINImageChange={this.handleVINImageChange}
                    deleteVINPhoto={this.deleteVINPhoto}
                    hideConfirmationDialog={hideConfirmationDialog}
                    isVIN={this.state.isVIN}
                  />
                )}
                {isNoStockingVehicleVisible && <NoStockingVehicle />}
              </div>
              {selectedVehicleForStocking && (
                <div className="bottom">
                  <section className="buttons-section d-flex flex-column align-items-center justify-content-center">
                    <span className="command-button">
                      <button
                        className="ripple"
                        disabled={
                          vehicleStockLoading || !(selectedVehicleForStocking && selectedVehicleForStocking.CanStock)
                        }
                        onClick={this.vehicleStock}
                      >
                        {Resource.modules.VehicleStocking.stock}
                      </button>
                      {vehicleStockLoading ? (
                        <span className="button-loading">
                          <Spinner scale={1} />
                        </span>
                      ) : null}
                    </span>
                  </section>
                </div>
              )}
            </>
          )}
        </>
      </div>
    );
  }
}
