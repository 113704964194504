import { Query } from "../../../shared/Models/Query";
import { AuthFetch } from "../../../shared/Models/AuthFetch";
export class VehicleDamaged implements Query<boolean> {
  readonly VIN: string;
  constructor(VIN: string) {
    this.VIN = VIN;
  }
  Result(): Promise<boolean> {
    const myFetch = AuthFetch();
    const url = `/api/Vehicle/VehicleDamaged?VIN=${this.VIN}`;
    return myFetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJSON: boolean) => {
        return responseJSON;
      });
  }
}
