import * as React from 'react';


interface UserIconProps {
    focused: boolean
};

export const HomeIcon = (props: UserIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18.7" viewBox="0 0 22 18.7">
            <path id="Path_76" data-name="Path 76" d="M10.8,21.7V15.1h4.4v6.6h5.5V12.9H24L13,3,2,12.9H5.3v8.8Z" transform="translate(-2 -3)" fill={props.focused ? "#336cf2" : "#97acb5"} />
        </svg>
    )
}


export const CarIcon = (props: UserIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
            <path id="Path_74" data-name="Path 74" d="M18.92,6.01A1.494,1.494,0,0,0,17.5,5H6.5A1.5,1.5,0,0,0,5.08,6.01L3,12v8a1,1,0,0,0,1,1H5a1,1,0,0,0,1-1V19H18v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V12ZM6.5,16A1.5,1.5,0,1,1,8,14.5,1.5,1.5,0,0,1,6.5,16Zm11,0A1.5,1.5,0,1,1,19,14.5,1.5,1.5,0,0,1,17.5,16ZM5,11,6.5,6.5h11L19,11Z" transform="translate(-3 -5)" fill={props.focused ? "#336cf2" : "#97acb5"}/>
        </svg>

    )
}



export const ShieldIcon = (props: UserIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
        <path id="Path_69" data-name="Path 69" d="M19,1H5A2,2,0,0,0,3.01,3L3,15.93a2.005,2.005,0,0,0,.88,1.66L12,23l8.11-5.41a2.005,2.005,0,0,0,.88-1.66L21,3A2.006,2.006,0,0,0,19,1ZM10,16,5,11,6.41,9.59,10,13.17l7.59-7.59L19,7Z" transform="translate(-3 -1)" fill={props.focused ? "#336cf2" : "#97acb5"}/>
      </svg>      
    )
}



export const TruckIcon = (props: UserIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
        <path id="Path_79" data-name="Path 79" d="M20,8H17V4H3A2.006,2.006,0,0,0,1,6V17H3a3,3,0,0,0,6,0h6a3,3,0,0,0,6,0h2V12ZM6,18.5A1.5,1.5,0,1,1,7.5,17,1.5,1.5,0,0,1,6,18.5Zm13.5-9L21.46,12H17V9.5Zm-1.5,9A1.5,1.5,0,1,1,19.5,17,1.5,1.5,0,0,1,18,18.5Z" transform="translate(-1 -4)" fill={props.focused ? "#336cf2" : "#97acb5"}/>
      </svg>
         
    )
}




export const CheckIcon = (props: UserIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Path_71" data-name="Path 71" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z" transform="translate(-2 -2)" fill={props.focused ? "#336cf2" : "#97acb5"}/>
      </svg>
         
    )
}


export const CloudIcon = (props: UserIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
        <path id="Path_73" data-name="Path 73" d="M19.35,10.04a7.492,7.492,0,0,0-14-2A6,6,0,0,0,6,20H19a4.986,4.986,0,0,0,.35-9.96ZM14,13v4H10V13H7l5-5,5,5Z" transform="translate(0 -4)" fill={props.focused ? "#336cf2" : "#97acb5"}/>
      </svg>
        
    )
}
